import { SelectCustomAsync } from "../../ui/molecules/asyncSelect";
import { NewProjectsService } from "../projects/service";

interface Props {
  project?: { label: string, value: string }
  setProject: (value?: { label: string, value: string }) => void
}

export function SelectProject(props: Props) {
  const filterProjects = async (inputValue: string) => {
    return await NewProjectsService().findAllProjects({
      itemsPerPage: 25,
      page: 0,
      name: inputValue,
      commercial: [],
      sector: [],
      category: [],
      startDate: [],
      state: [],
      order: true
    }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string; }[]>((resolve) => {
      resolve(filterProjects(inputValue));
    });


  return (
    <div>
      <SelectCustomAsync
        menuPortalTargetIsNotBody
        label="Progetto"
        loadOptions={promiseOptions}
        defaultOptions
        isClearable
        defaultValue={props.project}
        onChange={(e) => {
          //@ts-ignore
          props.setProject(e ?? undefined)
        }}
        placeholder="Seleziona il progetto..."
        error={false}
        errorLabel="Errore durante la selezione"
      />
    </div>
  );
}
