import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks'
import { OptionType } from '../../../lib/types'
import { XCircleIcon } from '../../../ui/icons/xCircle'
import Button from '../../../ui/molecules/button'
import Input from '../../../ui/molecules/input/Input'
import { SelectCustom } from '../../../ui/molecules/select'
import TextArea from '../../../ui/molecules/textArea'
import { findProjectById } from '../slice'
import { VariablesBody } from './dto'
import { findAllCustomMails, findEmailsHistory, findMailByID, resetMailDetails, sendCustomMail, setFindAllCustomMailsStatus, setFindMailByIdResponse, setMailDetailsBody, setMailDetailsContent, setMailDetailsName, setMailDetailsObject, setMailDetailsTo, setSelectedTemplateId, setSendCustomMailStatus, setSendEmailModalOpen } from './slice'
import { translateVariables } from './utils'
import { keycloak } from '../../../lib/keycloak'

export function SendEmailModal() {
    const mailsState = useAppSelector(state => state.mails)
    const dispatch = useAppDispatch()

    const keycloakState = useAppSelector(state => state.keycloak)
    const peopleState = useAppSelector(state => state.people)
    const projectState = useAppSelector(state => state.projects)
    const companyState = useAppSelector(state => state.companies)
    const targetListState = useAppSelector(state => state.targetList)

    let values: OptionType[] = []
    const [options, setOptions] = useState<OptionType[]>([])

    const findValuesMap = new Map<string, string>([
        ['commercialName', keycloak.hasRealmRole('commercial') ? keycloakState.keycloakUserInfo!.name ? keycloakState.keycloakUserInfo!.name : '' : ''],
        ['teamLeaderName', projectState.findProjectResponse ? (projectState.findProjectResponse.teamLeader.name + " " + projectState.findProjectResponse.teamLeader.surname) : ''],
        ['customerCompanyName', projectState.findProjectResponse ? projectState.findProjectResponse.customer.companyName ? projectState.findProjectResponse.customer.companyName : '' : ''],
        ['customerCompanyWebsite', projectState.findProjectResponse ? projectState.findProjectResponse.customer.companyWebsite ? projectState.findProjectResponse.customer.companyWebsite : '' : ''],
        ['addressee', companyState.findCompanyResponse && targetListState.editTargetListTabValue === 2 ? companyState.findCompanyResponse.name : targetListState.editTargetListTabValue === 1 && peopleState.findPersonResponse ? peopleState.findPersonResponse.name : '']
    ])

    useEffect(() => {
        if (targetListState.findTargetListResponse)
            dispatch(findProjectById(targetListState.findTargetListResponse.projectId))
    }, [])

    useEffect(() => {
        let body: VariablesBody = {}
        dispatch(findAllCustomMails({ ...mailsState.mailFilters, projectId: targetListState.findTargetListResponse?.projectId! }))
        dispatch(setMailDetailsName(projectState.findAllResponse?.data.find(item => item.id === targetListState.findTargetListResponse?.projectId)?.nameFrom))
        findValuesMap.forEach((item, key) => { if (item !== '') body[key as keyof VariablesBody] = item })
        dispatch(setMailDetailsBody(body))
    }, [mailsState.mailFilters, targetListState.editTargetListTabValue, companyState.findCompanyResponse, peopleState.findPersonResponse])

    useEffect(() => {
        if (mailsState.findAllMailsStatus === 'successfully' && mailsState.findAllMailsResponse) {
            mailsState.findAllMailsResponse.data.forEach(
                item => values.push({ value: item.ID, label: item.templateName })
            )
            dispatch(setFindAllCustomMailsStatus('idle'))
            setOptions(values)
        }
    }, [mailsState.findAllMailsStatus])

    useEffect(() => {
    }, [mailsState.mailToSendDetails])

    useEffect(() => {
        if (mailsState.findMailByIdStatus === 'successfully' && mailsState.findMailByIdResponse) {
            dispatch(setMailDetailsObject(mailsState.findMailByIdResponse.subject))
            dispatch(setMailDetailsContent(mailsState.findMailByIdResponse.templateText))
        }
    }, [mailsState.findMailByIdStatus])

    useEffect(() => {
        if (mailsState.sendCustomMailStatus === 'successfully') {
            dispatch(findEmailsHistory(mailsState.emailsHistoryFilters))
            dispatch(setSendCustomMailStatus('idle'))
        }
    }, [mailsState.sendCustomMailStatus])

    /*  function handleChangeFiles(e: File[]) {
         let filesAttached: Attachment[] = [...mailsState.mailToSendDetails.attachments]
         e.forEach(item => filesAttached.push({
             fileName: item.name,
             type: item.type,
             content: item.name
         }))
         dispatch(setMailDetailsAttachments(filesAttached))
     } */

    /* const a = document.createElement("a");
    document.body.appendChild(a);
    a.rel = "noreferrer"
    a.target = "_blank"
    a.href = 'mailto:' + peopleState.findPersonResponse?.email;
    a.click(); */

    if (mailsState.openSendEmailModal)
        return (
            <div className='transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center p-20 z-[100]'>
                <div className=' transition-all flex flex-col items-center gap-2 p-4 bg-white rounded-xl opacity-100 shadow-sm w-[45%]'>
                    <div className=' flex items-center gap-6 justify-between w-full '>
                        <span className=' text-heading-xs font-bold text-neutral-800'>Invia e-mail</span>
                        <Button onClick={() => {
                            dispatch(setSendEmailModalOpen(false));
                            setSelectedTemplateId(undefined);
                            dispatch(resetMailDetails())
                            dispatch(setFindMailByIdResponse(undefined))
                        }} size={'lg'} iconPosition={'only'}
                            variant={'link'} color={'blue'}
                            icon={<XCircleIcon color={'blue'} size={32} />} />
                    </div>
                    <div className=' flex flex-col w-full py-8 px-4 gap-3 border-t border-neutral-200'>
                        <div className='flex flex-col gap-3 '>
                            <SelectCustom defaultValue={mailsState.selectedTemplateId} label='Seleziona template-email' onChange={(choice) => {
                                if (typeof choice === 'string') {
                                    dispatch(setSelectedTemplateId(choice))
                                    dispatch(findMailByID(choice))
                                }
                            }}
                                placeholder={'Seleziona il template'} menuPortalTargetIsNotBody
                                noOptionsMessage='Non hai ancora creato alcun template custom'
                                options={
                                    options
                                } />
                        </div>
                        {targetListState.editTargetListTabValue === 1 &&
                            <div className='flex flex-col gap-3 '>
                                <Input label="Azienda associata" disabled value={peopleState.findPersonResponse ? (peopleState.findPersonResponse?.companyName.length > 0 ? peopleState.findPersonResponse?.companyName : 'Nessuna azienda associata') : 'N/A'} placeholder="Azienda associata" />
                            </div>
                        }
                        <div className='flex flex-col gap-3 '>
                            <Input label='Destinatario' defaultValue={mailsState.mailToSendDetails.to} onChange={(e) => dispatch(setMailDetailsTo(e.target.value))} placeholder="Specificare l'indirizzo email dell'utente" />
                        </div>
                        {/* <div className=' flex gap-2 w-full'>
                            <div className=' flex flex-col flex-grow gap-3'>
                                <Input label='cc' value={mailsState.mailToSendDetails.cc} onChange={(e) => dispatch(setMailDetailsCc(e.target.value))} />
                            </div>
                            <div className=' flex flex-col flex-grow gap-3'>
                                <Input label='ccn' value={mailsState.mailToSendDetails.ccn} onChange={(e) => dispatch(setMailDetailsCcn(e.target.value))} />
                            </div>
                        </div> */}
                        <div className='flex flex-col gap-3'>
                            <Input disabled label='Oggetto' value={mailsState.mailToSendObject} onChange={(e) => dispatch(setMailDetailsBody(e.target.value))} placeholder="Specificare l'oggetto della mail" />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <TextArea disabled label='Contenuto della mail' value={translateVariables(mailsState.mailToSendContent)} onChangeText={(e) => { dispatch(setMailDetailsBody(e)) }} placeholder="Descrivere il contenuto dell'e-mail" />
                        </div>
                        {/* <FileUploader id={''} multiple style={'large'} onChange={(e) => handleChangeFiles(e)} />
                        {mailsState.mailToSendDetails.attachments.length > 0 &&
                            <div className=' flex flex-col gap-3 h-[70px] overflow-scroll'>
                                {
                                    mailsState.mailToSendDetails.attachments.map(
                                        (item, index) =>
                                            <div key={index} className=' flex flex-shrink gap-[7px] items-center justify-between border border-brandPrimary-300 bg-brandPrimary-100 p-2 rounded-lg'>
                                                <div className=' flex items-center gap-2'>
                                                    <FileXlsIcon color={colors.brandPrimary[400]} size={20} />
                                                    <span className=' text-text-md font-medium'>{item.fileName}</span>
                                                </div>
                                                <div onClick={() => {
                                                    let filesAttached = [...mailsState.mailToSendDetails.attachments]
                                                    filesAttached.splice(index, 1)
                                                    dispatch(setMailDetailsAttachments(filesAttached))
                                                }}>
                                                    <TrashIcon color={colors.neutral[300]} size={20} />
                                                </div>
                                            </div>
                                    )
                                }
                            </div>
                        } */}
                    </div>
                    <div className=' flex justify-end items-center w-full border-t gap-2 py-4 px-6 border-neutral-200'>
                        <Button disabled={!mailsState.findMailByIdResponse} onClick={() => {
                            dispatch(setSelectedTemplateId(undefined))
                            dispatch(sendCustomMail({ customMailId: mailsState.findMailByIdResponse!.ID, request: mailsState.mailToSendDetails }))
                            dispatch(resetMailDetails())
                            dispatch(setSendEmailModalOpen(false))
                            dispatch(setFindMailByIdResponse(undefined))
                        }} label='Invia e-mail' size={'sm'} iconPosition={'left'} variant={'solid'} color={'green'} />
                    </div>
                </div>
            </div>
        )
    else return <></>
}
