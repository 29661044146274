import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { colors } from "../../../../../ui/colors";
import { CheckCircleIcon } from "../../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../../ui/icons/circle";
import { LinkedinIcon } from "../../../../../ui/icons/linkedin";
import Checkbox from "../../../../../ui/molecules/checkbox";
import { Pagination } from "../../../../../ui/organisms/pagination";
import { setFindAllPeopleFiltersItemsPerPage, setFindAllPeopleFiltersPage } from "../../../../database/people/slice";
import { setFindAllUsersFiltersItemsPerPage } from "../../../../users/slice";

interface Props {
  selectedIds: string[];
  setSelectedIds: (values: string[]) => void;
}
export function ImportFromDatabaseList(props: Props) {
  const { selectedIds, setSelectedIds } = props;
  const peopleState = useAppSelector((state) => state.people);
  const targetListState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  const isAlreadyIn = (id: string): boolean => {
    return targetListState.findTargetListResponse?.personContacts &&
      targetListState.findTargetListResponse?.personContacts[id] !== undefined
      ? true
      : false;
  };

  const isDisabled = (id: string, deletedAt: Date | null) => isAlreadyIn(id) || deletedAt !== null

  return (
    <div className="w-full" style={{ height: 'calc(100% - 176px)' }}>
      <div className="overflow-auto h-full">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase sticky top-0 z-10 text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left w-10">
                <Checkbox
                  shape={"square"}
                  disabled={
                    peopleState.findAllResponse?.data.every((person) =>
                      isDisabled(person.id, person.deletedAt)
                    )!
                  }
                  checked={
                    peopleState.findAllResponse?.data
                      .filter((person) => !isDisabled(person.id, person.deletedAt))
                      .every((person) => selectedIds.includes(person.id))! ||
                    peopleState.findAllResponse?.data.every((person) =>
                      isDisabled(person.id, person.deletedAt)
                    )!
                  }
                  onCheck={function (value: boolean): void {
                    if (
                      peopleState.findAllResponse?.data
                        .filter((person) => !isDisabled(person.id, person.deletedAt))
                        .every((person) => selectedIds.includes(person.id))
                    ) {
                      let newSelected = [...selectedIds];
                      peopleState.findAllResponse?.data.forEach((person) => {
                        newSelected = newSelected.filter(
                          (id) => person.id !== id
                        );
                      });
                      setSelectedIds([...newSelected]);
                    } else {
                      setSelectedIds([
                        ...selectedIds,
                        ...peopleState.findAllResponse?.data
                          .filter(
                            (person) =>
                              !selectedIds.includes(person.id) &&
                              !isDisabled(person.id, person.deletedAt)
                          )
                          .map((person) => person.id)!,
                      ]);
                    }
                  }}
                />
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">Cognome</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">nome</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">smartphone</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">email</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  <LinkedinIcon color={colors.neutral[400]} size={16} />
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">ruolo</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">azienda</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">reparto</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">privacy</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {peopleState.findAllResponse?.data.map((person) => (
              <tr
                className={clsx(
                  "h-[56px] border-b-[1px] border-b-neutral-100",
                  {
                    "bg-brandPrimary-100": selectedIds.includes(person.id),
                    "bg-white":
                      !selectedIds.includes(person.id) &&
                      !isDisabled(person.id, person.deletedAt),
                    "bg-brandPrimary-200 opacity-55":
                      !selectedIds.includes(person.id) &&
                      isDisabled(person.id, person.deletedAt),
                  }
                )}
                key={person.id}
              >
                <td className="text-text-sm font-semibold text-neutral-600">
                  <Checkbox
                    shape={"square"}
                    disabled={isDisabled(person.id, person.deletedAt)}
                    checked={
                      selectedIds.includes(person.id) || isDisabled(person.id, person.deletedAt)
                    }
                    onCheck={function (value: boolean): void {
                      if (selectedIds.includes(person.id)) {
                        setSelectedIds([
                          ...selectedIds.filter((id) => id !== person.id),
                        ]);
                      } else {
                        setSelectedIds([...selectedIds, person.id]);
                      }
                    }}
                  />
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {person.surname ?? "-"}
                </td>
                <td className="max-w-[150px] text-text-sm crop-text font-semibold text-neutral-600">
                  {person.name ?? "-"}
                </td>
                <td>
                  {person.phone ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={"tel:" + person.phone}
                    >
                      <div className="text-text-sm max-w-[150px] crop-text font-semibold text-neutral-600">
                        {person.phone}
                      </div>
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {person.email ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={"mailto:" + person.email}
                    >
                      <div className="max-w-[200px] crop-text text-text-sm font-semibold text-neutral-600">
                        {person.email}
                      </div>
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={person.linkedinProfile ?? undefined}
                  >
                    <LinkedinIcon
                      color={
                        person.linkedinProfile
                          ? colors.neutral[400]
                          : colors.neutral[200]
                      }
                      size={20}
                    />
                  </a>
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {person.role ?? "-"}
                </td>
                <td className="max-w-[200px] crop-text text-text-sm font-semibold text-brandPrimary-600">
                  {person.companyName ?? "-"}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {person.department ?? "-"}
                </td>
                <td className="h-full">
                  <div className="w-full flex items-center justify-center">
                    {person.privacy ? (
                      <CheckCircleIcon color={colors.neutral[400]} size={20} />
                    ) : (
                      <CircleIcon color={colors.neutral[400]} size={20} />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsPerPage={peopleState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setFindAllPeopleFiltersItemsPerPage(value))}
        found={peopleState.findAllResponse?.total || 0}
        key={"pagination-people-bottom"}
        pages={peopleState.findAllResponse?.totalPage || 0}
        currentPage={peopleState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllPeopleFiltersPage(page));
        }}
      />
    </div>
  );
}
