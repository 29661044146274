import clsx from "clsx";
import { useAppSelector } from "../../../../../lib/redux/hooks";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { Filters } from "../../../../database/companies/filters";
import { CompaniesHorizontalFilters } from "../../../../database/companies/filters/horizontalFilters";
import { TargetCompaniesList } from "./list";

export function TargetListCompaniesList() {
  const databaseState = useAppSelector((state) => state.database);
  const companiesState = useAppSelector((state) => state.companies);
  const targetsListsState = useAppSelector((state) => state.targetList);

  return (
    <div className="flex w-full h-full max-h-full border-t border-neutral-200 transition-all duration-300">
      <div
        className={clsx("transition-all duration-300 w-[20%] flex-shrink-0", {
          block: databaseState.filtersActive,
          hidden: !databaseState.filtersActive,
        })}
      >
        <Filters isInTargetList={true} />
      </div>
      <div
        className={clsx("transition-all h-full max-h-full duration-300", {
          "w-full": !databaseState.filtersActive,
          "w-4/5": databaseState.filtersActive,
        })}
      >
        <CompaniesHorizontalFilters />
        {companiesState.findAllStatus === "loading" ||
          targetsListsState.findTargetListStatus === "loading" ? (
          <div className="flex w-full items-center justify-center h-[45%]">
            {" "}
            <Spinner />
          </div>
        ) :
          <TargetCompaniesList />
        }
      </div>
    </div>
  );
}
