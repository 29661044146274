import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CalendarIcon } from "../../../../ui/icons/calendar";
import Input from "../../../../ui/molecules/input";
import { SelectCustom } from "../../../../ui/molecules/select";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { setFindAllCompaniesFiltersCreationContactDate } from "../slice";

export function ContactCreationFilter() {
    const companyState = useAppSelector((state) => state.companies);
    const dispatch = useAppDispatch();

    const [sign, setSign] = useState<'<' | '>' | '><' | undefined>(undefined);
    const [firstInput, setFirstInput] = useState<string | undefined>(undefined);
    const [secondInput, setSecondInput] = useState<string | undefined>(undefined);

    const signOptions = [
        { label: "Maggiore di", value: ">" },
        { label: "Minore di", value: "<" },
        { label: "Compreso tra", value: "><" },
    ];

    useEffect(() => {
        let value = sign === undefined ? undefined : sign + (firstInput ? ':' + firstInput : '') + (sign === '><' && secondInput ? ':' + secondInput : '')
        let label = signOptions.find(opt => opt.value === sign)?.label + (firstInput ? ' ' + firstInput : '') + (sign === '><' && secondInput ? ' e ' + secondInput : '')
        if (value === undefined) {
            dispatch(setFindAllCompaniesFiltersCreationContactDate(undefined))
        } else {
            dispatch(setFindAllCompaniesFiltersCreationContactDate({ value: value, label: label }))
        }
    }, [sign, firstInput, secondInput])

    const handleClear = () => {
        setFirstInput(undefined);
        setSecondInput(undefined);
        setSign(undefined)
        dispatch(setFindAllCompaniesFiltersCreationContactDate(undefined));
    };

    useEffect(() => {
        if (companyState.filters.creationContactDate === undefined) {
            setFirstInput(undefined);
            setSecondInput(undefined);
            setSign(undefined)
        }
    }, [companyState.filters.creationContactDate])

    return (
        <AccordionFilter
            key={'company-filter-creation-of-contact'}
            label={"Creazione contatto"}
            icon={<CalendarIcon color={""} size={0} />}
            showClear={companyState.filters.creationContactDate !== undefined}
            options={companyState.filters.creationContactDate !== undefined ? [companyState.filters.creationContactDate] : []}
            clearAction={handleClear}
            deleteFilter={handleClear}
        >
            <div className="flex flex-col gap-4">
                <SelectCustom
                    onChange={(e) => {
                        if (e !== undefined && typeof e === 'string') {
                            setSign(e as '<' | '>' | '><' | undefined);
                        }
                    }}
                    defaultValue={sign}
                    placeholder={"Minore, maggiore, compreso"}
                    options={signOptions}
                />
                {
                    sign !== undefined && (
                        <div className="flex flex-col gap-2">
                            <Input
                                placeholder={signOptions.find((rev) => rev.value === sign)?.label}
                                type="date"
                                key={"company-filter-creation-contact-date-input-0"}
                                defaultValue={firstInput}
                                onChangeText={(e) => {
                                    if (isValid(new Date(e)))
                                        if (e !== undefined && typeof e === 'string')
                                            setFirstInput(format(new Date(e), 'yyyy-MM-dd'));
                                        else
                                            setFirstInput(undefined);
                                }}
                            />
                            {
                                sign === '><' && (
                                    <Input
                                        key={"company-filter-creation-contact-date-input-1"}
                                        defaultValue={secondInput}
                                        placeholder={"Compreso tra"}
                                        type="date"
                                        onChangeText={(e) => {
                                            if (isValid(new Date(e)))
                                                if (e !== undefined && typeof e === 'string')
                                                    setSecondInput(format(new Date(e), 'yyyy-MM-dd'));
                                                else
                                                    setSecondInput(undefined);
                                        }}
                                    />
                                )}
                        </div>
                    )}
            </div>
        </AccordionFilter >
    );
}
