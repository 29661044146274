import { useEffect } from "react"
import { useAppSelector, useAppDispatch } from "../../../lib/redux/hooks"
import { setSelectedIdsCompanies, setShowPopupCompanies, setDeleteCompanyStatus, findCompany, setEditCompanyStatus } from "../../database/companies/slice"
import { setSelectedIdsPeople, setShowPopupPeople, setDeletePersonStatus, setNewPersonStatus, setNewPersonResponse, findPerson, setEditPersonStatus } from "../../database/people/slice"
import { findTargetListById, setChangeContactStateStatus } from "../slice"
import { Banner } from "../../../ui/organisms/banner"

export function BannerTargetListBins() {
    const targetListState = useAppSelector(state => state.targetList)
    const companiesState = useAppSelector(state => state.companies)
    const peopleState = useAppSelector(state => state.people)

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.changeContactStateStatus === "failed" ||
            targetListState.changeContactStateStatus === "successfully"
        ) {
            if (targetListState.changeContactStateStatus === "successfully") {
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setChangeContactStateStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.changeContactStateStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteCompanyStatus === "failed" ||
            companiesState.deleteCompanyStatus === "successfully"
        ) {
            dispatch(setSelectedIdsCompanies([]))
            dispatch(setShowPopupCompanies(false))
            if (companiesState.deleteCompanyStatus === "successfully") {
                dispatch(findTargetListById(targetListState.selectedTargetBins!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteCompanyStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deletePersonStatus === "failed" ||
            peopleState.deletePersonStatus === "successfully"
        ) {
            dispatch(setSelectedIdsPeople([]))
            dispatch(setShowPopupPeople(false))
            if (peopleState.deletePersonStatus === "successfully") {
                dispatch(findTargetListById(targetListState.selectedTargetBins!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeletePersonStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deletePersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    return (
        <div className="fixed flex flex-col gap-4 top-6 right-6">
            <Banner
                label={"Contatto aggiunto correttamente"}
                visible={peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"success"}
            />
            <Banner
                label={"Il contatto che stai provando ad inserire esiste ed è associato all'azienda"}
                visible={peopleState.newPersonStatus === "successfully" && peopleState.newPersonResponse?.alreadyExists === true}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"warning"}
            />
            <Banner
                label={peopleState.newPersonResponseMessage}
                visible={peopleState.newPersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"error"}
            />
            <Banner
                label={"Persona eliminata."}
                visible={peopleState.deletePersonStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setDeletePersonStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={
                    "Si è verificato un errore durante l'eliminazione della persona."
                }
                visible={peopleState.deletePersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setDeletePersonStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Azienda eliminata."}
                visible={companiesState.deleteCompanyStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setDeleteCompanyStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={
                    "Si è verificato un errore durante l'eliminazione dell'azienda."
                }
                visible={companiesState.deleteCompanyStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setDeleteCompanyStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Persona aggiornata."}
                visible={peopleState.editPersonStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setEditPersonStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica della persona."}
                visible={peopleState.editPersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setEditPersonStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Azienda aggiornata."}
                visible={companiesState.editCompanyStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setEditCompanyStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica dell'azienda."}
                visible={companiesState.editCompanyStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setEditCompanyStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Esito aggiornato."}
                visible={targetListState.changeContactStateStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setChangeContactStateStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica dell'esito."}
                visible={targetListState.changeContactStateStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setChangeContactStateStatus("idle"));
                }}
                type={"error"}
            />
        </div>
    )
}