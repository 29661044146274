import clsx from "clsx";
import { Spinner } from "../../../../ui/molecules/spinner";
import { EditCompanyForm } from "../../companies/editCompanyForm";
import { useAppSelector } from "../../../../lib/redux/hooks";

export function PersonCompany() {
    const companiesState = useAppSelector(state => state.companies)
    const peopleState = useAppSelector(state => state.people)

    return (
        <div>
            <div
                className={clsx("flex items-center justify-center h-[50%]", {
                    hidden: companiesState.findCompanyStatus !== "loading",
                })}
            >
                <Spinner />
            </div>
            <div
                className={clsx("flex items-center justify-center pt-8", {
                    hidden: peopleState.findPersonResponse?.companyId
                })}
            >
                <span className="text-text-sm text-neutral-800">
                    Non è stata trovata nessuna azienda associata al contatto.
                </span>
            </div>
            <div
                className={clsx("flex items-center justify-center pb-4", {
                    hidden: !peopleState.findPersonResponse?.companyId,
                })}
            >
                <EditCompanyForm />
            </div>
        </div>
    )
}