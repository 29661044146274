import React from 'react'

interface Props {
    color : string
    label : string
}
export function Badge(props : Props) {
  return (
    <div style={{backgroundColor: props.color}} className={' flex items-center justify-center rounded-full size-1 p-2'} >
       <span className=' text-white font-bold text-text-xs'>{props.label}</span>
    </div>
  )
}
