import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { PhoneCallIcon } from "../../../ui/icons/phoneCall"
import { XCircleIcon } from "../../../ui/icons/xCircle"
import Button from "../../../ui/molecules/button"
import Input from "../../../ui/molecules/input"
import { setEditCompanyPhone, setEditCompanyToVerifyFields } from "./slice"

export function CompanyPhones() {
    const companiesState = useAppSelector(state => state.companies)
    const dispatch = useAppDispatch()

    if (companiesState.findCompanyResponse === undefined) {
        return <></>
    }

    return (
        <>
            {
                companiesState.editCompanyRequest.phones?.map((phone, index) => (
                    <div className="flex items-end">
                        <div className="flex gap-2">
                            <Input
                                label="Telefono"
                                disabled={companiesState.findCompanyResponse?.active}
                                startIcon={<PhoneCallIcon color={""} size={0} />}
                                value={phone.phone}
                                error={companiesState.errors.validateEdit.phone[index]}
                                supportingText={companiesState.errors.validateEdit.phone[index] ? 'Inserisci un numero di telefono valido' : ''}
                                onChangeText={(text) => {
                                    const phones = [...companiesState.editCompanyRequest.phones]
                                    phones[index] = { ...phones[index], phone: text }
                                    dispatch(setEditCompanyPhone([...phones]))
                                }}
                            />
                            <Input
                                label="Note"
                                disabled={companiesState.findCompanyResponse?.active}
                                // endIcon={< color={""} size={0} />}
                                value={phone.note}
                                onChangeText={(text) => {
                                    const phones = [...companiesState.editCompanyRequest.phones]
                                    phones[index] = { ...phones[index], note: text }
                                    dispatch(setEditCompanyPhone([...phones]))
                                }}
                            />
                        </div>
                        <Button
                            size={"sm"}
                            iconPosition={"only"}
                            icon={<XCircleIcon color={""} size={0} />}
                            variant={"ghost"}
                            color={"blue"}
                            onClick={() => {
                                const phones = [...companiesState.editCompanyRequest.phones]
                                const phoneRequest = [...phones.slice(0, index), ...phones.slice(index + 1)]
                                dispatch(setEditCompanyPhone([...phoneRequest]))
                                if (companiesState.editCompanyRequest.toVerifyFields !== null)
                                    dispatch(setEditCompanyToVerifyFields([...companiesState.editCompanyRequest.toVerifyFields.filter(field => field !== 'phone')]))
                            }}
                        />
                    </div>
                ))
            }
            <div className="flex items-end justify-end">
                <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"link"}
                    color={"blue"}
                    label="Aggiungi numero di telefono"
                    onClick={() => {
                        dispatch(setEditCompanyPhone([...companiesState.editCompanyRequest.phones, '']))
                    }}
                />
            </div>
        </>
    )
}