import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Layout } from "../../../ui/layout";
import { Spinner } from "../../../ui/molecules/spinner";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import {
    findAllCompanies,
    setFindAllCompaniesFiltersPage,
    setFindAllCompaniesFiltersTargetList,
    setSelectedCompany
} from "../../database/companies/slice";
import {
    findAllPeople,
    setFindAllPeopleFiltersPage,
    setFindAllPeopleFiltersTargetList,
    setSelectedPerson
} from "../../database/people/slice";
import { TargetListPageEnum } from "../dto";
import {
    findTargetListById,
    getCounters,
    setEditTargetListTabValue,
    setFindTargetListStatus,
    setTargetListPage
} from "../slice";
import { BannerTargetListBins } from "./banner";
import { TargetCompaniesBins } from "./companies";
import { TargetPeopleBins } from "./people";

export function TargetListBins() {
    const dispatch = useAppDispatch();

    const targetListState = useAppSelector((state) => state.targetList);
    const companiesState = useAppSelector((state) => state.companies);
    const peopleState = useAppSelector((state) => state.people);

    const [direction, setDirection] = useState<"left" | "right">("left");
    const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);
    const [isFirstLoadPeople, setIsFirstLoadPeople] = useState(true);

    useEffect(() => {
        dispatch(findTargetListById(targetListState.selectedTargetBins!));
        dispatch(setEditTargetListTabValue(0))
        dispatch(setSelectedCompany(undefined))
        dispatch(setSelectedPerson(undefined))
    }, []);

    useEffect(() => {
        if (!isFirstLoadCompanies) {
            dispatch(setFindAllCompaniesFiltersPage(0));
            dispatch(
                findAllCompanies({
                    ...companiesState.filters,
                    page: 0,
                    targetList:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? [
                                {
                                    value: targetListState.findTargetListResponse?.id!,
                                    label: targetListState.findTargetListResponse?.name!,
                                },
                            ]
                            : [],
                })
            );
        }
    }, [
        companiesState.filters.order,
        companiesState.filters.itemsPerPage,
        companiesState.filters.sort,
        companiesState.filters.name,
        companiesState.filters.atecos,
        companiesState.filters.category,
        companiesState.filters.city,
        companiesState.filters.countryRegion,
        companiesState.filters.department,
        companiesState.filters.employees,
        companiesState.filters.filter,
        companiesState.filters.active,
        companiesState.filters.privacy,
        companiesState.filters.revenue,
        companiesState.filters.role,
        companiesState.filters.sector,
        companiesState.filters.hasSmartphone,
        companiesState.filters.state,
        companiesState.filters.zipCode,
        companiesState.filters.targetList,
        companiesState.filters.outcome,
        companiesState.filters.project,
        companiesState.filters.particularity,
        companiesState.filters.smartphone,
        companiesState.filters.creationContactDate,
        companiesState.filters.lastContactDate,
        companiesState.filters.outcomeInfo
    ]);

    useEffect(() => {
        if (!isFirstLoadPeople) {
            //check items per page
            dispatch(setFindAllPeopleFiltersPage(0));
            dispatch(
                findAllPeople({
                    ...peopleState.filters,
                    page: 0,
                    itemsPerPage:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? peopleState.filters.itemsPerPage
                            : 500,
                    targetList:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? [
                                {
                                    value: targetListState.findTargetListResponse?.id!,
                                    label: targetListState.findTargetListResponse?.name!,
                                },
                            ]
                            : [],
                })
            );
        }
    }, [
        peopleState.filters.order,
        peopleState.filters.itemsPerPage,
        peopleState.filters.sort,
        peopleState.filters.name,
        peopleState.filters.atecos,
        peopleState.filters.category,
        peopleState.filters.city,
        peopleState.filters.countryRegion,
        peopleState.filters.department,
        peopleState.filters.employees,
        peopleState.filters.privacy,
        peopleState.filters.revenue,
        peopleState.filters.role,
        peopleState.filters.sector,
        peopleState.filters.hasSmartphone,
        peopleState.filters.state,
        peopleState.filters.zipCode,
        peopleState.filters.targetList,
        peopleState.filters.personName,
        peopleState.filters.personEmail,
        peopleState.filters.hasCompany,
        peopleState.filters.active,
        peopleState.filters.project,
        peopleState.filters.outcome,
        peopleState.filters.particularity,
        peopleState.filters.smartphone,
        peopleState.filters.creationContactDate,
        peopleState.filters.lastContactDate,
        peopleState.filters.outcomeInfo
    ]);

    useEffect(() => {
        if (!isFirstLoadCompanies)
            dispatch(
                findAllCompanies({
                    ...companiesState.filters,
                    itemsPerPage:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? companiesState.filters.itemsPerPage
                            : 500,
                })
            );
    }, [companiesState.filters.page]);

    useEffect(() => {
        if (!isFirstLoadPeople)
            dispatch(
                findAllPeople({
                    ...peopleState.filters,
                    itemsPerPage:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? peopleState.filters.itemsPerPage
                            : 500,
                })
            );
    }, [peopleState.filters.page]);

    useEffect(() => {
        if (targetListState.findTargetListStatus === "successfully") {
            dispatch(setFindTargetListStatus("idle"));
            const contacts = targetListState.editTargetListTabValue === 1 ? targetListState.getTargetListCounterResponse?.persons : targetListState.findTargetListResponse?.companyContacts
            dispatch(
                setTargetListPage(
                    Object.keys(
                        contacts ?? {}
                    ).length === 0
                        ? TargetListPageEnum.ADD
                        : TargetListPageEnum.LIST
                )
            );
            dispatch(getCounters(targetListState.findTargetListResponse?.id!))
            dispatch(
                findAllCompanies({
                    ...companiesState.filters,
                    itemsPerPage:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? companiesState.filters.itemsPerPage
                            : 500,
                    targetList:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? [
                                {
                                    label: targetListState.findTargetListResponse?.name!,
                                    value: targetListState.findTargetListResponse?.id!,
                                },
                            ]
                            : [],
                })
            );
            dispatch(
                setFindAllPeopleFiltersTargetList(
                    targetListState.targetListPage !== TargetListPageEnum.DB
                        ? [
                            {
                                label: targetListState.findTargetListResponse?.name!,
                                value: targetListState.findTargetListResponse?.id!,
                            },
                        ]
                        : []
                )
            );
            dispatch(
                setFindAllCompaniesFiltersTargetList(
                    targetListState.targetListPage !== TargetListPageEnum.DB
                        ? [
                            {
                                label: targetListState.findTargetListResponse?.name!,
                                value: targetListState.findTargetListResponse?.id!,
                            },
                        ]
                        : []
                )
            );
            dispatch(
                findAllPeople({
                    ...peopleState.filters,
                    targetListBin: true,
                    itemsPerPage:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? peopleState.filters.itemsPerPage
                            : 500,
                    targetList:
                        targetListState.targetListPage !== TargetListPageEnum.DB
                            ? [
                                {
                                    label: targetListState.findTargetListResponse?.name!,
                                    value: targetListState.findTargetListResponse?.id!,
                                },
                            ]
                            : [],
                })
            );
            setIsFirstLoadCompanies(false);
            setIsFirstLoadPeople(false);
        }
    }, [targetListState.findTargetListStatus]);

    return (
        <Layout
            headerLabel={"Cestini lista target"}
            headerBackPath="/target-list"
            breadcrumbItems={[
                "Liste target",
                targetListState.findTargetListResponse?.name!,
                'Cestini',
                (targetListState.editTargetListTabValue === 0 ? 'Persone' : 'Aziende')
            ]}
            menuItem={MenuItems.TARGET_LIST}
        >
            <div className="h-full max-h-full p-6">
                <BannerTargetListBins />
                {targetListState.findTargetListStatus === "loading" || targetListState.getTargetListCounterStatus === 'loading' ? (
                    <Spinner />
                ) : (
                    <div className="flex flex-col bg-white rounded-2xl shadow-sm h-full max-h-full">
                        <div className="p-4 pb-0 flex justify-between items-center sticky">
                            <div className="flex items-center justify-between w-full">
                                <div className="flex items-center gap-6">
                                    <span className="text-heading-xs font-semibold text-neutral-800">
                                        {targetListState.findTargetListResponse?.name + ' > Cestini'}
                                    </span>
                                    <TabMenu
                                        direction={direction}
                                        setDirection={(value) => setDirection(value)}
                                        setValue={(e) => {
                                            dispatch(setEditTargetListTabValue(e));
                                            if (e === 1 || e === 2) {
                                                dispatch(setTargetListPage(TargetListPageEnum.LIST));
                                            }
                                        }}
                                        value={targetListState.editTargetListTabValue}
                                        items={[
                                            {
                                                icon: <></>,
                                                label:
                                                    "Persone (" +
                                                    // (targetListState.getTargetListCounterStatus === 'loading' ?
                                                    //   <Spinner size={10} />
                                                    //   :
                                                    targetListState.getTargetListCounterResponse?.persons.targetListBin.total
                                                    // ) 
                                                    +
                                                    ")",
                                            },
                                            {
                                                icon: <></>,
                                                label:
                                                    "Aziende (" +
                                                    // (targetListState.getTargetListCounterStatus === 'loading' ?
                                                    //   <Spinner size={10} />
                                                    //   :
                                                    targetListState.getTargetListCounterResponse?.companies.targetListBin.total
                                                    // )
                                                    +
                                                    ")",
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 79px)' }} className="border-t border-t-neutral-200">
                            <TabPages
                                direction={direction}
                                setDirection={(value) => setDirection(value)}
                                pages={[
                                    <div key={0} className="h-full">
                                        <TargetPeopleBins />
                                    </div>,
                                    <div key={1} className="h-full">
                                        <TargetCompaniesBins />
                                    </div>,
                                ]}
                                value={targetListState.editTargetListTabValue}
                                setValue={(e) => {
                                    dispatch(setEditTargetListTabValue(e));
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}
