import { cdAxios } from "../../lib/axios";
import { keycloak } from "../../lib/keycloak";
import {
  FindAllUserResponse,
  FindAllUsersFilters,
  UserDTO,
  UserRoleUpdateDTO,
} from "./dto";
import { UsersService } from "./service";

export class UsersServiceImpl implements UsersService {
  public findAllUsers(
    filters: FindAllUsersFilters
  ): Promise<FindAllUserResponse> {
    return cdAxios
      .get("/api/users", {
        params: {
          itemsPerPage: filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          order: filters.order,
          role: filters.role,
          search: filters.search?.toLowerCase(),
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findUserByUserId(userid: string): Promise<UserDTO> {
    return cdAxios
      .get("/api/users/find/" + userid, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findUserById(id: string): Promise<UserDTO> {
    let idToUse: string = "";
    if (window.location.pathname.includes("edit-user")) {
      if (window.location.pathname.split("edit-user/")[1] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("edit-user/")[1];
      }
    } else {
      idToUse = id;
    }
    return cdAxios
      .get("/api/users/" + idToUse, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public editUser(id: string, data: FormData): Promise<void> {
    return cdAxios
      .put("/api/users/" + id, data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public createUser(data: FormData): Promise<string> {
    return cdAxios
      .post("/api/users", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public deleteUser(id: string): Promise<void> {
    return cdAxios
      .delete("/api/users/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public findMe(): Promise<UserDTO> {
    return cdAxios
      .get("/api/users/find-me", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public updateRole(id: string, data: UserRoleUpdateDTO): Promise<void> {
    return cdAxios
      .put(
        "/api/users/" + id + "/role",
        {
          ...data,
          teamLeaderId:
            data.teamLeaderId === undefined ? "" : data.teamLeaderId,
        },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }
}
