import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { Filters } from "../../../../database/people/filters";
import { PeopleHorizontalFilters } from "../../../../database/people/filters/horizontalFilters";
import { TargetPeopleList } from "./list";

export function TargetListPeopleList() {
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);
  const targetListState = useAppSelector((state) => state.targetList);
  const dispatch = useAppDispatch();

  return (
    <div className="max-h-full w-full flex h-full">
      <div className="flex w-full">
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters isInTargetList={true} />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {peopleState.findAllStatus === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full">
              <PeopleHorizontalFilters />
              <TargetPeopleList />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
