import clsx from "clsx";
import { useEffect, useState } from "react";
import { keycloak } from "../../../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { DownloadIcon } from "../../../../../ui/icons/download";
import { EditIcon } from "../../../../../ui/icons/edit";
import { FiltersLinesIcon } from "../../../../../ui/icons/filtersLines";
import { MoreHorizontalIcon } from "../../../../../ui/icons/moreHorizontal";
import { SortIcon } from "../../../../../ui/icons/sort";
import { TrashIcon } from "../../../../../ui/icons/trash";
import { UserPlusIcon } from "../../../../../ui/icons/userPlus";
import Button from "../../../../../ui/molecules/button";
import Checkbox from "../../../../../ui/molecules/checkbox";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../../../../ui/organisms/dropdown";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { Pagination } from "../../../../../ui/organisms/pagination";
import { EditPerson } from "../../../../database/people/editPerson";
import {
  bulkCSVPeople,
  getCSVPeople,
  setFindAllPeopleFiltersItemsPerPage,
  setFindAllPeopleFiltersOrder,
  setFindAllPeopleFiltersOutcome,
  setFindAllPeopleFiltersPage,
  setFindAllPeopleFiltersSort,
  setSelectedPerson,
  setTabValueDetailsPerson,
} from "../../../../database/people/slice";
import { setFiltersActive } from "../../../../database/slice";
import {
  columnOrderTargetPeopleMapKey,
  ContactStateEnum
} from "../../../../targetList/dto";
import { findTargetListById, removeContacts, restoreContacts, setOptionsPeopleActive, setRestoreContactsStatus } from "../../../../targetList/slice";
import { PeopleColumnMap } from "../columnMap";
import { OptionsTargetPeople } from "../options/options";

export function TargetPeopleList() {
  const dispatch = useAppDispatch();
  const targetListState = useAppSelector((state) => state.targetList);
  const peopleState = useAppSelector((state) => state.people);
  const preferencesState = useAppSelector((state) => state.preferences);
  const databaseState = useAppSelector((state) => state.database);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (
      targetListState.removeContactsStatus === "failed" ||
      targetListState.removeContactsStatus === "successfully"
    ) {
      setShowPopup(false);
    }
  }, [targetListState.removeContactsStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.restoreContactsStatus === "failed" ||
      targetListState.restoreContactsStatus === "successfully"
    ) {
      dispatch(setRestoreContactsStatus("idle"));
      // dispatch(setRestoreContactsStatus("idle"));
      if (targetListState.restoreContactsStatus === "successfully") {
        dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      }
      timeoutId = setTimeout(() => {
        dispatch(setRestoreContactsStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.restoreContactsStatus]);

  const deletedAt = (companyId: string): boolean => {
    const del =
      targetListState.findTargetListResponse?.personContacts &&
        targetListState.findTargetListResponse?.personContacts[
        companyId
        ] ?
        targetListState.findTargetListResponse?.personContacts[
          companyId
        ].deletedAt : null

    return del !== null
  }

  return (
    <div className="w-full" style={{ height: 'calc(100% - 64px)' }}>
      <div className="px-4 h-16 flex items-center justify-between border-b border-b-neutral-100">
        <div className="flex items-center gap-10">
          <span className="text-heading-xs font-semibold text-neutral-800">
            Persone
          </span>
          {
            targetListState.getTargetListCounterStatus === 'loading' ?
              <div className="flex items-center w-full justify-center">
                <Spinner />
              </div> :
              <div className="flex items-center gap-2 h-7">
                <div
                  onClick={() => {
                    if (peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.LEADTAKEN))
                      dispatch(setFindAllPeopleFiltersOutcome(peopleState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.LEADTAKEN)))
                    else
                      dispatch(setFindAllPeopleFiltersOutcome([...peopleState.filters.outcome, { value: ContactStateEnum.LEADTAKEN, label: 'Lead preso' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-green-400", {
                    'border-[2px] border-green-600': peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.LEADTAKEN),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-green-800 text-label-sm font-medium">
                    Lead preso
                  </span>
                  <div className="bg-green-100 flex items-center justify-center text-green-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.persons.inList.leadTaken}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.TORECALL))
                      dispatch(setFindAllPeopleFiltersOutcome(peopleState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.TORECALL)))
                    else
                      dispatch(setFindAllPeopleFiltersOutcome([...peopleState.filters.outcome, { value: ContactStateEnum.TORECALL, label: 'Da richiamare' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-warning-400", {
                    'border-[2px] border-warning-600': peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.TORECALL),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-warning-800 text-label-sm font-medium">
                    Da richiamare
                  </span>
                  <div className="bg-warning-100 flex items-center justify-center text-warning-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.persons.inList.toRecall}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.KO))
                      dispatch(setFindAllPeopleFiltersOutcome(peopleState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.KO)))
                    else
                      dispatch(setFindAllPeopleFiltersOutcome([...peopleState.filters.outcome, { value: ContactStateEnum.KO, label: 'Ko' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-red-400", {
                    'border-[2px] border-red-600': peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.KO),
                    'hidden': !window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-red-800 text-label-sm font-medium">Ko</span>
                  <div className="bg-red-100 flex items-center justify-center text-red-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.persons.targetListBin.ko}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.INCORRECT))
                      dispatch(setFindAllPeopleFiltersOutcome(peopleState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.INCORRECT)))
                    else
                      dispatch(setFindAllPeopleFiltersOutcome([...peopleState.filters.outcome, { value: ContactStateEnum.INCORRECT, label: 'Info errate' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-neutral-400", {
                    'border-[2px] border-neutral-600': peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.INCORRECT),
                    'hidden': !window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-neutral-800 text-label-sm font-medium">
                    Info errate
                  </span>
                  <div className="bg-neutral-100 flex items-center justify-center text-neutral-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.persons.targetListBin.incorrect}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.DRAFT))
                      dispatch(setFindAllPeopleFiltersOutcome(peopleState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.DRAFT)))
                    else
                      dispatch(setFindAllPeopleFiltersOutcome([...peopleState.filters.outcome, { value: ContactStateEnum.DRAFT, label: 'In attesa' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-brandPrimary-400", {
                    'border-[2px] border-brandPrimary-600': peopleState.filters.outcome.map(value => value.value).includes(ContactStateEnum.DRAFT),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-brandPrimary-800 text-label-sm font-medium">
                    In attesa
                  </span>
                  <div className="bg-brandPrimary-100 flex items-center justify-center text-brandPrimary-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.persons.inList.draft}
                  </div>
                </div>
              </div>
          }
        </div>
        <div className="flex items-center gap-3">
        {/* {
                !keycloak.hasRealmRole('customer') &&
                <Button
                  size={"sm"}
                  iconPosition={"left"}
                  variant={"outline"}
                  color={"blue"}
                  onClick={() => {
                    dispatch(bulkCSVPeople({
                      name: peopleState.filters.name,
                      atecos: peopleState.filters.atecos,
                      sector: peopleState.filters.sector,
                      category: peopleState.filters.category,
                      department: peopleState.filters.department,
                      role: peopleState.filters.role,
                      zipCode: peopleState.filters.zipCode,
                      countryRegion: peopleState.filters.countryRegion,
                      hasSmartphone: peopleState.filters.hasSmartphone,
                      city: peopleState.filters.city,
                      targetList: peopleState.filters.targetList,
                      project: peopleState.filters.project,
                      outcome: peopleState.filters.outcome,
                      particularity: peopleState.filters.particularity,
                      smartphone: peopleState.filters.smartphone,
                      lastContactDate: peopleState.filters.lastContactDate,
                      creationContactDate: peopleState.filters.creationContactDate,
                      outcomeInfo: peopleState.filters.outcomeInfo
                    }))
                  }}
                  label="Esporta tutti"
                  icon={<DownloadIcon color={""} size={0} />}
                />
              } */}
          {selectedIds.length > 0 && (
            <>
              {window.location.pathname.includes('target-list-bins') &&
                !keycloak.hasRealmRole('customer') &&
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  icon={<UserPlusIcon color={""} size={0} />}
                  onClick={() => dispatch(restoreContacts({ targetListId: targetListState.findTargetListResponse?.id!, type: 'persons', contactIds: selectedIds }))}
                />}
              {
                !keycloak.hasRealmRole('customer') &&
                <Button
                  size={"sm"}
                  iconPosition={"left"}
                  label="Esporta selezionati"
                  variant={"outline"}
                  color={"blue"}
                  icon={<DownloadIcon color={""} size={0} />}
                  onClick={() => dispatch(getCSVPeople(selectedIds))}
                />}
              {
                !keycloak.hasRealmRole('customer') &&
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  icon={<TrashIcon color={""} size={0} />}
                  onClick={() => setShowPopup(true)}
                />
              }
            </>
          )}
          <Button
            size={"sm"}
            iconPosition={"only"}
            variant={targetListState.optionsPeopleActive ? "outline" : "ghost"}
            color={targetListState.optionsPeopleActive ? "blue" : "gray"}
            icon={<MoreHorizontalIcon color={""} size={0} />}
            onClick={() =>
              dispatch(setOptionsPeopleActive(!targetListState.optionsPeopleActive))
            }
          />
          <Button
            size={"sm"}
            iconPosition={"off"}
            label="Filtri / Espandi"
            variant={databaseState.filtersActive ? "outline" : "ghost"}
            color={databaseState.filtersActive ? "blue" : "gray"}
            icon={<FiltersLinesIcon color={""} size={0} />}
            onClick={() =>
              dispatch(setFiltersActive(!databaseState.filtersActive))
            }
          />
        </div>
      </div>
      {peopleState.findAllResponse?.data.length === 0 ||
        peopleState.findAllResponse?.data === null ? (
        <div style={{ height: 'calc(100% - 64px - 48px)' }}>
          <NotFound />
        </div>
      ) : (
        <div className="overflow-auto" style={{ height: 'calc(100% - 64px - 48px)' }}>
          <table className="w-full">
            <thead className="bg-neutral-800 h-[48px] font-semibold sticky top-0 z-10 text-neutral-500 uppercase text-text-xs">
              <tr className="bg-neutral-50">
                <th className="text-left w-10">
                  <Checkbox
                    shape={"square"}
                    checked={
                      selectedIds.length ===
                      peopleState.findAllResponse?.data.length
                    }
                    onCheck={function (value: boolean): void {
                      if (
                        selectedIds.length ===
                        peopleState.findAllResponse?.data.length
                      ) {
                        setSelectedIds([]);
                      } else {
                        setSelectedIds(
                          peopleState.findAllResponse?.data?.map(
                            (person) => person.id
                          )!
                        );
                      }
                    }}
                  />
                </th>
                {preferencesState.findAllResponse?.data[0].targetListPersonPreferences
                  .filter((column) => column.visible)
                  .map((column) => (
                    <th key={column.column} className="text-left">
                      <div className="flex gap-[4px]">
                        {column.column}
                        {
                          columnOrderTargetPeopleMapKey.get(column.column) &&
                          <div
                            onClick={() => { dispatch(setFindAllPeopleFiltersSort(columnOrderTargetPeopleMapKey.get(column.column))); dispatch(setFindAllPeopleFiltersOrder(!peopleState.filters.order)) }}
                            className="cursor-pointer"
                          >
                            <SortIcon />
                          </div>}
                      </div>
                    </th>
                  ))}
                <th className="text-left">
                  <div className="flex gap-[4px]" />
                </th>
              </tr>
            </thead>
            <tbody>
              {peopleState.findAllResponse?.data.map((person) => (
                <tr
                  className={clsx(
                    "h-[56px] border-b-[1px] border-b-neutral-100",
                    {
                      "bg-brandPrimary-100": selectedIds.includes(person.id),
                      "bg-white": !selectedIds.includes(person.id),
                    }
                  )}
                  key={person.id}
                >
                  <td className="text-text-sm font-semibold text-neutral-600">
                    <Checkbox
                      shape={"square"}
                      checked={selectedIds.includes(person.id)}
                      onCheck={function (value: boolean): void {
                        if (selectedIds.includes(person.id)) {
                          setSelectedIds([
                            ...selectedIds.filter((id) => id !== person.id),
                          ]);
                        } else {
                          setSelectedIds([...selectedIds, person.id]);
                        }
                      }}
                    />
                  </td>
                  {preferencesState.findAllResponse?.data[0].targetListPersonPreferences
                    .filter((column) => column.visible)
                    .map((column, index) => (
                      <PeopleColumnMap
                        key={person.id + "-" + column.column}
                        person={person}
                        column={column.column}
                        contactInfo={targetListState.findTargetListResponse
                          ?.personContacts ? targetListState.findTargetListResponse
                            ?.personContacts[person.id] : {
                          contactId: '-',
                          contactState: ContactStateEnum.DRAFT,
                          lastContactDate: null,
                          notes: null,
                          stateInfo: null,
                          callAttempts: null, createdAt: null, deletedAt: null, id: null
                        }}
                      />
                    ))}
                  <td className="w-[20px] cursor-pointer">
                    <Dropdown
                      items={[
                        {
                          label: "Modifica",
                          icon: <EditIcon color={""} size={0} />,
                          onClick: () => {
                            dispatch(setTabValueDetailsPerson(0))
                            dispatch(setSelectedPerson(person.id))
                          },
                        },
                        (
                          !keycloak.hasRealmRole('customer') &&
                            deletedAt(person.id) === true ?
                            {
                              label: "Ripristina",
                              icon: <UserPlusIcon color={""} size={0} />,
                              onClick: () => {
                                dispatch(restoreContacts({ targetListId: targetListState.findTargetListResponse?.id!, type: 'persons', contactIds: [person.id] }))
                              },
                            } :
                            null
                        ),
                        (keycloak.hasRealmRole('customer') ? null : {
                          label: "Cancella",
                          icon: <TrashIcon color={""} size={0} />,
                          onClick: () => {
                            setSelectedIds([person.id]);
                            setShowPopup(true);
                          },
                        }),
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        itemsPerPage={peopleState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setFindAllPeopleFiltersItemsPerPage(value))}
        found={peopleState.findAllResponse?.total || 0}
        key={"pagination-people-bottom"}
        pages={peopleState.findAllResponse?.totalPage || 0}
        currentPage={peopleState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllPeopleFiltersPage(page));
        }}
      />
      <div
        style={{ height: "calc(100% - 144px)" }}
        className={clsx(
          "fixed right-0 bottom-0 z-50 flex-shrink-0 w-[290px] transition-all",
          {
            visible: targetListState.optionsPeopleActive,
            hidden: !targetListState.optionsPeopleActive,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <OptionsTargetPeople />
        </div>
      </div>
      <div
        style={{ height: "calc(100% - 144px)" }}
        className={clsx(
          "fixed right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all",
          {
            hidden: peopleState.selectedPerson === undefined,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <EditPerson isInTargetList />
        </div>
      </div>
      <ConfirmModal
        loading={targetListState.removeContactsStatus === "loading"}
        title={"Rimuovi persone dalla lista"}
        label={
          "Sei sicuro di voler eliminare le persone selezionate dalla lista?"
        }
        confirmAction={function (): void {
          dispatch(
            removeContacts({
              targetListId: targetListState.findTargetListResponse?.id!,
              type: "persons",
              contactsId: selectedIds,
            })
          );
        }}
        onClose={function (): void {
          setSelectedIds([]);
          setShowPopup(false);
        }}
        visible={showPopup}
      />
    </div>
  );
}
