import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { MailIcon } from "../../../ui/icons/mail"
import { XCircleIcon } from "../../../ui/icons/xCircle"
import Button from "../../../ui/molecules/button"
import Input from "../../../ui/molecules/input"
import { setEditCompanyEmail } from "./slice"

export function CompanyEmails() {
    const companiesState = useAppSelector(state => state.companies)
    const dispatch = useAppDispatch()

    if (!companiesState.findCompanyResponse) {
        return <></>
    }

    return (
        <>
            {
                companiesState.editCompanyRequest.emails?.map((email, index) => (
                    <div className="flex items-end">
                        <Input
                            onChangeText={(text) => {
                                const emails = [...companiesState.editCompanyRequest.emails]
                                emails[index] = text
                                dispatch(setEditCompanyEmail([...emails]))
                            }}
                            label="Email"
                            error={companiesState.errors.validateEdit.email[index]}
                            supportingText={companiesState.errors.validateEdit.email[index] ? "Inserisci un'email valida" : ''}
                            startIcon={<MailIcon color={""} size={0} />}
                            value={email}
                        />
                        <Button
                            size={"sm"}
                            iconPosition={"only"}
                            icon={<XCircleIcon color={""} size={0} />}
                            variant={"ghost"}
                            color={"blue"}
                            onClick={() => {
                                const emails = [...companiesState.editCompanyRequest.emails]
                                const emailsRequest = [...emails.slice(0, index), ...emails.slice(index + 1)]
                                dispatch(setEditCompanyEmail([...emailsRequest]))
                            }}
                        />
                    </div>
                ))
            }
            <div className="flex items-end justify-end">
                <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"link"}
                    color={"blue"}
                    label="Aggiungi email"
                    onClick={() => {
                        dispatch(setEditCompanyEmail([...companiesState.editCompanyRequest.emails, '']))
                    }}
                />
            </div>
        </>
    )
}