import { PillsColor } from "../../ui/molecules/pills/Pills.types";
import { ItemsPerPageValues } from "../../ui/organisms/pagination/dto";

export interface ParticularityDTO {
  id: string;
  name: string;
  status: ParticularityStatusEnum;
  creatorUserId: null | string;
}

export enum ParticularityStatusEnum {
  IDLE = "IDLE",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
}

export interface FindAllParticularitiesResponse {
  data: ParticularityDTO[];
  next: boolean;
  page: number;
  perPage: number;
  prev: false;
  total: number;
  totalPage: number;
}

export interface UpsertParticularityDTO {
  name: string;
}

export interface ChangeStatusParticularityDTO {
  status: ParticularityStatusEnum;
}

export interface ParticularitiesFilters {
  itemsPerPage: ItemsPerPageValues;
  order?: boolean;
  page: number;
  sort?: keyof ParticularityDTO;
  name: string;
  status?: ParticularityStatusEnum;
}

export const ParticularityStatusMap = new Map<
  ParticularityStatusEnum,
  { label: string; color: PillsColor }
>([
  [ParticularityStatusEnum.ACTIVE, { label: "Attiva", color: "green" }],
  [ParticularityStatusEnum.CANCELED, { label: "Cancellata", color: "red" }],
  [ParticularityStatusEnum.IDLE, { label: "Inattiva", color: "gray" }],
]);
