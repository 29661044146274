import { ReactElement } from "react"
import { numericFormatter } from "react-number-format"
import { useAppDispatch } from "../../../lib/redux/hooks"
import { colors } from "../../../ui/colors"
import { GlobeIcon } from "../../../ui/icons/globe"
import { CompanyDTO } from "./dto"
import { findEmployees, setSelectedCompany, setSelectedCompanyNameForEmployees, setTabValueDetailsCompany } from "./slice"
import Pills from "../../../ui/molecules/pills"

interface Props {
    column: string
    index: number
    person: CompanyDTO
}

export const buildAddress = (address?: string, city?: string, province?: string, region?: string, state?: string, zipCode?: string | number): string => {
    let location = ''
    if (address) {
        location = address
    }
    if (city) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + city
    }
    if (province) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + province
    }
    if (region) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + region
    }
    if (state) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + state
    }
    if (zipCode) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + zipCode
    }
    return location === '' ? '-' : location
}

export function Cell(props: Props) {
    const { column, index, person } = props
    const dispatch = useAppDispatch()
    const cellMap = new Map<string, ReactElement>([
        ['Azienda',
            <div
                onClick={() => {
                    dispatch(setTabValueDetailsCompany(0))
                    dispatch(findEmployees(person.employeesId));
                    dispatch(
                        setSelectedCompanyNameForEmployees(person.name)
                    );
                    dispatch(setSelectedCompany(person.id))
                }}
                className="text-text-sm crop-text max-w-[200px] cursor-pointer font-semibold text-brandPrimary-500">
                {person.name}
            </div>
        ],
        ['Cod. ATECO',
            <div
                className="text-text-sm text-neutral-600 crop-text max-w-[150px]"
            >
                {person.atecos ? person.atecos[0]?.category : "-"}
            </div>
        ],
        ['Fatturato',
            <div
                className="text-text-sm text-neutral-600 crop-text max-w-[120px]"
            >
                {person.revenue ? numericFormatter(person.revenue.toString(), { thousandSeparator: '.', decimalSeparator: ',', fixedDecimalScale: true, decimalScale: 2, prefix: '€ ' }) : ''}
            </div>
        ],
        ['Telefono',
            person.toVerifyFields?.includes('phone') ?
                <Pills
                    size={"xs"}
                    label={person.phones && person.phones[0] ? (person.phones[0] + (person.phones.length > 1 ? (', ..., + ' + (person.phones.length - 1)) : '')) : '-'}
                    outline={true}
                    emphasis={true}
                    color={"red"}
                /> :
                <a
                    rel="noreferrer"
                    target="_blank"
                    href={'tel:' + (person.phones && person.phones[0].phone ? person.phones[0].phone : '')}
                >
                    <div className="crop-text max-w-[150px] text-text-sm text-neutral-600">
                        {person.phones && person.phones[0] ? (person.phones[0].phone + (person.phones.length > 1 ? (', ..., + ' + (person.phones.length - 1)) : '')) : '-'}
                    </div>
                </a>
        ],
        ['Email',
            <a
                rel="noreferrer"
                target="_blank"
                href={'mailto:' + (person.emails)}
            >
                <div className="crop-text max-w-[200px] text-text-sm text-neutral-600">
                    {person.emails && person.emails[0] ? (person.emails[0] + (person.emails.length > 1 ? (', ..., + ' + (person.emails.length - 1)) : '')) : '-'}
                </div>
            </a>
        ],
        ['Sede azienda',
            <span
                className="text-text-sm text-neutral-600 crop-text max-w-[200px]"
            >
                {buildAddress(person.address, person.city, person.province, person.countryRegion, person.state, person.zipCode)}
            </span>
        ],
        ['P. IVA',
            <div
                className="text-text-sm text-neutral-600 crop-text max-w-[100px]"
            >
                {person.vat}
            </div>
        ],
        ['Settore',
            person.toVerifyFields?.includes('sector') ?
                <Pills
                    size={"xs"}
                    label={person.atecos ? person.atecos[0]?.sector! : "-"}
                    outline={true}
                    emphasis={true}
                    color={"red"}
                /> :
                <div
                    className="text-text-sm text-neutral-600 crop-text max-w-[150px]"
                >
                    {person.atecos ? person.atecos[0]?.sector : "-"}
                </div>
        ],
        ['Categoria',
            person.toVerifyFields?.includes('category') ?
                <Pills
                    size={"xs"}
                    label={person.atecos ? person.atecos[0]?.category! : "-"}
                    outline={true}
                    emphasis={true}
                    color={"red"}
                /> :
                <div
                    className="text-text-sm text-neutral-600 crop-text max-w-[150px]"
                >
                    {person.atecos ? person.atecos[0]?.category : "-"}
                </div>
        ],
        [
            "Stato",
            <div>
                <Pills
                    size={"xs"}
                    label={person.active ? "Attivo" : "Inattivo"}
                    outline={false}
                    emphasis={false}
                    color={person.active ? "green" : "blue"}
                />
            </div>,
        ],
        ['Dipendenti',
            <div
                className="text-text-sm text-neutral-600 crop-text max-w-[100px]"
            >
                {person.employeesNumber ? numericFormatter(person.employeesNumber.toString(), { thousandSeparator: '.' }) : ''}
            </div>
        ],
        ['Sito web', <a rel="noreferrer" target="_blank" href={person.website} className="flex items-center justify-center"><GlobeIcon color={colors.neutral[600]} size={20} /></a>],
    ])

    return (
        <td>
            {cellMap.get(column)}
        </td>
    )
}