import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { Spinner } from "../../ui/molecules/spinner";
import { Banner } from "../../ui/organisms/banner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { NotFound } from "../../ui/organisms/notFound";
import { UsersFilters } from "./filters";
import { UsersList } from "./list";
import {
  findAllUsers,
  findUsersAvatarsList,
  setCreateFromProject,
  setCreateUserRole,
  setCreateUserStatus,
  setDeleteUserStatus,
  setFindAllUsersFiltersPage,
  setFindUserByIdResponse,
  setNewUserResponse,
  setSelectedUser,
  setValidateNewUserStatus,
  setValidateUpdateUserStatus,
} from "./slice";

export function Users() {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const usersState = useAppSelector((state) => state.users);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    dispatch(findAllUsers(usersState.filters));
    dispatch(setFindUserByIdResponse(undefined));
    dispatch(setValidateNewUserStatus("idle"));
    dispatch(setValidateUpdateUserStatus("idle"));
    dispatch(setNewUserResponse(""));
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(setFindAllUsersFiltersPage(0));
      dispatch(findAllUsers({ ...usersState.filters, page: 0 }));
    }
  }, [
    usersState.filters.role,
    usersState.filters.itemsPerPage,
    usersState.filters.order,
    usersState.filters.sort,
    usersState.filters.search,
  ]);

  useEffect(() => {
    if (!isFirstLoad) dispatch(findAllUsers(usersState.filters));
  }, [usersState.filters.page]);

  useEffect(() => {
    if (usersState.selectedUser !== undefined) {
      navigation("/edit-user/" + usersState.selectedUser);
      dispatch(setSelectedUser(undefined));
    }
  }, [usersState.selectedUser]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      usersState.deleteUserStatus === "failed" ||
      usersState.deleteUserStatus === "successfully"
    ) {
      if (usersState.deleteUserStatus === "successfully") {
        dispatch(findAllUsers(usersState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteUserStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [usersState.deleteUserStatus]);

  useEffect(() => {
    if (
      usersState.findAllStatus === "successfully" &&
      usersState.findAllResponse !== undefined
    ) {
      dispatch(
        findUsersAvatarsList(
          usersState.findAllResponse.data.map((user) => ({
            userId: user.id,
            objectId: user.avatarObjectId,
          }))
        )
      );
    }
  }, [usersState.findAllStatus]);

  return (
    <Layout
      menuItem={MenuItems.USERS}
      breadcrumbItems={["Utenti"]}
      headerLabel="Utenti"
      headerChildren={
        <div className="flex items-center h-[100%] w-[100%] justify-end">
          <Button
            size={"sm"}
            iconPosition={"off"}
            label="Aggiungi utente"
            variant={"solid"}
            color={"blue"}
            onClick={() => {
              dispatch(setCreateUserStatus("idle"));
              dispatch(setCreateUserRole(undefined))
              dispatch(setCreateFromProject(undefined))
              navigation("/new-user");
            }}
          />
        </div>
      }
    >
      <div className="p-[24px] h-full max-h-full">
        <div className="fixed flex flex-col gap-4 top-6 z-10 right-6">
          <Banner
            label={"Utente eliminato."}
            visible={usersState.deleteUserStatus === "successfully"}
            closeAction={function (): void {
              dispatch(setDeleteUserStatus("idle"));
            }}
            type={"success"}
          />
          <Banner
            label={
              "Si è verificato un errore durante l'eliminazione dell'utente."
            }
            visible={usersState.deleteUserStatus === "failed"}
            closeAction={function (): void {
              dispatch(setDeleteUserStatus("idle"));
            }}
            type={"error"}
          />
        </div>
        <div className="flex flex-col h-full max-h-full bg-white rounded-xl shadow-sm">
          <div className="p-4">
            <span className="text-heading-xs font-semibold text-neutral-800">
              Overview
            </span>
          </div>
          <UsersFilters />
          {usersState.findAllStatus === "loading" ? (
            <div className="w-full h-[50%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : usersState.findAllResponse?.data.length === 0 ||
            usersState.findAllResponse?.data === null ? (
            <div style={{ height: 'calc(100% - 64px - 60px)' }}>
              <NotFound />
            </div>
          ) : (
            <UsersList />
          )}
        </div>
      </div>
    </Layout>
  );
}
