import { useEffect } from "react";
import { keycloak } from "./app/lib/keycloak";
import {
  setKeycloakInitStatus,
  setKeycloakUserInfo,
  setRoles,
} from "./app/lib/keycloak/slice";
import {
  createPreferences,
  findAllAtecos,
  findAllPreferences,
  findAllProjectSuspended,
  findAllTargetListSuspended,
  setCreatePreferencesStatus,
  setFindAllPreferencesStatus,
  setFindAllTargetListsSuspendedStatus,
  setUpdatePreferencesStatus,
  updatePreferences,
} from "./app/lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "./app/lib/redux/hooks";
import { KeycloakRouter } from "./app/lib/router";
import {
  checkPreferences,
  companyColumns,
  peopleColumns,
  targetListColumns,
  targetListCompanyColumns,
  targetListPersonColumns,
} from "./app/pages/database/dto";
import { ProjectChangeStatusModal } from "./app/pages/projects/changeStatusModal";
import { setProjectChangeStateStatus as setChangeStateStatusProjects } from "./app/pages/projects/slice";
import { TargetListChangeStatusModal } from "./app/pages/targetList/changeStatusModal";
import { setTargetListChangeStateStatus } from "./app/pages/targetList/slice";
import { Spinner } from "./app/ui/molecules/spinner";
import { Banner } from "./app/ui/organisms/banner";
import { findMe } from "./app/pages/users/slice";

function App() {
  const dispatch = useAppDispatch();
  const preferencesState = useAppSelector((state) => state.preferences);
  const targetListsState = useAppSelector((state) => state.targetList);
  const projectsState = useAppSelector((state) => state.projects);
  const usersState = useAppSelector((state) => state.users);

  useEffect(() => {
    if (window.location.pathname === "/")
      window.location.replace(window.location.origin + "/dashboard");
  }, [window.location.pathname]);

  const keycloakState = useAppSelector((state) => state.keycloak);

  keycloak.onAuthSuccess = () => {
    keycloak.loadUserInfo().then((e) => dispatch(setKeycloakUserInfo(e)));
    if (keycloak.hasRealmRole("company")) {
      keycloak.logout({ redirectUri: window.location.origin });
    }
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles));
    dispatch(setKeycloakInitStatus("successfully"));
    dispatch(findAllPreferences(keycloak.subject!));
    dispatch(findAllAtecos());
    dispatch(findAllTargetListSuspended());
    dispatch(findMe())
  };

  useEffect(() => {
    if (
      preferencesState.findAllStatus === "successfully" &&
      preferencesState.findAllResponse !== undefined
    ) {
      dispatch(setFindAllPreferencesStatus("idle"));
      if (preferencesState.findAllResponse.data.length === 0) {
        dispatch(
          createPreferences({
            owner: "",
            personPreferences: peopleColumns,
            companyPreferences: companyColumns,
            targetListPreferences: targetListColumns,
            targetListCompanyPreferences: targetListCompanyColumns,
            targetListPersonPreferences: targetListPersonColumns
          })
        );
      }
      else if (
        !checkPreferences(preferencesState.findAllResponse.data[0].companyPreferences, companyColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].personPreferences, peopleColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].targetListPreferences, targetListColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].targetListCompanyPreferences, targetListCompanyColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].targetListPersonPreferences, targetListPersonColumns)
      ) {
        dispatch(updatePreferences({
          data: {
            owner: preferencesState.findAllResponse?.data[0].owner!,
            personPreferences: checkPreferences(preferencesState.findAllResponse.data[0].personPreferences, peopleColumns) ? preferencesState.findAllResponse.data[0].personPreferences : peopleColumns,
            companyPreferences: checkPreferences(preferencesState.findAllResponse.data[0].companyPreferences, companyColumns) ? preferencesState.findAllResponse.data[0].companyPreferences : companyColumns,
            targetListPreferences: checkPreferences(preferencesState.findAllResponse.data[0].targetListPreferences, targetListColumns) ? preferencesState.findAllResponse.data[0].targetListPreferences : targetListColumns,
            targetListPersonPreferences: checkPreferences(preferencesState.findAllResponse.data[0].targetListPersonPreferences, targetListPersonColumns) ? preferencesState.findAllResponse.data[0].targetListPersonPreferences : targetListPersonColumns,
            targetListCompanyPreferences: checkPreferences(preferencesState.findAllResponse.data[0].targetListCompanyPreferences, targetListCompanyColumns) ? preferencesState.findAllResponse.data[0].targetListCompanyPreferences : targetListCompanyColumns
          },
          id: preferencesState.findAllResponse?.data[0].id!,
        }))
      }
    }
  }, [preferencesState.findAllStatus]);

  useEffect(() => {
    if (
      preferencesState.findAllTargetListSuspendedStatus === "successfully" &&
      preferencesState.findAllTargetListSuspendedResponse !== undefined &&
      preferencesState.findAllTargetListSuspendedResponse.total === 0
    ) {
      dispatch(findAllProjectSuspended());
      dispatch(setFindAllTargetListsSuspendedStatus("idle"));
    }
  }, [preferencesState.findAllTargetListSuspendedStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListsState.changeStateStatus === "failed" ||
      targetListsState.changeStateStatus === "successfully"
    ) {
      dispatch(setFindAllTargetListsSuspendedStatus("idle"));
      if (targetListsState.changeStateStatus === "successfully") {
        dispatch(findAllProjectSuspended());
      }
      timeoutId = setTimeout(() => {
        dispatch(setTargetListChangeStateStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListsState.changeStateStatus]);

  useEffect(() => {
    if (
      preferencesState.createStatus === "successfully" ||
      preferencesState.updateStatus === "successfully"
    ) {
      dispatch(setCreatePreferencesStatus("idle"));
      dispatch(setUpdatePreferencesStatus("idle"));
      dispatch(findAllPreferences(keycloak.subject!));
    }
  }, [preferencesState.createStatus, preferencesState.updateStatus]);

  keycloak.onAuthRefreshError = () => {
    keycloak.logout();
  };

  if (
    keycloakState.keycloakInitStatus === "idle" ||
    preferencesState.findAllTargetListSuspendedStatus === "loading" ||
    preferencesState.findAllProjectSuspendedStatus === "loading" ||
    usersState.findMeStatus === 'loading'
  ) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="App">
      <div className="fixed flex flex-col gap-4 top-6 z-10 right-6">
        <Banner
          label={"Liste target aggiornate."}
          visible={targetListsState.changeStateStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setTargetListChangeStateStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'aggiornamento delle liste target."
          }
          visible={targetListsState.changeStateStatus === "failed"}
          closeAction={function (): void {
            dispatch(setTargetListChangeStateStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Progetti aggiornati."}
          visible={projectsState.changeStateStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setChangeStateStatusProjects("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'aggiornamento dei progetti."
          }
          visible={projectsState.changeStateStatus === "failed"}
          closeAction={function (): void {
            dispatch(setChangeStateStatusProjects("idle"));
          }}
          type={"error"}
        />
      </div>
      <KeycloakRouter />
      <TargetListChangeStatusModal />
      <ProjectChangeStatusModal />
    </div>
  );
}

export default App;
