import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { setTabValue } from "../slice";
import { Filters } from "./filters";
import { PeopleHorizontalFilters } from "./filters/horizontalFilters";
import { PeopleList } from "./list";
import {
  findAllPeople,
  personCount,
  setFindAllPeopleFiltersPage
} from "./slice";

export function People() {
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFirstLoadPeople, setIsFirstLoadPeople] = useState(true);

  useEffect(() => {
    dispatch(findAllPeople({ ...peopleState.filters, targetList: [], targetListBin: undefined }));
    setIsFirstLoadPeople(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoadPeople) {
      dispatch(setFindAllPeopleFiltersPage(0));
      dispatch(findAllPeople({ ...peopleState.filters, page: 0 }));
    }
  }, [
    peopleState.filters.order,
    peopleState.filters.itemsPerPage,
    peopleState.filters.sort,
    peopleState.filters.personName,
    peopleState.filters.personEmail,
    peopleState.filters.hasCompany,
    peopleState.filters.atecos,
    peopleState.filters.category,
    peopleState.filters.city,
    peopleState.filters.countryRegion,
    peopleState.filters.department,
    peopleState.filters.employees,
    peopleState.filters.active,
    peopleState.filters.name,
    peopleState.filters.privacy,
    peopleState.filters.revenue,
    peopleState.filters.role,
    peopleState.filters.sector,
    peopleState.filters.hasSmartphone,
    peopleState.filters.state,
    peopleState.filters.zipCode,
    peopleState.filters.targetList,
    peopleState.filters.project,
    peopleState.filters.outcome,
    peopleState.filters.particularity,
    peopleState.filters.smartphone,
    peopleState.filters.creationContactDate,
    peopleState.filters.lastContactDate,
    peopleState.filters.outcomeInfo
  ]);

  useEffect(() => {
    if (!isFirstLoadPeople) dispatch(findAllPeople(peopleState.filters));
  }, [peopleState.filters.page]);

  useEffect(() => {
    if (
      peopleState.importCSVStatus === "failed" ||
      peopleState.importCSVStatus === "successfully"
    ) {
      navigate("/database");
      dispatch(setTabValue(0));
    }
  }, [peopleState.importCSVStatus]);

  useEffect(() => {
    if (peopleState.findAllStatus === "successfully") {
      dispatch(personCount())
    }
  }, [peopleState.findAllStatus]);

  return (
    <div className="max-h-full w-full flex" style={{ height: 'calc(100% - 63.8px)' }}>
      <div className="flex w-full">
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters isInTargetList={false} />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {peopleState.findAllStatus === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full">
              <PeopleHorizontalFilters />
              <PeopleList />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
