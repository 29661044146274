export const addToTextArea = function (text_to_add: string) {
  let textarea = document.querySelector('textarea')!
  let start_position = textarea.selectionStart;
  let end_position = textarea.selectionEnd;

  textarea.value = textarea.value.substring(
    0,
    start_position
  ) + " " + text_to_add + " " + textarea.value.substring(
    end_position,
    textarea.value.length
  );
};

export function HTMLfy(bodyText: string) {
  const body = document.createElement('body')
  body.style.fontSize = '15px'
  body.style.lineHeight = '15px'
  body.style.maxWidth = '600px'
  body.style.padding = '20px'
  body.style.textAlign = 'center'
  body.style.margin = '0 auto'
  body.style.color = '#323647'
  const div = document.createElement('div')
  div.style.marginTop = '20px'
  body.appendChild(div)
  div.textContent = bodyText
  return (body.outerHTML).replace(/\{\{(.*?)\}\}/g, "<strong>{{$1}}</strong>").replace(/\*sito_web_cliente/g, (match) => {
    return `<a href="${match.slice(1)}">${match.slice(1)}</a>`}).replace(/"/g, '\"')
}

/* export const variablesMap = new Map<string, string>(
  [
    ['nome_cliente', '{{.Body.customerName}}'],
    ['nome_azienda', '{{.Body.leadCompanyName}}'],
    ['data_appuntamento', '{{.Body.appointmentDate}}'],
    ['motivo', '{{.Body.reason}}'],
    ['nome_commerciale', '{{.Body.commercialName}}'],
    ['orario_appuntamento', '{{.Body.appointmentTime}}'],
    ['data_vecchio_appuntamento', '{{.Body.oldAppointmentDate}}'],
  ]
) */

/* const variablesInvertedMap = new Map<string, string>(
  [
    ['{{.Body.customerName}}', 'nome_cliente'],
    ['{{.Body.leadCompanyName}}', 'nome_azienda'],
    ['{{.Body.appointmentDate}}', 'data_appuntamento'],
    ['{{.Body.reason}}', 'motivo'],
    ['{{.Body.commercialName}}', 'nome_commerciale'],
    ['{{.Body.appointmentTime}}', 'orario_appuntamento'],
    ['{{.Body.oldAppointmentDate}}', 'data_vecchio_appuntamento'],
  ]
) */

export const variablesMap = new Map<string, string>([
  ['nome_commerciale', '{{.Body.commercialName}}'],
  ['nome_teamLeader', '{{.Body.teamLeaderName}}'],
  ['ragione_sociale_cliente', '{{.Body.customerCompanyName}}'],
  ['sito_web_cliente', '{{.Body.customerCompanyWebsite}}'],
  ['nome_destinatario', '{{.Body.addressee}}'],
]
)

export const variablesInvertedMap = new Map<string, string>([
  ['{{.Body.commercialName}}', 'nome_commerciale'],
  ['{{.Body.teamLeaderName}}', 'nome_teamLeader'],
  ['{{.Body.customerCompanyName}}', 'ragione_sociale_cliente'],
  ['{{.Body.customerCompanyWebsite}}', 'sito_web_cliente'],
  ['{{.Body.addressee}}', 'nome_destinatario'],
])

export function translateVariables(text: string) {
  return text.replace(/\{\{(.*?)\}\}/g, (match) => "[" + variablesInvertedMap.get(match) + ']') 
}