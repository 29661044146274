import { roleDepartment } from "../../../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { groupBy } from "../../../../lib/utils";
import { LinkedinIcon } from "../../../../ui/icons/linkedin";
import { MailIcon } from "../../../../ui/icons/mail";
import { PhoneCallIcon } from "../../../../ui/icons/phoneCall";
import Input from "../../../../ui/molecules/input";
import { SelectCustom } from "../../../../ui/molecules/select";
import Toggle from "../../../../ui/molecules/toggle";
import { setEditPersonDepartment, setEditPersonEmail, setEditPersonLinkedinProfile, setEditPersonName, setEditPersonPhone, setEditPersonPrivacy, setEditPersonRole, setEditPersonSurname, setEditPersonToVerifyFields } from "../slice";

export function EditPersonForm() {
    const peopleState = useAppSelector(state => state.people)
    const dispatch = useAppDispatch()

    return (
        <div key={0} className="px-6 py-4 flex flex-col gap-4">
            <div className="flex gap-2">
                <Input
                    label="Nome"
                    disabled={peopleState.findPersonResponse?.active}
                    value={peopleState.editPersonRequest?.name ?? ""}
                    error={peopleState.errors.validateUpdate.name}
                    supportingText={peopleState.errors.validateUpdate.name ? 'Inserire il nome' : ''}
                    onChangeText={(text) => dispatch(setEditPersonName(text))}
                />
                <Input
                    label="Cognome"
                    disabled={peopleState.findPersonResponse?.active}
                    error={peopleState.errors.validateUpdate.surname}
                    supportingText={peopleState.errors.validateUpdate.surname ? 'Inserire il cognome' : ''}
                    value={peopleState.editPersonRequest?.surname ?? ""}
                    onChangeText={(text) =>
                        dispatch(setEditPersonSurname(text))
                    }
                />
            </div>
            <div className="flex flex-col gap-[6px]">
                <SelectCustom
                    isClearable
                    label="Ruolo"
                    menuPortalTargetIsNotBody
                    disabled={peopleState.findPersonResponse?.active}
                    onChange={(e) => {
                        if (e === undefined || typeof e === "string") {
                            dispatch(setEditPersonRole(e));
                        }
                    }}
                    defaultValue={
                        peopleState.editPersonRequest?.role ?? undefined
                    }
                    placeholder={"Cerca per ruolo"}
                    options={groupBy(roleDepartment, "role").map((sector) => ({
                        value: sector.key,
                        label: sector.key,
                    }))}
                    noOptionsMessage="Non sono presenti ruoli."
                />
            </div>
            <Input
                label="Reparto"
                disabled
                value={
                    roleDepartment.find(
                        (role) => role.role === peopleState.editPersonRequest.role
                    )?.department || ""
                }
                onChangeText={(text) =>
                    dispatch(setEditPersonDepartment(text))
                }
            />
            <Toggle
                disabled={peopleState.findPersonResponse?.active}
                placeholder="Permessi privacy"
                checked={peopleState.editPersonRequest?.privacy ?? false}
                onChange={function (value: boolean): void {
                    dispatch(setEditPersonPrivacy(value));
                }}
            />
            <Input
                label="Cellulare"
                endIcon={<PhoneCallIcon color={""} size={0} />}
                value={peopleState.editPersonRequest?.phone ?? ""}
                error={peopleState.errors.validateUpdate.phone}
                supportingText={peopleState.errors.validateUpdate.phone ? 'Inserire un numero di telefono valido' : ''}
                onChangeText={(text) => {
                    dispatch(setEditPersonPhone(text))
                    if (peopleState.editPersonRequest.toVerifyFields !== null)
                        dispatch(setEditPersonToVerifyFields([...peopleState.editPersonRequest.toVerifyFields.filter(field => field !== 'phone')]))
                }}
            />
            <Input
                label="Mail"
                error={peopleState.errors.validateUpdate.email}
                supportingText={peopleState.errors.validateUpdate.email ? "Inserire un'email valida" : ''}
                endIcon={<MailIcon color={""} size={0} />}
                value={peopleState.editPersonRequest?.email ?? ""}
                onChangeText={(text) => dispatch(setEditPersonEmail(text))}
            />
            <Input
                label="LinkedIn"
                endIcon={<LinkedinIcon color={""} size={0} />}
                value={peopleState.editPersonRequest?.linkedinProfile ?? ""}
                onChangeText={(text) =>
                    dispatch(setEditPersonLinkedinProfile(text))
                }
            />
        </div>
    )
}