import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { EditIcon } from "../../ui/icons/edit";
import { SortIcon } from "../../ui/icons/sort";
import { TrashIcon } from "../../ui/icons/trash";
import Avatar from "../../ui/molecules/avatar";
import Pills from "../../ui/molecules/pills";
import { PillsColor } from "../../ui/molecules/pills/Pills.types";
import { Spinner } from "../../ui/molecules/spinner";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../ui/organisms/dropdown";
import { Pagination } from "../../ui/organisms/pagination";
import { ProjectStateEnum } from "./dto";
import {
  deleteProject,
  setProjectFilterItemsPerPage,
  setProjectFilterOrder,
  setProjectFilterPage,
  setProjectFilterSort,
  setSelectedProject,
} from "./slice";
import { keycloak } from "../../lib/keycloak";

export function ProjectsList() {
  const projectsState = useAppSelector((state) => state.projects);
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    if (projectsState.deleteProjectStatus === "successfully") {
      setIdToDelete(undefined);
    }
  }, [projectsState.deleteProjectStatus]);

  if (projectsState.findAllStatus === "loading") {
    return (
      <div className="w-full flex items-center justify-center h-[45%]">
        <Spinner />
      </div>
    );
  }

  const projectStateMap = new Map<
    ProjectStateEnum,
    { label: string; color: PillsColor }
  >([
    [ProjectStateEnum.TOSTART, { label: "Da iniziare", color: "gray" }],
    [ProjectStateEnum.CONCLUDED, { label: "Concluso", color: "green" }],
    [ProjectStateEnum.INPROGRESS, { label: "In corso", color: "blue" }],
    [ProjectStateEnum.SUSPENDED, { label: "Sospeso", color: "orange" }],
  ]);

  return (
    <div className="w-[100%] h-full max-h-full flex flex-col"
      style={{ height: 'calc(100% - 124px)' }}
    >
      <div className="overflow-auto" style={{ height: 'calc(100% - 48px)' }}>
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase sticky top-0 z-10 text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Nome progetto
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("name"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Settore
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("customer.companySector"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Team Leader
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("teamLeader.name"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-center">
                <div className="flex gap-[4px]">
                  Commerciali
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("commercials.name"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Data inizio
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("startDate"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Data fine
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("endDate"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Stato
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("state"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Liste target
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("targetListSize"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Contatti
                  <div
                    onClick={() => {
                      dispatch(setProjectFilterSort("contactsNumber"));
                      dispatch(
                        setProjectFilterOrder(!projectsState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] w-[20px]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {projectsState.findAllResponse?.data.map((project) => (
              <tr
                className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                key={project.id}
              >
                <td
                  onClick={() => dispatch(setSelectedProject(project.id))}
                  className="max-w-[200px] cursor-pointer crop-text text-text-sm font-semibold text-neutral-600">
                  {project.name}
                </td>
                <td className="max-w-[200px]">
                  {project.sector ? (
                    <Pills
                      size={"xs"}
                      label={project.sector}
                      outline={false}
                      emphasis={false}
                      color={"blue"}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <div className="flex items-center gap-2">
                    <Avatar
                      type="single"
                      size="sm"
                      shape="circle"
                      imageUrl={
                        usersState.findUsersAvatarsListResponse.find(
                          (avatar) => avatar.userId === project.teamLeader.id
                        )?.objectId ?? ""
                      }
                      fullName={project.teamLeader.name + " " +
                        project.teamLeader.surname}
                      altTextInitials={
                        project.teamLeader.name[0] +
                        project.teamLeader.surname[0]
                      }
                    />
                    {project.teamLeader.name + " " + project.teamLeader.surname}
                  </div>
                </td>
                <td>
                  <Avatar
                    type="group"
                    size="sm"
                    length={2}
                    imageUrls={project.commercials.map((commercial) => ({
                      altTextInitials:
                        commercial.name[0] + commercial.surname[0],
                      fullNames: (commercial.name + " " + commercial.surname),
                      url:
                        usersState.findUsersAvatarsListResponse.find(
                          (avatar) => avatar.userId === commercial.id
                        )?.objectId ?? "ciaoooo",
                    }))}
                  />
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {project.startDate
                    ? format(new Date(project.startDate), "dd/MM/yyyy")
                    : ""}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {project.endDate
                    ? format(new Date(project.endDate), "dd/MM/yyyy")
                    : ""}
                </td>
                <td>
                  <Pills
                    size={"xs"}
                    label={projectStateMap.get(project.state)?.label!}
                    outline={false}
                    emphasis={false}
                    color={projectStateMap.get(project.state)?.color!}
                  />
                </td>
                <td>
                  <Pills
                    size={"xs"}
                    label={project.targetListsIds?.length.toString() ?? "0"}
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td>
                  <Pills
                    size={"xs"}
                    label={project.contactsNumber?.toString() ?? "0"}
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="w-[20px] cursor-pointer">
                  <Dropdown
                    items={[
                      {
                        label: "Modifica",
                        icon: <EditIcon color={""} size={0} />,
                        onClick: () => dispatch(setSelectedProject(project.id)),
                      },
                      (keycloak.hasRealmRole('customer') ? null : {
                        label: "Cancella",
                        icon: <TrashIcon color={""} size={0} />,
                        onClick: () => setIdToDelete(project.id),
                      }),
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsPerPage={projectsState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setProjectFilterItemsPerPage(value))}
        found={projectsState.findAllResponse?.total || 0}
        pages={projectsState.findAllResponse?.totalPage || 1}
        currentPage={projectsState.filters.page}
        setPage={(page) => dispatch(setProjectFilterPage(page))}
      />
      <ConfirmModal
        title={"Elimina progetto"}
        label={"Sei sicuro di voler eliminare questo progetto?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteProject(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
