import { FindAllCompaniesFilters } from "../database/companies/dto";
import { ImportCSVResponseDTO } from "../database/dto";
import { FindAllPeopleFilters } from "../database/people/dto";
import {
  ContactStateEnum,
  EditTargetListRequest,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  NoteDTO,
  TargetListCounterDTO,
  TargetListDTO,
  TargetListStatusEnum,
} from "./dto";
import { TargetListsServiceImpl } from "./serviceImpl";

export interface TargetListsService {
  deleteTargetList(id: string): Promise<void>;
  createTargetList(request: NewTargetListRequest): Promise<string>;
  findTargetListById(id: string): Promise<TargetListDTO>;
  findAllTargetLists(
    filters: FindAllTargetListsFilters
  ): Promise<FindAllTargetListsResponse>;
  importContactCSV(
    data: FormData,
    type: "persons" | "companies",
    targetListId: string
  ): Promise<ImportCSVResponseDTO>;
  removeContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void>;
  changeContactStatus(
    targetListId: string,
    type: "persons" | "companies",
    contactId: string,
    contactState?: ContactStateEnum,
    stateInfo?: string | null
  ): Promise<void>;
  addContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void>;
  changeStatus(
    request: {
      state:
      | TargetListStatusEnum.CONCLUDED
      | TargetListStatusEnum.INPROGRESS
      | undefined;
      id: string;
    }[]
  ): Promise<void>;
  editTargetList(
    targetListId: string,
    data: EditTargetListRequest
  ): Promise<void>
  getCounters(
    targetListId: string
  ): Promise<TargetListCounterDTO>
  addPeopleBulk(
    targetListId: string,
    filters: FindAllPeopleFilters
  ): Promise<void>
  addCompaniesBulk(
    targetListId: string,
    filters: FindAllCompaniesFilters
  ): Promise<void>
  updateNotes(
    targetListId: string,
    type: "persons" | "companies",
    contactId: string,
    note: NoteDTO[]
  ): Promise<void>
  restoreContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[],
  ): Promise<void>
}

export function NewTargetListsService(): TargetListsService {
  return new TargetListsServiceImpl();
}
