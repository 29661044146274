export interface PaginationProps {
    found: number
    pages: number
    currentPage: number
    setPage: (page: number) => void
    itemsPerPage: ItemsPerPageValues
    setItemsPerPage: (items: ItemsPerPageValues) => void
    fixedPagination?: boolean
}

export const itemsPerPageValues = [25, 50, 100, 200, 500]
export type ItemsPerPageValues = 25 | 50 | 100 | 200 | 500