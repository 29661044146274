import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { MaximizeIcon } from "../../../../ui/icons/maximize";
import { SelectCustomAsync } from "../../../../ui/molecules/asyncSelect";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { NewParticularitiesService } from "../../../particularities/service";
import { setFindAllCompaniesFiltersParticularity } from "../slice";

export function ParticularitiesFilters() {
    const companiesState = useAppSelector(state => state.companies)
    const dispatch = useAppDispatch()

    const filterParticularity = async (inputValue: string) => {
        return await NewParticularitiesService().findAllParticularities({
            order: true,
            sort: 'name',
            itemsPerPage: 25,
            page: 0,
            name: ""
        }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ value: string; label: string; }[]>((resolve) => {
            resolve(filterParticularity(inputValue));
        });

    return (
        <AccordionFilter
            key={"person-filter-particularities"}
            label={"Particolarità"}
            icon={<MaximizeIcon color={""} size={0} />}
            showClear={companiesState.filters.particularity.length > 0}
            clearAction={() => dispatch(setFindAllCompaniesFiltersParticularity([]))}
            deleteFilter={(option) => dispatch(setFindAllCompaniesFiltersParticularity(companiesState.filters.particularity.filter(name => name.value !== option.value)))}
            options={companiesState.filters.particularity}
        >
            <SelectCustomAsync
                isMulti
                onChange={e => dispatch(setFindAllCompaniesFiltersParticularity(e))}
                defaultValue={companiesState.filters.particularity}
                noOptionsMessage="Non sono presenti particolarità."
                loadOptions={promiseOptions}
                defaultOptions
                isClearable={false}
                placeholder={"Cerca per particolarità"}
            />
        </AccordionFilter>
    )
}