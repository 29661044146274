import { createSlice } from "@reduxjs/toolkit"
import { MenuItems } from "../organisms/navbar/dto"
import { MenuStatus } from "./dto"

interface LayoutState {
    menuItems: { item: MenuItems, section: number }[]
    menuStatus: MenuStatus
}

const initialState: LayoutState = {
    menuItems: [],
    menuStatus: MenuStatus.CLOSED
}

const layoutSlice = createSlice({
    name: 'layout/slice',
    initialState,
    reducers: {
        setMenuItems: (state, action) => {
            state.menuItems = action.payload
        },
        setMenuStatus: (state, action) => {
            state.menuStatus = action.payload
        },
    },
})

export const {
    setMenuItems,
    setMenuStatus
} = layoutSlice.actions

export default layoutSlice.reducer