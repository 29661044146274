import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { TrashIcon } from "../../ui/icons/trash";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { Banner } from "../../ui/organisms/banner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { setSelectedCompany } from "../database/companies/slice";
import { EditTargetList } from "./editTargetListDetails/index";
import { findTargetListById, setRemoveContactsStatus, setSelectedTargetBins } from "./slice";
import { setSelectedPerson } from "../database/people/slice";

export function EditTargetListPage() {
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();
  const navigation = useNavigate()

  useEffect(() => {
    dispatch(findTargetListById(targetListsState.selectedTargetList!));
    dispatch(setSelectedCompany(undefined))
    dispatch(setSelectedPerson(undefined))
  }, []);

  useEffect(() => {
    if (targetListsState.selectedTargetBins !== undefined) {
      navigation("/target-list-bins/" + targetListsState.selectedTargetBins);
      dispatch(setSelectedTargetBins(undefined));
    }
  }, [targetListsState.selectedTargetBins]);

  return (
    <Layout
      headerLabel={"Modifica lista"}
      headerBackPath="/target-list"
      breadcrumbItems={[
        "Liste target",
        targetListsState.findTargetListResponse?.name!,
        (targetListsState.editTargetListTabValue === 0 ? 'Specifiche' : targetListsState.editTargetListTabValue === 1 ? 'Persone' : 'Aziende')
      ]}
      menuItem={MenuItems.TARGET_LIST}
      headerChildren={
        <div className="flex items-center justify-end w-full h-full">
          <Button
            size={"sm"}
            iconPosition={"left"}
            variant={"outline"}
            color={"blue"}
            icon={<TrashIcon color={""} size={0} />}
            label="Visualizza cestini"
            onClick={() => dispatch(setSelectedTargetBins(targetListsState.findTargetListResponse?.id!))}
          />
        </div>
      }
    >
      <div className="fixed flex flex-col gap-4 top-6 right-6">
        <Banner
          label={"Contatto rimosso dalla lista."}
          visible={targetListsState.removeContactsStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante la rimozione del contatto dalla lista target."
          }
          visible={targetListsState.removeContactsStatus === "failed"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <EditTargetList />
    </Layout>
  );
}
