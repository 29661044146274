import { useDebounce } from "../../../../lib/hooks/debounce";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { SearchIcon } from "../../../../ui/icons/search";
import Input from "../../../../ui/molecules/input";
import {
  setFindAllCompaniesFiltersFilter
} from "../slice";

export function CompaniesHorizontalFilters() {
  const companiesState = useAppSelector((state) => state.companies);
  const databaseState = useAppSelector((state) => state.database);
  const dispatch = useAppDispatch();
  const debounceCompanyFilter = useDebounce(
    (value) => dispatch(setFindAllCompaniesFiltersFilter(value)),
    1000
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        width: '100%',
        height: "64px",
        padding: "0 16px",
        backgroundColor: "white",
        borderBottom: "1px solid " + colors.neutral[200],
      }}
    >
      <div className="w-3/4">
        <Input
          key={'find person-horizontal-filter-companies'}
          startIcon={<SearchIcon color={""} size={0} />}
          placeholder="Cerca persona"
          onChangeText={(value) => debounceCompanyFilter(value)}
          defaultValue={companiesState.filters.filter}
        />
      </div>
    </div>
  );
}
