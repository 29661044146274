import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { findAllCompanies, findCompany, setEditCompanyStatus } from "../database/companies/slice";
import { setNewPersonStatus, setNewPersonResponse, findPerson, findAllPeople, setEditPersonStatus } from "../database/people/slice";
import { Banner } from "../../ui/organisms/banner";

export function BannerBlackList() {
    const peopleState = useAppSelector(state => state.people)
    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                dispatch(findAllCompanies(companiesState.filters));
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                dispatch(findAllCompanies(companiesState.filters));
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))
                dispatch(findAllPeople(peopleState.filters));
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    return (
        <div className="fixed flex flex-col gap-4 top-6 right-6 z-50">
            <Banner
                label={"Contatto aggiunto correttamente"}
                visible={peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"success"}
            />
            <Banner
                label={"Il contatto che stai provando ad inserire esiste ed è associato all'azienda"}
                visible={peopleState.newPersonStatus === "successfully" && peopleState.newPersonResponse?.alreadyExists === true}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"warning"}
            />
            <Banner
                label={peopleState.newPersonResponseMessage}
                visible={peopleState.newPersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"error"}
            />
            <Banner
                label={"Persona aggiornata."}
                visible={peopleState.editPersonStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setEditPersonStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica della persona."}
                visible={peopleState.editPersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setEditPersonStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Azienda aggiornata."}
                visible={companiesState.editCompanyStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setEditCompanyStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica dell'azienda."}
                visible={companiesState.editCompanyStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setEditCompanyStatus("idle"));
                }}
                type={"error"}
            />
        </div>
    )
}