import { ItemsPerPageValues } from "../../ui/organisms/pagination/dto";

export interface UserDTO {
  collaborators: string[] | null;
  customerId: string | null;
  email: string;
  name: string;
  avatarObjectId: string;
  id: string;
  role: UserRole;
  surname: string;
  userId: string;
  phone: string;
  isAutonomous: boolean;
  companyName?: string;
  companyVat?: string;
  companyAtecoCode?: string;
  companySector?: string;
  companyRevenue?: number;
  companyWebsite?: string;
  canAccessDatabase: boolean | null
}

export enum UserRole {
  admin = "admin",
  team_leader = "team_leader",
  commercial = "commercial",
  customer = "customer",
  collaborator = "collaborator",
}

export interface FindAllUsersFilters {
  role?: UserRole;
  search?: string;
  order: boolean;
  sort?: keyof UserDTO;
  itemsPerPage: ItemsPerPageValues;
  page: number;
}

export const UserRoleMap = new Map<UserRole, string>([
  [UserRole.admin, "Admin"],
  [UserRole.team_leader, "Team Leader"],
  [UserRole.commercial, "Commerciale"],
  [UserRole.customer, "Cliente"],
  [UserRole.collaborator, "Collaboratore"],
]);

export interface FindAllUserResponse {
  data: UserDTO[];
  next: boolean;
  page: number;
  perPage: number;
  prev: false;
  total: number;
  totalPage: number;
}

export interface EditUserRequest {
  name: string;
  surname: string;
  collaborators: string[];
  customerId: string;
  phone: string;
  isAutonomous: boolean;
  companyName?: string;
  companyVat?: string;
  companyAtecoCode?: string;
  companySector?: string;
  companyRevenue?: number;
  companyWebsite? : string;
  canAccessDatabase: boolean | null
}

export interface UserRoleUpdateDTO {
  role?: UserRole;
  teamLeaderId?: string;
}

export interface NewUserRequest {
  name: string;
  surname: string;
  email: string;
  objectId: string;
  role?: string;
  collaborators: string[];
  isAutonomous: boolean;
  customerId: string;
  phone: string;
  companyName?: string;
  companyVat?: string;
  companyAtecoCode?: string;
  companySector?: string;
  companyRevenue?: number;
  companyWebsite? : string
  canAccessDatabase: boolean | null
}
