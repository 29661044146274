import { ReactElement } from "react";
import { ActivityIcon } from "../../icons/activity";
import { CalendarIcon } from "../../icons/calendar";
import { DatabaseIcon } from "../../icons/database";
import { HomeIcon } from "../../icons/home";
import { ListIcon } from "../../icons/list";
import { RocketIcon } from "../../icons/rocket";
import { SettingsIcon } from "../../icons/settings";
import { TrelloIcon } from "../../icons/trello";
import { UsersIcon } from "../../icons/users";
import { MaximizeIcon } from "../../icons/maximize";
import { UserXIcon } from "../../icons/userX";
import { UserCheckIcon } from "../../icons/userCheck";

export enum MenuItems {
  DASHBOARD = "DASHBOARD",
  DATABASE = "DATABASE",
  TARGET_LIST = "TARGET_LIST",
  PROJECTS = "PROJECTS",
  PARTICULARITIES = "PARTICULARITIES",
  USERS = "USERS",
  PIPELINE = "PIPELINE",
  CALENDAR = "CALENDAR",
  REPORT = "REPORT",
  SETTINGS = "SETTINGS",
  BLACK_LIST = "BLACK_LIST",
  VERIFY_BINS = "VERIFY_BINS"
}

export const MenuItemsLabelMap = new Map<MenuItems, string>([
  [MenuItems.DASHBOARD, "Dashboard"],
  [MenuItems.DATABASE, "Database"],
  [MenuItems.TARGET_LIST, "Liste Target"],
  [MenuItems.PARTICULARITIES, "Particolarità"],
  [MenuItems.PROJECTS, "Progetti"],
  [MenuItems.USERS, "Utenti"],
  [MenuItems.PIPELINE, "Pipeline"],
  [MenuItems.CALENDAR, "Calendario"],
  [MenuItems.REPORT, "Report"],
  [MenuItems.SETTINGS, "Impostazioni"],
  [MenuItems.BLACK_LIST, "Lista Nera"],
  [MenuItems.VERIFY_BINS, "Cestini Da Verificare"],
]);

export const MenuItemsHrefMap = new Map<MenuItems, string>([
  [MenuItems.DASHBOARD, "/dashboard"],
  [MenuItems.DATABASE, "/database"],
  [MenuItems.TARGET_LIST, "/target-list"],
  [MenuItems.PROJECTS, "/projects"],
  [MenuItems.USERS, "/users"],
  [MenuItems.PIPELINE, "/pipeline"],
  [MenuItems.CALENDAR, "/calendar"],
  [MenuItems.REPORT, "/report"],
  [MenuItems.SETTINGS, "/settings"],
  [MenuItems.PARTICULARITIES, "/particularities"],
  [MenuItems.BLACK_LIST, "/black-list"],
  [MenuItems.VERIFY_BINS, "/verify-bins"],
]);

export const PathMenuItemMap = new Map<string, MenuItems>([
  ["dashboard", MenuItems.DASHBOARD],
  ["database", MenuItems.DATABASE],
  ["liste target", MenuItems.TARGET_LIST],
  ["progetti", MenuItems.PROJECTS],
  ["utenti", MenuItems.USERS],
  ["pipeline", MenuItems.PIPELINE],
  ["calendario", MenuItems.CALENDAR],
  ["report", MenuItems.REPORT],
  ["impostazioni", MenuItems.SETTINGS],
  ["particolarità", MenuItems.PARTICULARITIES],
  ["lista nera", MenuItems.BLACK_LIST],
  ["cestino da verificare", MenuItems.VERIFY_BINS],
]);

export function MenuItemsIconsMap(
  menuItem: MenuItems,
  color: string,
  size: number
): ReactElement {
  const map = new Map<MenuItems, ReactElement>([
    [MenuItems.DASHBOARD, <HomeIcon color={color} size={size} />],
    [MenuItems.DATABASE, <DatabaseIcon color={color} size={size} />],
    [MenuItems.TARGET_LIST, <ListIcon color={color} size={size} />],
    [MenuItems.PROJECTS, <RocketIcon color={color} size={size} />],
    [MenuItems.USERS, <UsersIcon color={color} size={size} />],
    [MenuItems.PIPELINE, <TrelloIcon color={color} size={size} />],
    [MenuItems.CALENDAR, <CalendarIcon color={color} size={size} />],
    [MenuItems.REPORT, <ActivityIcon color={color} size={size} />],
    [MenuItems.SETTINGS, <SettingsIcon color={color} size={size} />],
    [MenuItems.PARTICULARITIES, <MaximizeIcon color={color} size={size} />],
    [MenuItems.BLACK_LIST, <UserXIcon color={color} size={size} />],
    [MenuItems.VERIFY_BINS, <UserCheckIcon color={color} size={size} />],
  ]);

  return map.get(menuItem)!;
}
