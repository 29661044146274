import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { setTabValue } from "../slice";
import { Filters } from "./filters";
import { CompaniesHorizontalFilters } from "./filters/horizontalFilters";
import { CompaniesList } from "./list";
import {
  countCompanies,
  findAllCompanies,
  setFindAllCompaniesFiltersPage
} from "./slice";

export function Companies() {
  const databaseState = useAppSelector((state) => state.database);
  const companiesState = useAppSelector((state) => state.companies);
  const peopleState = useAppSelector((state) => state.people);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);

  useEffect(() => {
    dispatch(findAllCompanies({ ...companiesState.filters, targetList: [] }));
    setIsFirstLoadCompanies(false);
  }, []);

  useEffect(() => {
    if (companiesState.findAllStatus === 'successfully') {
      dispatch(countCompanies())
    }
  }, [companiesState.findAllStatus])

  useEffect(() => {
    if (!isFirstLoadCompanies) {
      dispatch(setFindAllCompaniesFiltersPage(0));
      dispatch(findAllCompanies({ ...companiesState.filters, page: 0 }));
    }
  }, [
    companiesState.filters.order,
    companiesState.filters.itemsPerPage,
    companiesState.filters.sort,
    companiesState.filters.name,
    companiesState.filters.atecos,
    companiesState.filters.category,
    companiesState.filters.filter,
    companiesState.filters.active,
    companiesState.filters.city,
    companiesState.filters.countryRegion,
    companiesState.filters.department,
    companiesState.filters.employees,
    companiesState.filters.privacy,
    companiesState.filters.revenue,
    companiesState.filters.role,
    companiesState.filters.sector,
    companiesState.filters.hasSmartphone,
    companiesState.filters.state,
    companiesState.filters.zipCode,
    companiesState.filters.targetList,
    companiesState.filters.project,
    companiesState.filters.outcome,
    companiesState.filters.particularity,
    companiesState.filters.smartphone,
    companiesState.filters.creationContactDate,
    companiesState.filters.lastContactDate,
    companiesState.filters.outcomeInfo
  ]);

  useEffect(() => {
    if (!isFirstLoadCompanies)
      dispatch(findAllCompanies(companiesState.filters));
  }, [companiesState.filters.page]);

  useEffect(() => {
    if (
      companiesState.importCSVStatus === "failed" ||
      companiesState.importCSVStatus === "successfully"
    ) {
      navigate("/database");
      dispatch(setTabValue(1));
    }
  }, [companiesState.importCSVStatus]);

  return (
    <div className="max-h-full flex" style={{ height: 'calc(100% - 63.8px)' }}>
      <div className="flex" style={{ width: 'calc(100%)' }}>
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters isInTargetList={false} />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {companiesState.findAllStatus === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full max-h-full">
              <CompaniesHorizontalFilters />
              <CompaniesList />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
