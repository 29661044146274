import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { DownloadIcon } from "../../../../../ui/icons/download";
import { EditIcon } from "../../../../../ui/icons/edit";
import { FiltersLinesIcon } from "../../../../../ui/icons/filtersLines";
import { MoreHorizontalIcon } from "../../../../../ui/icons/moreHorizontal";
import { SortIcon } from "../../../../../ui/icons/sort";
import { TrashIcon } from "../../../../../ui/icons/trash";
import { UserPlusIcon } from "../../../../../ui/icons/userPlus";
import Button from "../../../../../ui/molecules/button";
import Checkbox from "../../../../../ui/molecules/checkbox";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../../../../ui/organisms/dropdown";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { Pagination } from "../../../../../ui/organisms/pagination";
import { EditCompany } from "../../../../database/companies/editCompany";
import {
  bulkCSVCompanies,
  findEmployees,
  getCSVCompanies,
  setEmployeeToDelete,
  setFindAllCompaniesFiltersItemsPerPage,
  setFindAllCompaniesFiltersOrder,
  setFindAllCompaniesFiltersOutcome,
  setFindAllCompaniesFiltersPage,
  setFindAllCompaniesFiltersSort,
  setSelectedCompany,
  setSelectedCompanyNameForEmployees,
  setTabValueDetailsCompany,
} from "../../../../database/companies/slice";
import { setFiltersActive } from "../../../../database/slice";
import {
  columnOrderTargetCompaniesMapKey,
  ContactStateEnum
} from "../../../../targetList/dto";
import { findTargetListById, removeContacts, restoreContacts, setOptionsCompaniesActive, setRestoreContactsStatus } from "../../../../targetList/slice";
import { CompanyColumnMap } from "../columnMap";
import { OptionsTargetCompanies } from "../options/options";
import { deletePerson } from "../../../../database/people/slice";
import { keycloak } from "../../../../../lib/keycloak";

export function TargetCompaniesList() {
  const dispatch = useAppDispatch();
  const targetListState = useAppSelector((state) => state.targetList);
  const companiesState = useAppSelector((state) => state.companies);
  const preferencesState = useAppSelector((state) => state.preferences);
  const databaseState = useAppSelector((state) => state.database);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (
      targetListState.removeContactsStatus === "failed" ||
      targetListState.removeContactsStatus === "successfully"
    ) {
      setShowPopup(false);
    }
  }, [targetListState.removeContactsStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.restoreContactsStatus === "failed" ||
      targetListState.restoreContactsStatus === "successfully"
    ) {
      dispatch(setRestoreContactsStatus("idle"));
      // dispatch(setRestoreContactsStatus("idle"));
      if (targetListState.restoreContactsStatus === "successfully") {
        dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      }
      timeoutId = setTimeout(() => {
        dispatch(setRestoreContactsStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.restoreContactsStatus]);

  const deletedAt = (companyId: string): boolean => {
    const del =
      targetListState.findTargetListResponse?.companyContacts &&
        targetListState.findTargetListResponse?.companyContacts[
        companyId
        ] ?
        targetListState.findTargetListResponse?.companyContacts[
          companyId
        ].deletedAt : null

    return del !== null
  }

  return (
    <div className="w-full" style={{ height: 'calc(100% - 64px)' }}>
      <div className="px-4 h-16 flex items-center justify-between border-b border-b-neutral-100">
        <div className="flex items-center gap-10">
          <span className="text-heading-xs font-semibold text-neutral-800">
            Aziende
          </span>
          {
            targetListState.getTargetListCounterStatus === 'loading' ?
              <div className="flex items-center w-full justify-center">
                <Spinner />
              </div> :
              <div className="flex items-center gap-2 h-7">
                <div
                  onClick={() => {
                    if (companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.LEADTAKEN))
                      dispatch(setFindAllCompaniesFiltersOutcome(companiesState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.LEADTAKEN)))
                    else
                      dispatch(setFindAllCompaniesFiltersOutcome([...companiesState.filters.outcome, { value: ContactStateEnum.LEADTAKEN, label: 'Lead preso' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-green-400", {
                    'border-[2px] border-green-600': companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.LEADTAKEN),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-green-800 text-label-sm font-medium">
                    Lead preso
                  </span>
                  <div className="bg-green-100 flex items-center justify-center text-green-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.companies.inList.leadTaken}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.TORECALL))
                      dispatch(setFindAllCompaniesFiltersOutcome(companiesState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.TORECALL)))
                    else
                      dispatch(setFindAllCompaniesFiltersOutcome([...companiesState.filters.outcome, { value: ContactStateEnum.TORECALL, label: 'Da richiamare' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-warning-400", {
                    'border-[2px] border-warning-600': companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.TORECALL),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-warning-800 text-label-sm font-medium">
                    Da richiamare
                  </span>
                  <div className="bg-warning-100 flex items-center justify-center text-warning-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.companies.inList.toRecall}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.KO))
                      dispatch(setFindAllCompaniesFiltersOutcome(companiesState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.KO)))
                    else
                      dispatch(setFindAllCompaniesFiltersOutcome([...companiesState.filters.outcome, { value: ContactStateEnum.KO, label: 'Ko' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-red-400", {
                    'border-[2px] border-red-600': companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.KO),
                    'hidden': !window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-red-800 text-label-sm font-medium">Ko</span>
                  <div className="bg-red-100 flex items-center justify-center text-red-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.companies.targetListBin.ko}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.INCORRECT))
                      dispatch(setFindAllCompaniesFiltersOutcome(companiesState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.INCORRECT)))
                    else
                      dispatch(setFindAllCompaniesFiltersOutcome([...companiesState.filters.outcome, { value: ContactStateEnum.INCORRECT, label: 'Info errate' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-neutral-400", {
                    'border-[2px] border-neutral-600': companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.INCORRECT),
                    'hidden': !window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-neutral-800 text-label-sm font-medium">
                    Info errate
                  </span>
                  <div className="bg-neutral-100 flex items-center justify-center text-neutral-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.companies.targetListBin.incorrect}
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.DRAFT))
                      dispatch(setFindAllCompaniesFiltersOutcome(companiesState.filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.DRAFT)))
                    else
                      dispatch(setFindAllCompaniesFiltersOutcome([...companiesState.filters.outcome, { value: ContactStateEnum.DRAFT, label: 'In attesa' }]))
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-brandPrimary-400", {
                    'border-[2px] border-brandPrimary-600': companiesState.filters.outcome.map(value => value.value).includes(ContactStateEnum.DRAFT),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-brandPrimary-800 text-label-sm font-medium">
                    In attesa
                  </span>
                  <div className="bg-brandPrimary-100 flex items-center justify-center text-brandPrimary-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                    {targetListState.getTargetListCounterResponse?.companies.inList.draft}
                  </div>
                </div>
              </div>
          }
        </div>
        <div className="flex items-center gap-3">
          {/* {
            !keycloak.hasRealmRole('customer') &&
            <Button
              size={"sm"}
              iconPosition={"left"}
              variant={"outline"}
              color={"blue"}
              onClick={() => {
                dispatch(bulkCSVCompanies({
                  name: companiesState.filters.name,
                  atecos: companiesState.filters.atecos,
                  sector: companiesState.filters.sector,
                  category: companiesState.filters.category,
                  department: companiesState.filters.department,
                  role: companiesState.filters.role,
                  zipCode: companiesState.filters.zipCode,
                  countryRegion: companiesState.filters.countryRegion,
                  hasSmartphone: companiesState.filters.hasSmartphone,
                  city: companiesState.filters.city,
                  targetList: companiesState.filters.targetList,
                  project: companiesState.filters.project,
                  outcome: companiesState.filters.outcome,
                  particularity: companiesState.filters.particularity,
                  smartphone: companiesState.filters.smartphone,
                  lastContactDate: companiesState.filters.lastContactDate,
                  creationContactDate: companiesState.filters.creationContactDate,
                  outcomeInfo: companiesState.filters.outcomeInfo
                }))
              }}
              label="Esporta tutti"
              icon={<DownloadIcon color={""} size={0} />}
            />
          } */}
          {selectedIds.length > 0 && (
            <>
              {window.location.pathname.includes('target-list-bins') &&
                !keycloak.hasRealmRole('customer') &&
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  icon={<UserPlusIcon color={""} size={0} />}
                  onClick={() => dispatch(restoreContacts({ targetListId: targetListState.findTargetListResponse?.id!, type: 'companies', contactIds: selectedIds }))}
                />}
              {
                !keycloak.hasRealmRole('customer') &&
                <Button
                  size={"sm"}
                  label="Esporta selezionati"
                  iconPosition={"left"}
                  variant={"outline"}
                  color={"blue"}
                  icon={<DownloadIcon color={""} size={0} />}
                  onClick={() => dispatch(getCSVCompanies(selectedIds))}
                />}
              {
                !keycloak.hasRealmRole('customer') &&
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  icon={<TrashIcon color={""} size={0} />}
                  onClick={() => setShowPopup(true)}
                />}
            </>
          )}
          <Button
            size={"sm"}
            iconPosition={"only"}
            variant={targetListState.optionsCompaniesActive ? "outline" : "ghost"}
            color={targetListState.optionsCompaniesActive ? "blue" : "gray"}
            icon={<MoreHorizontalIcon color={""} size={0} />}
            onClick={() =>
              dispatch(setOptionsCompaniesActive(!targetListState.optionsCompaniesActive))
            }
          />
          <Button
            size={"sm"}
            iconPosition={"off"}
            label="Filtri / Espandi"
            variant={databaseState.filtersActive ? "outline" : "ghost"}
            color={databaseState.filtersActive ? "blue" : "gray"}
            icon={<FiltersLinesIcon color={""} size={0} />}
            onClick={() =>
              dispatch(setFiltersActive(!databaseState.filtersActive))
            }
          />
        </div>
      </div>
      {companiesState.findAllResponse?.total === 0 ? (
        <div style={{ height: 'calc(100% - 64px - 48px)' }}>
          <NotFound />
        </div>
      ) : (
        <div className="overflow-auto" style={{ height: 'calc(100% - 64px - 48px)' }}>
          <table className="w-full">
            <thead className="bg-neutral-800 h-[48px] sticky top-0 z-10 font-semibold text-neutral-500 uppercase text-text-xs">
              <tr className="bg-neutral-50">
                <th className="text-left w-10">
                  <Checkbox
                    shape={"square"}
                    checked={
                      selectedIds.length ===
                      companiesState.findAllResponse?.data.length
                    }
                    onCheck={function (value: boolean): void {
                      if (
                        selectedIds.length ===
                        companiesState.findAllResponse?.data.length
                      ) {
                        setSelectedIds([]);
                      } else {
                        setSelectedIds(
                          companiesState.findAllResponse?.data?.map(
                            (company) => company.id
                          )!
                        );
                      }
                    }}
                  />
                </th>
                {preferencesState.findAllResponse?.data[0].targetListCompanyPreferences
                  .filter((column) => column.visible)
                  .map((column) => (
                    <th key={column.column} className="text-left">
                      <div className="flex gap-[4px]">
                        {column.column}
                        {
                          columnOrderTargetCompaniesMapKey.get(column.column) &&
                          <div
                            onClick={() => { dispatch(setFindAllCompaniesFiltersSort(columnOrderTargetCompaniesMapKey.get(column.column))); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                            className="cursor-pointer"
                          >
                            <SortIcon />
                          </div>}
                      </div>
                    </th>
                  ))}
                <th className="text-left">
                  <div className="flex gap-[4px]">
                  </div>
                </th>
                <th className="text-left">
                  <div className="flex gap-[4px] w-[20px]" />
                </th>
              </tr>
            </thead>
            <tbody>
              {companiesState.findAllResponse?.data.map((company) => (
                <tr
                  className={clsx(
                    "h-[56px] border-b-[1px] border-b-neutral-100",
                    {
                      "bg-brandPrimary-100": selectedIds.includes(company.id),
                      "bg-white": !selectedIds.includes(company.id),
                    }
                  )}
                  key={company.id}
                >
                  <td className="text-text-sm font-semibold text-neutral-600">
                    <Checkbox
                      shape={"square"}
                      checked={selectedIds.includes(company.id)}
                      onCheck={function (value: boolean): void {
                        if (selectedIds.includes(company.id)) {
                          setSelectedIds([
                            ...selectedIds.filter((id) => id !== company.id),
                          ]);
                        } else {
                          setSelectedIds([...selectedIds, company.id]);
                        }
                      }}
                    />
                  </td>
                  {preferencesState.findAllResponse?.data[0].targetListCompanyPreferences
                    .filter((column) => column.visible)
                    .map((column, index) => (
                      <CompanyColumnMap
                        key={company.id + "-" + column.column}
                        company={company}
                        column={column.column}
                        contactInfo={targetListState.findTargetListResponse
                          ?.companyContacts ? targetListState.findTargetListResponse
                            ?.companyContacts[company.id] : {
                          contactId: '-',
                          contactState: ContactStateEnum.DRAFT,
                          lastContactDate: null,
                          notes: null,
                          stateInfo: null,
                          callAttempts: null, createdAt: null, deletedAt: null, id: null
                        }}
                      />
                    ))}
                  <td className="">
                    <Button
                      size={"sm"}
                      iconPosition={"off"}
                      disabled={!company.employeesId}
                      variant={"outline"}
                      color={"blue"}
                      label="Mostra dipendenti"
                      onClick={() => {
                        dispatch(setSelectedCompany(company.id))
                        dispatch(findEmployees(company.employeesId));
                        dispatch(
                          setSelectedCompanyNameForEmployees(company.name)
                        );
                        dispatch(setTabValueDetailsCompany(1))
                      }}
                    />
                  </td>
                  <td className="w-[20px] cursor-pointer">
                    <Dropdown
                      items={[
                        {
                          label: "Modifica",
                          icon: <EditIcon color={""} size={0} />,
                          onClick: () => {
                            dispatch(setTabValueDetailsCompany(0))
                            dispatch(setSelectedCompany(company.id))
                            dispatch(findEmployees(company.employeesId));
                            dispatch(
                              setSelectedCompanyNameForEmployees(company.name)
                            );
                          },
                        },
                        (!keycloak.hasRealmRole('customer') &&
                          deletedAt(company.id) === true ?
                          {
                            label: "Ripristina",
                            icon: <UserPlusIcon color={""} size={0} />,
                            onClick: () => {
                              dispatch(restoreContacts({ targetListId: targetListState.findTargetListResponse?.id!, type: 'companies', contactIds: [company.id] }))
                            },
                          } : null),
                        (keycloak.hasRealmRole('customer') ? null : {
                          label: "Cancella",
                          icon: <TrashIcon color={""} size={0} />,
                          onClick: () => {
                            setSelectedIds([company.id]);
                            setShowPopup(true);
                          },
                        }),
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        itemsPerPage={companiesState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setFindAllCompaniesFiltersItemsPerPage(value))}
        found={companiesState.findAllResponse?.total || 0}
        key={"pagination-companies-bottom"}
        pages={companiesState.findAllResponse?.totalPage || 0}
        currentPage={companiesState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllCompaniesFiltersPage(page));
        }}
      />
      <div
        style={{ height: "calc(100% - 144px)" }}
        className={clsx(
          "fixed right-0 bottom-0 z-50 flex-shrink-0 w-[290px] transition-all",
          {
            visible: targetListState.optionsCompaniesActive,
            hidden: !targetListState.optionsCompaniesActive,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <OptionsTargetCompanies />
        </div>
      </div>
      <div
        style={{ height: "calc(100% - 144px)" }}
        className={clsx(
          "fixed right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all",
          {
            hidden: companiesState.selectedCompany === undefined,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <EditCompany isInTargetList />
        </div>
      </div>
      <ConfirmModal
        title={"Elimina persona"}
        label={"Sei sicuro di voler eliminare questa persona?"}
        confirmAction={
          () => dispatch(deletePerson([companiesState.employeeToDelete!]))
        }
        onClose={() => dispatch(setEmployeeToDelete(undefined))}
        visible={companiesState.employeeToDelete !== undefined}
      />
      <ConfirmModal
        loading={targetListState.removeContactsStatus === "loading"}
        title={"Rimuovi aziende dalla lista"}
        label={
          "Sei sicuro di voler eliminare le aziende selezionate dalla lista?"
        }
        confirmAction={function (): void {
          dispatch(
            removeContacts({
              targetListId: targetListState.findTargetListResponse?.id!,
              type: "companies",
              contactsId: selectedIds,
            })
          );
        }}
        onClose={function (): void {
          setSelectedIds([]);
          setShowPopup(false);
        }}
        visible={showPopup}
      />
    </div>
  );
}
