import qs from "qs";
import { cdAxios } from "../../../lib/axios";
import { keycloak } from "../../../lib/keycloak";
import { BulkCSVFilters, ImportCSVResponseDTO } from "../dto";
import {
  AssociatePersonToCompany,
  EditPersonRequest,
  FindAllPeopleFilters,
  FindAllPeopleResponse,
  NewPersonRequest,
  PersonDTO,
} from "./dto";
import { PeopleService } from "./service";

export class PeopleServiceImpl implements PeopleService {
  public findAllPeople(
    filters: FindAllPeopleFilters
  ): Promise<FindAllPeopleResponse> {
    return cdAxios
      .get("/api/person", {
        params: {
          itemsPerPage: filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          targetListBin: window.location.pathname.includes('target-list-bins') === true ? true : window.location.pathname.includes('target') ? false : undefined,
          blackListBin: window.location.pathname.includes('black-list'),
          toVerifyBin: window.location.pathname.includes('verify-bins'),
          order: filters.order,
          privacy: filters.privacy?.value,
          revenue: filters.revenue?.value,
          employeesNumber: filters.employees?.value,
          personName: filters.personName,
          personEmail: filters.personEmail,
          hasCompany: filters.hasCompany,
          active: filters.active,
          hasSmartphone: filters.hasSmartphone?.value,
          smartphone: filters.smartphone?.value,
          creationContactDate: filters.creationContactDate?.value,
          lastContactDate: filters.lastContactDate?.value,
          outcomeInfo: filters.outcomeInfo.map((value) => value.value),
          outcome: filters.outcome.map((outcome) => outcome.value + (window.location.pathname.includes('target') ? ':' + filters.targetList[0].value : '')),
          atecoCode: filters.atecos.map((ateco) => "~:" + ateco.value),
          category: filters.category.map((category) => category.value),
          particularity: filters.particularity.map((particolarity) => particolarity.value),
          department: filters.department.map((department) => department.value),
          name: filters.name.map((name) => name.label),
          role: filters.role.map((role) => role.value),
          sector: filters.sector.map((sector) => sector.value),
          state: filters.state?.value,
          city: filters.city.map((city) => city.value),
          countryRegion: filters.countryRegion.map(
            (countryRegion) => countryRegion.value
          ),
          zipCode: filters.zipCode.map((zipCode) => zipCode.value),
          targetList: filters.targetList.map((target) => "=:" + target.value),
          project: filters.project.map((project) => "=:" + project.value),
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public deletePerson(id: string[]): Promise<void> {
    return cdAxios
      .delete("/api/person", {
        data: id,
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public personCount(): Promise<{ toActivate: number }> {
    return cdAxios
      .get("/api/person/count", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findPersonById(id: string): Promise<PersonDTO> {
    return cdAxios
      .get("/api/person/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public updatePerson(id: string, data: EditPersonRequest): Promise<void> {
    return cdAxios
      .put("/api/person/" + id, data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createPerson(data: NewPersonRequest): Promise<{
    id: string,
    alreadyExists: boolean
  }> {
    return cdAxios
      .post("/api/person", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public importContact(data: FormData): Promise<ImportCSVResponseDTO> {
    return cdAxios
      .post("/api/person/csv", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public associateCompany(
    data: AssociatePersonToCompany,
    personId: string
  ): Promise<void> {
    return cdAxios
      .post("/api/person/" + personId + "/company", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public getCSV(ids: string[]): Promise<string> {
    return cdAxios
      .get("/api/person/csv", {
        params: {
          ids: ids,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/octet-stream",
        },
      })
      .then((response) => response.data);
  }

  public bulkCSV(
    filters: BulkCSVFilters
  ): Promise<string> {
    return cdAxios
      .get("/api/person/bulk-csv", {
        params: {
          filter: filters.filter,
          active: filters.active,
          privacy: filters.privacy?.value,
          targetListBin: window.location.pathname.includes('target-list-bins') === true ? true : window.location.pathname.includes('target') ? false : undefined,
          blackListBin: window.location.pathname.includes('black-list'),
          toVerifyBin: window.location.pathname.includes('verify-bins'),
          revenue: filters.revenue?.value,
          employeesNumber: filters.employees?.value,
          hasSmartphone: filters.hasSmartphone?.value,
          atecoCode: filters.atecos.map((ateco) => "~:" + ateco.value),
          category: filters.category.map((category) => category.value),
          department: filters.department.map((department) => department.value),
          targetList: filters.targetList.map((target) => "=:" + target.value),
          outcome: filters.outcome.map((outcome) => outcome.value + (window.location.pathname.includes('target') ? ':' + filters.targetList[0].value : '')),
          project: filters.project.map((project) => "=:" + project.value),
          name: filters.name.map((name) => name.label),
          role: filters.role.map((role) => role.value),
          sector: filters.sector.map((sector) => sector.value),
          state: filters.state?.value,
          city: filters.city.map((city) => "~:" + city.value),
          countryRegion: filters.countryRegion.map(
            (countryRegion) => countryRegion.value
          ),
          zipCode: filters.zipCode.map((zipCode) => zipCode.value),
          particularity: filters.particularity.map((particularity) => particularity.value),
          smartphone: filters.smartphone?.value,
          creationContactDate: filters.creationContactDate?.value,
          lastContactDate: filters.lastContactDate?.value,
          outcomeInfo: filters.outcomeInfo.map((outcomeInfo) => outcomeInfo.value)
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
