import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { colors } from "../../../ui/colors"
import { ChevronIcon } from "../../../ui/icons/chevron"
import Button from "../../../ui/molecules/button"
import { Spinner } from "../../../ui/molecules/spinner"
import { EditPersonForm } from "../people/editPerson/form"
import { editPerson, findPerson, setEditPersonCompanyId, setEditPersonDepartment, setEditPersonEmail, setEditPersonLinkedinProfile, setEditPersonName, setEditPersonPhone, setEditPersonPrivacy, setEditPersonRole, setEditPersonSurname, setEditPersonToVerifyFields, setValidateUpdatePersonEmail, setValidateUpdatePersonName, setValidateUpdatePersonPhone, setValidateUpdatePersonStatus, setValidateUpdatePersonSurname, validateUpdatePerson } from "../people/slice"
import { getUsersByUserIds, findUsersAvatarsList, setFindUsersByUserIdStatus } from "../../users/slice"
import { findCompany, setFindCompanyResponse } from "./slice"

interface Props {
    goToList: Function
}

export function EditEmployee(props: Props) {
    const peopleState = useAppSelector(state => state.people)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (peopleState.selectedPerson !== undefined) {
            dispatch(findPerson(peopleState.selectedPerson));
        }
    }, [peopleState.selectedPerson]);

    useEffect(() => {
        if (peopleState.findPersonStatus === "successfully") {
            dispatch(setEditPersonName(peopleState.findPersonResponse?.name));
            dispatch(setEditPersonSurname(peopleState.findPersonResponse?.surname));
            dispatch(setEditPersonRole(peopleState.findPersonResponse?.role));
            dispatch(setEditPersonToVerifyFields(peopleState.findPersonResponse?.toVerifyFields))
            dispatch(setEditPersonDepartment(peopleState.findPersonResponse?.department));
            dispatch(setEditPersonPrivacy(peopleState.findPersonResponse?.privacy));
            dispatch(setEditPersonPhone(peopleState.findPersonResponse?.phone));
            dispatch(setEditPersonEmail(peopleState.findPersonResponse?.email));
            dispatch(setEditPersonLinkedinProfile(peopleState.findPersonResponse?.linkedinProfile));
            dispatch(setEditPersonCompanyId(peopleState.findPersonResponse?.companyId));

            dispatch(setValidateUpdatePersonStatus('idle'))
            dispatch(setValidateUpdatePersonEmail(false))
            dispatch(setValidateUpdatePersonName(false))
            dispatch(setValidateUpdatePersonPhone(false))
            dispatch(setValidateUpdatePersonSurname(false))
        }
    }, [peopleState.findPersonStatus]);

    useEffect(() => {
        if (peopleState.errors.validateUpdate.status === 'successfully') {
            dispatch(editPerson({ id: peopleState.selectedPerson!, data: peopleState.editPersonRequest }))
            dispatch(setValidateUpdatePersonStatus('idle'))
        }
    }, [peopleState.errors.validateUpdate.status])

    useEffect(() => {
        if (peopleState.editPersonStatus === 'successfully') {
            props.goToList()
        }
    }, [peopleState.editPersonStatus])

    return (
        <div className="px-4">
            {
                peopleState.findPersonStatus === 'loading' ?
                    <Spinner /> :
                    <>
                        <div className="border-y border-neutral-200 py-3 flex items-center justify-between">
                            <div className="flex items-center gap-1 cursor-pointer" onClick={() => props.goToList()}>
                                <ChevronIcon color={colors.neutral[400]} size={24} direction="l" />
                                <span className="text-text-sm font-semibold text-neutral-700">Torna alla lista</span>
                            </div>
                            <Button
                                size={"sm"}
                                iconPosition={"left"}
                                variant={"outline"}
                                color={"blue"}
                                label="Modifica contatto"
                                onClick={() => dispatch(validateUpdatePerson(peopleState.editPersonRequest))}
                            />
                        </div>
                        <EditPersonForm />
                    </>
            }
        </div>
    )
}