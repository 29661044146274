import clsx from "clsx";
import { useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { colors } from "../../../ui/colors";
import { PlusIcon } from "../../../ui/icons/plus";
import { UserPlusIcon } from "../../../ui/icons/userPlus";
import Avatar from "../../../ui/molecules/avatar";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { SendEmailModal } from "../../projects/mails/sendEmailModal";
import { SentMailsList } from "../../projects/mails/sentMailsList";
import { findEmailsHistory, setEmailsHistoryFilters } from "../../projects/mails/slice";
import { findUsersAvatarsList, getUsersByUserIds, setFindUsersByUserIdStatus } from "../../users/slice";
import {
  editCompany,
  findCompany,
  setFindCompanyResponse,
  setSelectedCompany
} from "../companies/slice";
import { PersonCompany } from "./editPerson/company";
import useCreateBorder from "./editPerson/createBorder";
import { EditPersonForm } from "./editPerson/form";
import { PersonTargetListInfo } from "./editPerson/targetListInfo";
import { EditPersonNotes } from "./notes";
import {
  editPerson,
  findPerson,
  setEditPersonCompanyId,
  setEditPersonDepartment,
  setEditPersonEmail,
  setEditPersonLinkedinProfile,
  setEditPersonName,
  setEditPersonPhone,
  setEditPersonPrivacy,
  setEditPersonRole,
  setEditPersonSurname,
  setEditPersonToVerifyFields,
  setPersonToAddCompany,
  setSelectedPerson,
  setTabValueDetailsPerson,
  setValidateUpdatePersonEmail,
  setValidateUpdatePersonName,
  setValidateUpdatePersonPhone,
  setValidateUpdatePersonStatus,
  setValidateUpdatePersonSurname,
  validateUpdatePerson
} from "./slice";

interface Props {
  isInTargetList: boolean
}

export function EditPerson(props: Props) {
  const { isInTargetList } = props
  const peopleState = useAppSelector((state) => state.people);
  const usersState = useAppSelector((state) => state.users);
  const companiesState = useAppSelector((state) => state.companies);
  const targetListsState = useAppSelector((state) => state.targetList);
  const [direction, setDirection] = useState<"left" | "right">("left");

  const dispatch = useAppDispatch();
  const borderStyle = useCreateBorder(isInTargetList)

  useEffect(() => {
    if (peopleState.selectedPerson !== undefined) {
      dispatch(setSelectedCompany(undefined))
      dispatch(findPerson(peopleState.selectedPerson));
    }
  }, [peopleState.selectedPerson]);

  useEffect(() => {
    if (peopleState.errors.validateUpdate.status === 'successfully') {
      dispatch(setValidateUpdatePersonStatus('idle'))
      dispatch(
        editPerson({
          data: peopleState.editPersonRequest,
          id: peopleState.findPersonResponse?.id!,
        })
      );
    }
  }, [peopleState.errors.validateUpdate.status])

  const notes = targetListsState.findTargetListResponse?.personContacts &&
    targetListsState.findTargetListResponse?.personContacts[
    peopleState.findPersonResponse?.id!
    ] ?
    targetListsState.findTargetListResponse?.personContacts[
      peopleState.findPersonResponse?.id!
    ].notes : null

  const mailsState = useAppSelector(state => state.mails)

  useEffect(() => {
    if (peopleState.findPersonStatus === "successfully") {
      dispatch(setEditPersonName(peopleState.findPersonResponse?.name));
      dispatch(setEditPersonSurname(peopleState.findPersonResponse?.surname));
      dispatch(setEditPersonRole(peopleState.findPersonResponse?.role));
      dispatch(setEditPersonToVerifyFields(peopleState.findPersonResponse?.toVerifyFields))
      dispatch(setEditPersonDepartment(peopleState.findPersonResponse?.department));
      dispatch(setEditPersonPrivacy(peopleState.findPersonResponse?.privacy));
      dispatch(setEditPersonPhone(peopleState.findPersonResponse?.phone));
      dispatch(setEditPersonEmail(peopleState.findPersonResponse?.email));
      dispatch(setEditPersonLinkedinProfile(peopleState.findPersonResponse?.linkedinProfile));
      dispatch(setEditPersonCompanyId(peopleState.findPersonResponse?.companyId));

      dispatch(setValidateUpdatePersonStatus('idle'))
      dispatch(setValidateUpdatePersonEmail(false))
      dispatch(setValidateUpdatePersonName(false))
      dispatch(setValidateUpdatePersonPhone(false))
      dispatch(setValidateUpdatePersonSurname(false))

      if (peopleState.findPersonResponse?.companyId)
        dispatch(findCompany(peopleState.findPersonResponse?.companyId!));
      else {
        dispatch(setFindCompanyResponse(undefined))
      }
      if (isInTargetList) {
        if (peopleState.findPersonResponse?.email && targetListsState.editTargetListTabValue === 1 && peopleState.selectedPerson)
          dispatch(setEmailsHistoryFilters({ ...mailsState.emailsHistoryFilters, toEmail: peopleState.findPersonResponse?.email }))
        dispatch(getUsersByUserIds(notes?.map(note => note.authorUserId) ?? []))
      }
    }
  }, [peopleState.findPersonStatus]);

  useEffect(() => {
    if (mailsState.emailsHistoryFilters.toEmail.length > 0 && peopleState.selectedPerson)
      dispatch(findEmailsHistory(mailsState.emailsHistoryFilters))
  }, [mailsState.emailsHistoryFilters.toEmail])

  useEffect(() => {
    if (usersState.findUsersByUserIdStatus === 'successfully') {
      dispatch(findUsersAvatarsList(usersState.findUsersByUserIdResponse.map(user => ({ userId: user.userId, objectId: user.avatarObjectId }))))
      dispatch(setFindUsersByUserIdStatus('idle'))
    }
  }, [usersState.findUsersByUserIdStatus])

  return (
    <div className="h-full overflow-auto">
      {peopleState.findPersonStatus === "loading" ||
        peopleState.editPersonStatus === "loading" ||
        companiesState.editCompanyStatus === "loading" ? (
        <Spinner />
      ) : (
        <div className={borderStyle}>
          <div className="px-6 pt-4 flex items-center justify-between">
            <Avatar
              type="info"
              imageUrl=""
              firstRow={
                (peopleState.findPersonResponse?.name ?? "-") +
                " " +
                (peopleState.findPersonResponse?.surname ?? "-")
              }
              secondRow={peopleState.findPersonResponse?.role}
              size="md"
              altTextInitials={
                (peopleState.findPersonResponse?.name[0] && peopleState.findPersonResponse?.name[0]?.toUpperCase()) ??
                "" +
                (peopleState.findPersonResponse?.surname[0] && peopleState.findPersonResponse?.surname[0]?.toUpperCase()) ??
                ""
              }
            />
            <div className="flex items-start gap-4">
              <div
                className="cursor-pointer rotate-45"
                onClick={() => dispatch(setSelectedPerson(undefined))}
              >
                <PlusIcon color={colors.neutral[500]} size={20} />
              </div>
            </div>
          </div>
          <PersonTargetListInfo visible={isInTargetList} />
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => dispatch(setTabValueDetailsPerson(e))}
            value={peopleState.tabValueDetailsPerson}
            items={[
              {
                icon: <></>,
                label: "Anagrafica",
              },
              {
                icon: <></>,
                label: "Azienda",
              },
              {
                icon: <></>,
                label: "Note",
                hide: !isInTargetList
              },
              {
                icon: <></>,
                label: "Cronologia email",
                hide: !window.location.pathname.includes('target') || !peopleState.findPersonResponse?.email
              }
            ]}
          />
          <div>
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <EditPersonForm key={0} />,
                <PersonCompany key={1} />,
                <EditPersonNotes key={2} />,
                <SentMailsList key={3} />
              ]}
              value={peopleState.tabValueDetailsPerson}
              setValue={(e) => dispatch(setTabValueDetailsPerson(e))}
            />
          </div>
          <div className={clsx("p-6 justify-end items-center gap-2", { 'hidden': peopleState.tabValueDetailsPerson === 2 || peopleState.tabValueDetailsPerson === 3, 'flex': peopleState.tabValueDetailsPerson !== 2 && peopleState.tabValueDetailsPerson !== 3 })}>
            <Button
              size={"sm"}
              iconPosition={"off"}
              variant={"outline"}
              color={"blue"}
              label="Annulla"
              onClick={() => dispatch(setSelectedPerson(undefined))}
            />
            {
              peopleState.tabValueDetailsPerson === 0 || (peopleState.tabValueDetailsPerson === 1 && peopleState.findPersonResponse?.companyId) ?
                <Button
                  size={"sm"}
                  iconPosition={"off"}
                  variant={"solid"}
                  disabled={keycloak.hasRealmRole('customer')}
                  color={"blue"}
                  label="Salva"
                  onClick={() => {
                    if (peopleState.findPersonResponse?.companyId)
                      dispatch(
                        editCompany({
                          data: companiesState.editCompanyRequest,
                          id: peopleState.findPersonResponse?.companyId,
                        })
                      );
                    dispatch(validateUpdatePerson(peopleState.editPersonRequest))
                  }}
                /> :
                <Button
                  size={"sm"}
                  disabled={keycloak.hasRealmRole('customer')}
                  iconPosition={"left"}
                  icon={<UserPlusIcon color={""} size={0} />}
                  variant={"solid"}
                  color={"blue"}
                  label="Associa azienda"
                  onClick={() => dispatch(setPersonToAddCompany(peopleState.findPersonResponse))}
                />
            }
          </div>
        </div>
      )}
      <SendEmailModal />
    </div>
  );
}
