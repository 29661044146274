import axios from "axios";
import { cdAxios } from "../../../lib/axios";
import { keycloak } from "../../../lib/keycloak";
import { CustomMailCreateDTO, CustomMailDTO, CustomMailUpdateDTO, EmailsHistoryFilters, EmailsMessagesDTO, FindAllCustomMailsResponseDTO, findEmailByIdResponseDTO, Mail, MailFilters, SaveResponseDTO } from "./dto";
import { MailService } from "./service";

export class MailServiceImpl implements MailService {
    findEmailById(id: string): Promise<findEmailByIdResponseDTO> {
        return cdAxios.get('/api/notifications/find/'+ id, {
                    headers: {
                        Authorization: "Bearer " + keycloak.token,
                        Accept: "*/*",
                        ContentType: "application/json",
                        "Access-Control-Allow-Headers":
                            "accept, authorization, content-type, x-requested-with",
                        "Access-Control-Allow-Origin": "http://localhost:3000",
                        "Access-Control-Allow-Methods": "GET",
                    },
                }).then((response) => response.data);
    }
    findAllCustomMails(filters: MailFilters): Promise<FindAllCustomMailsResponseDTO> {
        return cdAxios.get('/api/customMail', {
            params: {
                page: filters.page,
                pageSize: filters.pageSize,
                //sort: filters.sort,
                sortBy: filters.sortBy,
                projectId: filters.projectId,
                //mailSubject : filters.mailSubject
            },
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "*/*",
                ContentType: "application/json",
                "Access-Control-Allow-Headers":
                    "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
        }).then((response) => response.data);
    }
    findMailByID(id: string): Promise<CustomMailDTO> {
        return cdAxios.get('/api/customMail/' + id, {
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "*/*",
                ContentType: "application/json",
                "Access-Control-Allow-Headers":
                    "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
        }).then((response) => response.data)
    }
    saveMail(request: CustomMailCreateDTO): Promise<SaveResponseDTO> {
        return cdAxios.post('/api/customMail', request, {
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "*/*",
                ContentType: "application/json",
                "Access-Control-Allow-Headers":
                    "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
        } ).then((response) => response.data)
    }
    updateMail(id: string, request: CustomMailUpdateDTO): Promise<string> {
        return cdAxios.put('/api/customMail/' + id, request, {
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "*/*",
                ContentType: "application/json",
                "Access-Control-Allow-Headers":
                    "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
        })
    }
    removeMail(id: string): Promise<string> {
        return cdAxios.delete('/api/customMail/' + id, {
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "*/*",
                ContentType: "application/json",
                "Access-Control-Allow-Headers":
                    "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
        })
    }
    sendCustomMail(customMailId: string, request: Mail): Promise<any> {
        return cdAxios.post("/api/notifications/sendCustomEmail/" + customMailId, request, {
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "*/*",
                ContentType: "application/json",
                "Access-Control-Allow-Headers":
                    "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
        }
        )
    }

   findEmailsHistory(filters : EmailsHistoryFilters): Promise<{messages : EmailsMessagesDTO[]}>{
    let url: string = "/api/notifications/finds"

    return axios({
        url: url,
        params :{
            toEmail : filters.toEmail ?? filters.toEmail,
            fromEmail : filters.fromEmail ?? filters.fromEmail,
            object : filters.object ?? filters.object,
            limit : filters.limit
        },
        method: "GET",
        headers: {
            Authorization: 'Bearer ' + keycloak.token,
            Accept: '*/*',
            "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "GET",
        }
    }).then(response => response.data)
   }
}