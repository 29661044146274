import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { SettingsIcon } from "../../../ui/icons/settings";
import { TrashIcon } from "../../../ui/icons/trash";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { EditTargetList } from "../../targetList/editTargetListDetails/index";
import {
  findAllTArgetLists,
  setNewTargetListAtecos,
  setNewTargetListProjectId,
  setRemoveContactsStatus,
  setSelectedTargetBins,
  setSelectedTargetList,
  setTargetListsFilterPage
} from "../../targetList/slice";
import {
  findClients,
  findCommercials,
  findTeamLeaders,
  findUsersAvatarsList,
} from "../../users/slice";
import { EditProjectPage } from "../dto";
import { EmailSettingModal } from "../mails/emailSettingModal";
import { setEmailSettingsModalOpen, setSaveMailStatus } from "../mails/slice";
import {
  editCustomerInProject,
  editProject,
  findProjectById,
  setEditCustomerInProjectStatus,
  setEditProjectDescription,
  setEditProjectEmailFrom,
  setEditProjectNameFrom,
  setEditProjectPage,
  setEditProjectStatus,
  setEditProjectTargetListIds,
  setFindProjectStatus,
} from "../slice";
import { AddTargetList } from "./addTargetList";
import { Form } from "./form";
import { TargetLists } from "./targetLists";

export function EditProject() {
  const mailsState = useAppSelector((state) => state.mails);
  const projectsState = useAppSelector((state) => state.projects);
  const targetListsState = useAppSelector((state) => state.targetList);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [tabValue, setTabValue] = useState<number>(
    window.location.pathname.includes("specifics") ? 0 : 1
  );

  useEffect(() => {
    dispatch(findCommercials());
    dispatch(findClients());
    dispatch(findTeamLeaders());
    dispatch(findProjectById(projectsState.selectedProject!));
    if (window.location.pathname.includes("specifics")) {
      setTabValue(0);
    }
    if (window.location.pathname.includes("target-lists")) {
      setTabValue(1);
      if (window.location.pathname.split("/")[4]) {
        dispatch(setEditProjectPage(EditProjectPage.EDITLIST));
      }
    }
  }, []);

  useEffect(() => {
    if (projectsState.findProjectStatus === "successfully") {
      dispatch(setFindProjectStatus("idle"));
      dispatch(
        findUsersAvatarsList(
          projectsState.findProjectResponse?.targetLists
            ?.flatMap((data) => data.commercials)
            .map((commercial) => ({
              objectId: commercial.avatarObjectId,
              userId: commercial.id,
            }))!
        )
      );
      dispatch(
        findAllTArgetLists({
          commercial: [],
          contacts: "",
          itemsPerPage: 50,
          name: "",
          order: true,
          page: 0,
          sector: [],
          sort: "name",
          category: [],
          state: [],
          teamLeader: [],
          project: [projectsState.findProjectResponse?.id!],
        })
      );
      dispatch(
        setEditProjectDescription(
          projectsState.findProjectResponse?.description
        )
      );
      dispatch(
        setEditProjectTargetListIds(
          projectsState.findProjectResponse?.targetListsIds
            ? [...projectsState.findProjectResponse?.targetListsIds]
            : null
        )
      );
      dispatch(setEditProjectEmailFrom(projectsState.findProjectResponse?.emailFrom
        ? projectsState.findProjectResponse?.emailFrom
        : null))
      dispatch(setEditProjectNameFrom(projectsState.findProjectResponse?.nameFrom
        ? projectsState.findProjectResponse?.nameFrom
        : null))
    }
  }, [projectsState.findProjectStatus]);

  useEffect(() => {
    if (projectsState.editCustomerInProjectStatus === "successfully") {
      dispatch(findProjectById(projectsState.findProjectResponse?.id!))
    }
  }, [projectsState.editCustomerInProjectStatus])

  useEffect(() => {
    if (projectsState.editProjectStatus === "successfully") {
      dispatch(findProjectById(projectsState.findProjectResponse?.id!))
    }
  }, [projectsState.editProjectStatus])

  useEffect(() => {
    if (targetListsState.selectedTargetList !== undefined) {
      navigate(
        window.location.pathname + "/" + targetListsState.selectedTargetList
      );
      dispatch(setEditProjectPage(EditProjectPage.EDITLIST));
      dispatch(setSelectedTargetList(undefined));
    }
  }, [targetListsState.selectedTargetList]);

  useEffect(() => {
    dispatch(setTargetListsFilterPage(0));
    dispatch(
      findAllTArgetLists({
        ...targetListsState.filters,
        page: 0,
        project: [projectsState.findProjectResponse?.id!],
      })
    );
  }, [
    targetListsState.filters.order,
    targetListsState.filters.itemsPerPage,
    targetListsState.filters.sort,
    targetListsState.filters.name,
    targetListsState.filters.teamLeader,
    targetListsState.filters.commercial,
    targetListsState.filters.sector,
    targetListsState.filters.state,
  ]);

  useEffect(() => {
    dispatch(
      findAllTArgetLists({
        ...targetListsState.filters,
        project: [projectsState.findProjectResponse?.id!],
      })
    );
  }, [targetListsState.filters.page]);

  useEffect(() => {
    if (targetListsState.selectedTargetBins !== undefined) {
      navigation("/target-list-bins/" + targetListsState.selectedTargetBins);
      dispatch(setSelectedTargetBins(undefined));
    }
  }, [targetListsState.selectedTargetBins]);

  useEffect(() => {
    if (projectsState.editProjectStatus === 'successfully')
      dispatch(findProjectById(projectsState.findProjectResponse!.id))
  }, [projectsState.editProjectStatus])

  const editProjectMap = new Map<EditProjectPage, ReactElement>([
    [EditProjectPage.LIST, <TargetLists />],
    [EditProjectPage.ADDLIST, <AddTargetList />],
    [EditProjectPage.EDITLIST, <EditTargetList />],
  ]);

  const tabValuePathMap = new Map<number, string>([
    [0, "/specifics"],
    [1, "/target-lists"],
  ]);

  const navigate = useNavigate();

  return (
    <Layout
      menuItem={MenuItems.PROJECTS}
      breadcrumbItems={["Progetti", "Modifica progetto"]}
      headerLabel={projectsState.findProjectResponse?.name!}
      headerBackPath={"/projects"}
      headerChildren={
        <div className="flex items-center h-[100%] w-[100%] justify-between">
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => {
              setTabValue(e);
              navigate(
                "/edit-project/" +
                window.location.pathname.split("/")[2] +
                tabValuePathMap.get(e)
              );
              if (e === 1) {
                dispatch(setEditProjectPage(EditProjectPage.LIST));
              }
            }}
            value={tabValue}
            items={[
              {
                icon: <></>,
                label: "Specifiche",
              },
              {
                icon: <></>,
                label:
                  "Liste target (" +
                  projectsState.findProjectResponse?.targetLists?.length +
                  ")",
              },
            ]}
          />
          {
            (tabValue !== 1 || !window.location.pathname.split('/')[4]) && !keycloak.hasRealmRole('customer') && projectsState.editProjectPage !== EditProjectPage.ADDLIST &&
            <div className="flex flex-row gap-2">
              <Button onClick={() => dispatch(setEmailSettingsModalOpen(true))} size={"sm"} label="Impostazioni email" iconPosition={"left"} icon={<SettingsIcon color={""} size={0} />} variant={"link"} color={"blue"} />
              <Button
                size={"sm"}
                iconPosition={"off"}
                variant={"outline"}
                color={"blue"}
                label="Aggiungi lista target"
                onClick={() => {
                  setTabValue(1)
                  dispatch(setEditProjectPage(EditProjectPage.ADDLIST));
                  dispatch(
                    setNewTargetListProjectId(projectsState.findProjectResponse?.id)
                  );
                  dispatch(setNewTargetListAtecos([{ atecoCode: null, atecoDescription: null, sector: null, category: null }]))
                }}
              />
              {
                tabValue === 0 &&
                !keycloak.hasRealmRole('customer') &&
                (projectsState.newProjectStatus === "loading" ||
                  projectsState.validateNewProject.status === "loading" ? (
                  <Spinner />
                ) : (
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    label="Modifica progetto"
                    variant={"solid"}
                    color={"blue"}
                    onClick={() => {
                      if (projectsState.editCustomerInProjectRequest.customerId !== '') {
                        dispatch(editCustomerInProject({ id: projectsState.findProjectResponse?.id!, data: projectsState.editCustomerInProjectRequest }))
                      }
                      if (projectsState.editProjectRequest.description !== "") {
                        dispatch(
                          editProject({
                            id: projectsState.findProjectResponse?.id!,
                            data: {
                              ...projectsState.editProjectRequest,
                              description: projectsState.editProjectRequest.description,
                              commercialIds: projectsState.editProjectRequest.commercialIds
                            },
                          })
                        );
                      }
                    }}
                  />
                ))
              }
            </div>
          }
          {
            tabValue === 1 && window.location.pathname.split('/')[4] &&
            <Button
              size={"sm"}
              iconPosition={"left"}
              label="Visualizza cestini"
              variant={"outline"}
              icon={<TrashIcon color={""} size={0} />}
              color={"blue"}
              onClick={() => dispatch(setSelectedTargetBins(targetListsState.findTargetListResponse?.id!))}
            />
          }
        </div>
      }
    >
      <EmailSettingModal />
      <div className="fixed flex flex-col gap-4 top-6 right-6 z-[100]">
        <Banner
          label={"Contatto rimosso dalla lista."}
          visible={targetListsState.removeContactsStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante la rimozione del contatto dalla lista target."
          }
          visible={targetListsState.removeContactsStatus === "failed"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Cliente aggiunto al progetto."}
          visible={projectsState.editCustomerInProjectStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditCustomerInProjectStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'aggiunta del contatto nel progetto."
          }
          visible={projectsState.editCustomerInProjectStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditCustomerInProjectStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Progetto modificato correttamente."}
          visible={projectsState.editProjectStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditProjectStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante la modifica del progetto."
          }
          visible={projectsState.editProjectStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditProjectStatus("idle"));
          }}
          type={"error"}
        />
        {/* <Banner label={'Progetto modificato con successo!'} visible={projectsState.editProjectStatus === 'successfully'} closeAction={() => { dispatch(setEditProjectStatus('idle')) }} type={'success'} /> */}
        <Banner label={'Template creato con successo!'} visible={mailsState.saveMailStatus === 'successfully'} closeAction={() => { dispatch(setSaveMailStatus('idle')) }} type={'success'} />
        <Banner label={'Creazione del template fallita!'} visible={mailsState.saveMailStatus === 'failed'} closeAction={() => { dispatch(setSaveMailStatus('idle')) }} type={'error'} />
      </div>
      <div className="h-full max-h-full">
        <TabPages
          direction={direction}
          setDirection={(value) => setDirection(value)}
          pages={[
            <Form key={0} />,
            <div key={1} className="max-h-full h-full">
              {editProjectMap.get(projectsState.editProjectPage)}
            </div>,
          ]}
          value={tabValue}
          setValue={(e) => {
            setTabValue(e);
          }}
        />
      </div>
    </Layout>
  );
}
