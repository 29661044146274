import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { findCompany, setEditCompanyStatus, setSelectedIdsCompanies, setShowPopupCompanies, setDeleteCompanyStatus } from "../../database/companies/slice";
import { setSelectedIdsPeople, setShowPopupPeople, setDeletePersonStatus, findPerson, setEditPersonStatus, setNewPersonStatus, setNewPersonResponse } from "../../database/people/slice";
import { findTargetListById, setAddCompaniesBulkStatus, setAddContactsStatus, setAddPeopleBulkStatus, setChangeContactStateStatus, setEditTargetListStatus, setRemoveContactsStatus, setTargetListPage } from "../slice";
import { Banner } from "../../../ui/organisms/banner";
import { TargetListPageEnum } from "../dto";

export function BannerTargetListDetails() {
    const targetListState = useAppSelector(state => state.targetList)
    const companiesState = useAppSelector(state => state.companies)
    const peopleState = useAppSelector(state => state.people)

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.addContactsStatus === "failed" ||
            targetListState.addContactsStatus === "successfully"
        ) {
            if (targetListState.addContactsStatus === "successfully") {
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
            }
            timeoutId = setTimeout(() => {
                dispatch(setAddContactsStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.addContactsStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.addPeopleBulkStatus === "failed" ||
            targetListState.addPeopleBulkStatus === "successfully"
        ) {
            if (targetListState.addPeopleBulkStatus === "successfully") {
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
            }
            timeoutId = setTimeout(() => {
                dispatch(setAddPeopleBulkStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.addPeopleBulkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.addCompaniesBulkStatus === "failed" ||
            targetListState.addCompaniesBulkStatus === "successfully"
        ) {
            if (targetListState.addCompaniesBulkStatus === "successfully") {
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
            }
            timeoutId = setTimeout(() => {
                dispatch(setAddCompaniesBulkStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.addCompaniesBulkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.editTargetListStatus === "failed" ||
            targetListState.editTargetListStatus === "successfully"
        ) {
            if (targetListState.editTargetListStatus === "successfully") {
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditTargetListStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.editTargetListStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.removeContactsStatus === "failed" ||
            targetListState.removeContactsStatus === "successfully"
        ) {
            if (targetListState.removeContactsStatus === "successfully") {
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
            }
            timeoutId = setTimeout(() => {
                dispatch(setRemoveContactsStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.removeContactsStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.changeContactStateStatus === "failed" ||
            targetListState.changeContactStateStatus === "successfully"
        ) {
            if (targetListState.changeContactStateStatus === "successfully") {
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
            }
            timeoutId = setTimeout(() => {
                dispatch(setChangeContactStateStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.changeContactStateStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteCompanyStatus === "failed" ||
            companiesState.deleteCompanyStatus === "successfully"
        ) {
            dispatch(setSelectedIdsCompanies([]))
            dispatch(setShowPopupCompanies(false))
            if (companiesState.deleteCompanyStatus === "successfully") {
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteCompanyStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deletePersonStatus === "failed" ||
            peopleState.deletePersonStatus === "successfully"
        ) {
            dispatch(setSelectedIdsPeople([]))
            dispatch(setShowPopupPeople(false))
            if (peopleState.deletePersonStatus === "successfully") {
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeletePersonStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deletePersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);


    return (
        <div className="fixed flex flex-col gap-4 top-6 right-6">
            <Banner
                label={"Contatto aggiunto correttamente"}
                visible={peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"success"}
            />
            <Banner
                label={"Il contatto che stai provando ad inserire esiste ed è associato all'azienda"}
                visible={peopleState.newPersonStatus === "successfully" && peopleState.newPersonResponse?.alreadyExists === true}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"warning"}
            />
            <Banner
                label={peopleState.newPersonResponseMessage}
                visible={peopleState.newPersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }}
                type={"error"}
            />
            <Banner
                label={"Persona eliminata."}
                visible={peopleState.deletePersonStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setDeletePersonStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={
                    "Si è verificato un errore durante l'eliminazione della persona."
                }
                visible={peopleState.deletePersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setDeletePersonStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Azienda eliminata."}
                visible={companiesState.deleteCompanyStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setDeleteCompanyStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={
                    "Si è verificato un errore durante l'eliminazione dell'azienda."
                }
                visible={companiesState.deleteCompanyStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setDeleteCompanyStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Azienda aggiornata."}
                visible={companiesState.editCompanyStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setEditCompanyStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica dell'azienda."}
                visible={companiesState.editCompanyStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setEditCompanyStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Contatto aggiornato."}
                visible={peopleState.editPersonStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setEditPersonStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica del contatto."}
                visible={peopleState.editPersonStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setEditPersonStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Esito aggiornato."}
                visible={targetListState.changeContactStateStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setChangeContactStateStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica dell'esito."}
                visible={targetListState.changeContactStateStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setChangeContactStateStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={
                    (targetListState.editTargetListTabValue === 1 ? "Persone " : "Aziende") + " aggiunte alla lista."
                }
                visible={targetListState.addContactsStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setAddContactsStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Lista target modificata correttamente."}
                visible={targetListState.editTargetListStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setEditTargetListStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={"Si è verificato un errore durante la modifica della lista target"}
                visible={targetListState.editTargetListStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setEditTargetListStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={
                    "Si è verificato un errore durante l'aggiunta delle " +
                    (targetListState.editTargetListTabValue === 1 ? "persone " : "aziende") +
                    " alla lista target."
                }
                visible={targetListState.addContactsStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setAddContactsStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Persone aggiunte alla lista."}
                visible={targetListState.addPeopleBulkStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setAddPeopleBulkStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={
                    "Si è verificato un errore durante l'aggiunta delle persone alla lista target."
                }
                visible={targetListState.addPeopleBulkStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setAddPeopleBulkStatus("idle"));
                }}
                type={"error"}
            />
            <Banner
                label={"Aziende aggiunte alla lista."}
                visible={targetListState.addCompaniesBulkStatus === "successfully"}
                closeAction={function (): void {
                    dispatch(setAddCompaniesBulkStatus("idle"));
                }}
                type={"success"}
            />
            <Banner
                label={
                    "Si è verificato un errore durante l'aggiunta delle aziende alla lista target."
                }
                visible={targetListState.addCompaniesBulkStatus === "failed"}
                closeAction={function (): void {
                    dispatch(setAddCompaniesBulkStatus("idle"));
                }}
                type={"error"}
            />
        </div>
    )
}