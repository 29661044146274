import { ReactElement } from "react";
import { useAppDispatch } from "../../../lib/redux/hooks";
import { colors } from "../../../ui/colors";
import { CheckCircleIcon } from "../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../ui/icons/circle";
import { LinkedinIcon } from "../../../ui/icons/linkedin";
import Pills from "../../../ui/molecules/pills";
import { PersonDTO } from "./dto";
import { setSelectedPerson, setTabValueDetailsPerson } from "./slice";

interface Props {
  column: string;
  index: number;
  person: PersonDTO;
}
export function Cell(props: Props) {
  const { column, index, person } = props;

  const dispatch = useAppDispatch();

  const cellMap = new Map<string, ReactElement>([
    [
      "Contatto",
      <div
        onClick={() => {
          dispatch(setSelectedPerson(person.id))
          dispatch(setTabValueDetailsPerson(0))
        }}
        className="crop-text max-w-[150px] text-text-sm cursor-pointer font-semibold text-neutral-800"
      >
        {(person.name ?? "-") + " " + (person.surname ?? "-")}
      </div>,
    ],
    [
      "Telefono",
      person.toVerifyFields?.includes('phone') ?
        <Pills
          size={"xs"}
          label={person.phone && person.phone[0] ? (person.phone[0] + (person.phone.length > 1 ? (', ..., + ' + (person.phone.length - 1)) : '')) : '-'}
          outline={true}
          emphasis={true}
          color={"red"}
        /> :
        <a
          rel="noreferrer"
          target="_blank"
          href={"tel:" + person.phone}
        >
          <div className="crop-text max-w-[150px] text-text-sm text-neutral-600">
            {person.phone}
          </div>
        </a>,
    ],
    [
      "Email",
      <a
        rel="noreferrer"
        target="_blank"
        href={"mailto:" + person.email}
        className="w-min"
      >
        <div className="crop-text max-w-[200px] text-text-sm text-neutral-600">
          {person.email}
        </div>
      </a>
    ],
    [
      "Azienda",
      person.toVerifyFields?.includes('company') ?
        <Pills
          size={"xs"}
          label={person.companyName ?? '-'}
          outline={true}
          emphasis={true}
          color={"red"}
        /> :
        <div
          onClick={() => {
            dispatch(setSelectedPerson(person.id))
            dispatch(setTabValueDetailsPerson(1))
          }}
          className="block cursor-pointer crop-text max-w-[150px] text-text-sm font-semibold text-brandPrimary-500">
          {person.companyName}
        </div>,
    ],
    [
      "Stato",
      <div>
        <Pills
          size={"xs"}
          label={person.active ? "Attivo" : "Inattivo"}
          outline={false}
          emphasis={false}
          color={person.active ? "green" : "blue"}
        />
      </div>,
    ],
    [
      "Linkedin",
      <a
        rel="noreferrer"
        target="_blank"
        href={person.linkedinProfile}
        className="w-full flex justify-center"
      >
        <LinkedinIcon color={colors.neutral[400]} size={20} />
      </a>,
    ],
    [
      "Ruolo",
      <span className="crop-text max-w-[150px] text-text-sm text-neutral-600">{person.role}</span>,
    ],
    [
      "Reparto",
      <span className="crop-text max-w-[150px] text-text-sm text-neutral-600">
        {person.department}
      </span>,
    ],
    [
      "Privacy",
      <div className="w-full flex justify-center">
        {person.privacy ? (
          <CheckCircleIcon color={colors.neutral[400]} size={20} />
        ) : (
          <CircleIcon color={colors.neutral[400]} size={20} />
        )}
      </div>,
    ],
  ]);

  return (
    <td>
      {cellMap.get(column)}
    </td>
  );
}
