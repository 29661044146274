import clsx from "clsx";
import { useAppSelector } from "../../../../lib/redux/hooks";
import { ContactStateEnum } from "../../../targetList/dto";

export default function useCreateBorder(isInTargetList: boolean): string {
    const targetListsState = useAppSelector(state => state.targetList)
    const peopleState = useAppSelector(state => state.people)

    const contactState = targetListsState.findTargetListResponse?.personContacts &&
        targetListsState.findTargetListResponse?.personContacts[
        peopleState.findPersonResponse?.id!
        ] ?
        targetListsState.findTargetListResponse?.personContacts[
            peopleState.findPersonResponse?.id!
        ].contactState : ContactStateEnum.DRAFT

    return (
        clsx("h-full", {
            "border-t-green-500 border-t-4":
                isInTargetList &&
                contactState === ContactStateEnum.LEADTAKEN,
            "border-t-orange-500 border-t-4":
                isInTargetList &&
                contactState === ContactStateEnum.TORECALL,
            "border-t-red-500 border-t-4":
                isInTargetList &&
                contactState === ContactStateEnum.KO,
            "border-t-neutral-500 border-t-4":
                isInTargetList &&
                contactState === ContactStateEnum.INCORRECT,
            "border-t-brandPrimary-500 border-t-4":
                isInTargetList &&
                contactState === ContactStateEnum.DRAFT,
        })
    )
}
