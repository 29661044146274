import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { colors } from "../../../../../ui/colors";
import { ArrowRightIcon } from "../../../../../ui/icons/arrowRight";
import { TargetListPageEnum } from "../../../../targetList/dto";
import {
  findAllTArgetLists,
  setTargetListPage,
} from "../../../../targetList/slice";
import { ImportFromPeople } from "./contacts";
import { ImportFromTargetTargetList } from "./target";

export function ImportFromTargetList() {
  const dispatch = useAppDispatch();

  const targetListsState = useAppSelector((state) => state.targetList);
  const usersState = useAppSelector((state) => state.users);

  const [step, setStep] = useState<"target" | "contact">("target");
  const [target, setTarget] = useState<
    { id: string; name: string } | undefined
  >(undefined);

  useEffect(() => {
    dispatch(
      findAllTArgetLists({
        commercial: [],
        contacts: "",
        itemsPerPage: 50,
        category: [],
        name: "",
        order: false,
        page: 0,
        sector: [],
        sort: "name",
        state: [],
        teamLeader: [],
        project: [],
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      findAllTArgetLists({
        ...targetListsState.filters,
        page: 0,
      })
    );
  }, [
    targetListsState.filters.commercial,
    targetListsState.filters.contacts,
    targetListsState.filters.name,
    targetListsState.filters.order,
    targetListsState.filters.itemsPerPage,
    targetListsState.filters.sector,
    targetListsState.filters.sort,
    targetListsState.filters.state,
    targetListsState.filters.teamLeader,
  ]);

  useEffect(() => {
    dispatch(findAllTArgetLists(targetListsState.filters));
  }, [targetListsState.filters.page]);

  return (
    <div className="h-full max-h-full">
      <div className="p-4 bg-brandPrimary-50 border-y border-y-neutral-100 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
            onClick={() => dispatch(setTargetListPage(TargetListPageEnum.ADD))}
          >
            Seleziona il tipo di importazione
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span
            onClick={() => {
              if (step === "contact") setStep("target");
            }}
            className={clsx({
              "text-text-md font-bold text-neutral-800": step === "target",
              "text-text-md font-bold text-neutral-400 cursor-pointer":
                step === "contact",
            })}
          >
            Aggiungi da lista target
          </span>
          {step === "contact" && (
            <ArrowRightIcon color={colors.neutral[500]} size={24} />
          )}
          {step === "contact" && (
            <span className={clsx("text-text-md font-bold text-neutral-800")}>
              Seleziona persone
            </span>
          )}
        </div>
      </div>
      {step === "target" && (
        <ImportFromTargetTargetList
          setStep={(value) => setStep(value)}
          setTarget={function (
            value: { id: string; name: string } | undefined
          ): void {
            setTarget(value);
          }}
        />
      )}
      {step === "contact" && <ImportFromPeople target={target} />}
    </div>
  );
}
