import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { EyeIcon } from "../../../../ui/icons/eye"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllCompaniesFiltersHasSmartphone } from "../slice"

export function SmartphoneFilter() {
    const companiesState = useAppSelector(state => state.companies)
    const options = [
        { value: 'true', label: 'Sì' },
        { value: 'false', label: 'No' }
    ]

    const dispatch = useAppDispatch()

    return (
        <AccordionFilter
            key={'person-filter-smartphone'}
            label={"Smartphone"}
            icon={<EyeIcon color={""} size={0} />}
            showClear={companiesState.filters.hasSmartphone !== undefined}
            options={companiesState.filters.hasSmartphone !== undefined ? [companiesState.filters.hasSmartphone] : []}
            clearAction={function (): void {
                dispatch(setFindAllCompaniesFiltersHasSmartphone(undefined))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllCompaniesFiltersHasSmartphone(undefined))
            }}
        >
            <SelectCustom
                isClearable
                onChange={e => {
                    if (e === undefined) {
                        dispatch(setFindAllCompaniesFiltersHasSmartphone(undefined))
                    } else if (typeof e === 'string') {
                        dispatch(setFindAllCompaniesFiltersHasSmartphone(({ value: e, label: options.find(opt => opt.value === e)?.label })))
                    }
                }}
                defaultValue={companiesState.filters.hasSmartphone?.value}
                placeholder={"Smartphone"}
                options={options}
            />
        </AccordionFilter>
    )
}