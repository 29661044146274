import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { TargetListPageEnum } from "../../../targetList/dto";
import { } from "../../slice";
import {
  findAllPeople,
  setFindAllPeopleFiltersAtecos,
  setFindAllPeopleFiltersCategory,
  setFindAllPeopleFiltersCity,
  setFindAllPeopleFiltersCountryRegion,
  setFindAllPeopleFiltersCreationContactDate,
  setFindAllPeopleFiltersDepartment,
  setFindAllPeopleFiltersEmployees,
  setFindAllPeopleFiltersHasSmartphone,
  setFindAllPeopleFiltersLastContactDate,
  setFindAllPeopleFiltersName,
  setFindAllPeopleFiltersOutcome,
  setFindAllPeopleFiltersOutcomeInfo,
  setFindAllPeopleFiltersParticolarity,
  setFindAllPeopleFiltersPrivacy,
  setFindAllPeopleFiltersProject,
  setFindAllPeopleFiltersRevenue,
  setFindAllPeopleFiltersRole,
  setFindAllPeopleFiltersSector,
  setFindAllPeopleFiltersSmartphone,
  setFindAllPeopleFiltersState,
  setFindAllPeopleFiltersTargetList,
  setFindAllPeopleFiltersZipCode,
} from "../slice";
import { AtecoFilter } from "./ateco";
import { CategoryFilter } from "./category";
import { CompanyFilter } from "./company";
import { ContactCreationFilter } from "./contactCreationFilter";
import { DateOfContactFilter } from "./dateOfContactFilter";
import { DepartmentFilter } from "./department";
import { EmployeesNumberFilter } from "./employeesNumber";
import { OutcomeFilter } from "./outcome";
import { OutcomeInfoFilter } from "./outcomeInfoFilter";
import { ParticularitiesFilters } from "./particularitiesFilters";
import { PhoneFilter } from "./phoneFilter";
import { PrivacyFilter } from "./privacy";
import { ProjectFilter } from "./project";
import { RevenueFilter } from "./revenue";
import { RoleFilter } from "./role";
import { SectorFilter } from "./sector";
import { SmartphoneFilter } from "./smartphone";
import { StateFilter } from "./state";
import { TargetFilter } from "./target";

interface Props {
  isInTargetList: boolean
}

export function Filters(props: Props) {
  const { isInTargetList } = props
  const peopleState = useAppSelector((state) => state.people);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(findAllCompaniesForFilters());
    // dispatch(findAllTargetListForFilters());
    // dispatch(findAllProjectsForFilters());
  }, []);

  const filters = Object.entries(peopleState.filters).filter(
    (entr) =>
      entr[0] !== "page" &&
      entr[0] !== "itemsPerPage" &&
      entr[0] !== "order" &&
      entr[0] !== "sort" &&
      entr[0] !== "personName" &&
      entr[0] !== "personEmail" &&
      entr[0] !== "targetListBin" &&
      entr[0] !== "hasCompany" &&
      entr[0] !== "active" &&
      entr[1] !== undefined &&
      entr[1].length !== 0 &&
      (window.location.pathname.includes("target") &&
        targetListsState.targetListPage !== TargetListPageEnum.DB
        ? entr[0] !== "project" && entr[0] !== "targetList"
        : true)
  ).length;

  return (
    <div className="bg-white border-r h-full border-neutral-200 rounded-b-xl w-full relative">
      <div className="px-[24px] h-16 flex items-center justify-between sticky">
        <span className="text-text-md text-neutral-800 font-bold">
          {"Filtri " + (filters === 0 ? "" : "(" + filters + ")")}
        </span>
        <div className="flex items-center">
          <Button
            size={"sm"}
            iconPosition={"off"}
            variant={"link"}
            color={"blue"}
            label="Pulisci tutti"
            onClick={() => {
              dispatch(setFindAllPeopleFiltersName([]));
              dispatch(setFindAllPeopleFiltersRevenue(undefined));
              dispatch(setFindAllPeopleFiltersEmployees(undefined));
              dispatch(setFindAllPeopleFiltersAtecos([]));
              dispatch(setFindAllPeopleFiltersSector([]));
              dispatch(setFindAllPeopleFiltersCategory([]));
              dispatch(setFindAllPeopleFiltersDepartment([]));
              dispatch(setFindAllPeopleFiltersRole([]));
              dispatch(setFindAllPeopleFiltersState(undefined));
              dispatch(setFindAllPeopleFiltersCity([]));
              dispatch(setFindAllPeopleFiltersParticolarity([]))
              dispatch(setFindAllPeopleFiltersCountryRegion([]));
              dispatch(setFindAllPeopleFiltersHasSmartphone(undefined));
              dispatch(setFindAllPeopleFiltersPrivacy(undefined));
              dispatch(setFindAllPeopleFiltersZipCode([]));
              dispatch(setFindAllPeopleFiltersSmartphone(undefined))
              dispatch(setFindAllPeopleFiltersCreationContactDate(undefined))
              dispatch(setFindAllPeopleFiltersLastContactDate(undefined))
              dispatch(setFindAllPeopleFiltersOutcomeInfo([]))
              dispatch(
                setFindAllPeopleFiltersTargetList(
                  window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                      {
                        label: targetListsState.findTargetListResponse?.name!,
                        value: targetListsState.findTargetListResponse?.id!,
                      },
                    ]
                    : []
                )
              );
              dispatch(
                setFindAllPeopleFiltersProject([])
              );
              dispatch(setFindAllPeopleFiltersOutcome([]));
              dispatch(
                findAllPeople({
                  atecos: [],
                  category: [],
                  particularity: [],
                  department: [],
                  employees: undefined,
                  itemsPerPage: peopleState.filters.itemsPerPage,
                  name: [],
                  order: true,
                  page: 0,
                  privacy: undefined,
                  revenue: undefined,
                  role: [],
                  sector: [],
                  city: [],
                  hasSmartphone: undefined,
                  sort: undefined,
                  state: undefined,
                  outcome: [],
                  countryRegion: [],
                  zipCode: [],
                  targetListBin: false,
                  targetList: window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                      {
                        label: targetListsState.findTargetListResponse?.name!,
                        value: targetListsState.findTargetListResponse?.id!,
                      },
                    ]
                    : [],
                  project: [],
                  smartphone: undefined,
                  creationContactDate: undefined,
                  lastContactDate: undefined,
                  outcomeInfo: []
                })
              );
            }}
          />
        </div>
      </div>
      <div className="overflow-auto rounded-b-xl" style={{ height: 'calc(100% - 64px)' }}>
        <CompanyFilter isInTargetList={isInTargetList} />
        <RevenueFilter />
        <OutcomeFilter />
        <EmployeesNumberFilter />
        {(!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListsState.targetListPage === TargetListPageEnum.DB)) && (
            <>
              <ProjectFilter />
              <TargetFilter />
            </>
          )}
        <AtecoFilter />
        <SectorFilter />
        <CategoryFilter />
        <DepartmentFilter />
        <RoleFilter />
        <SmartphoneFilter />
        <PrivacyFilter />
        <StateFilter />
        <ParticularitiesFilters />
        <PhoneFilter />
        <ContactCreationFilter />
        <DateOfContactFilter />
        <OutcomeInfoFilter />
      </div>
    </div>
  );
}
