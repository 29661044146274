
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import { Spinner } from '../../../ui/molecules/spinner';
import { NotFound } from '../../../ui/organisms/notFound';
import Pills from '../../../ui/molecules/pills';
import { findEmailByID, setEmailsHistoryFilters } from './slice';
import { MailIcon } from '../../../ui/icons/mail';
import { colors } from '../../../ui/colors';
import { findProjectById } from '../slice';

export function SentMailsList() {

  const dispatch = useAppDispatch()

  const mailsState = useAppSelector(state => state.mails)
  const usersState = useAppSelector(state => state.users)
  const projectState = useAppSelector(state => state.projects)
  const targetListState = useAppSelector(state => state.targetList)

  const [emailId, setEmailId] = useState('')

  useEffect(() => {
    if (emailId.length > 0)
      dispatch(findEmailByID(emailId))
  }, [emailId])

  useEffect(() => {
    if (targetListState.findTargetListResponse)
      dispatch(findProjectById(targetListState.findTargetListResponse?.projectId))
  }, [targetListState.findTargetListResponse])

  useEffect(() => {
    if (projectState.findProjectResponse)
      dispatch(setEmailsHistoryFilters({ ...mailsState.emailsHistoryFilters, fromEmail: projectState.findProjectResponse.emailFrom }))
  }, [projectState.findProjectResponse])

  if (usersState.findUsersByUserIdStatus === 'loading' || mailsState.findEmailsHistoryStatus === 'loading') {
    return (
      <div className="w-full p-6 flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="p-6 border-t border-t-neutral-100 w-full flex flex-col items-start gap-4">
      {(mailsState.findEmailsHistoryResponse && mailsState.findEmailsHistoryResponse.messages.length > 0) &&
        mailsState.findEmailsHistoryResponse.messages.map((email, index) =>
          <div key={email.msg_id} onClick={() => setEmailId(email.msg_id)} className="rounded-lg cursor-pointer border w-full border-neutral-100 bg-white hover:bg-neutral-50 shadow-xs hover:shadow-none p-3 flex flex-col items-center justify-center gap-3">
            <div className="flex items-start justify-between gap-1 w-full">
              <div className="flex flex-col items-start w-full gap-2">
                <span className="text-text-sm w-full font-bold text-left text-neutral-800">
                  {email.from_email}
                </span>
                {(mailsState.findEmailByIdStatus === 'loading' && email.msg_id === emailId) && <Spinner size={10} />}
                {mailsState.findEmailByIdResponse.msg_id === email.msg_id && <div className=' flex gap-2.5 items-center '>
                  <Pills startIcon={<MailIcon color={colors.brandPrimary[800]} size={14} />} size={'xs'}
                    label={(mailsState.findAllMailsResponse && mailsState.findAllMailsResponse.data.find(item => item.subject === mailsState.findEmailByIdResponse.subject)) ?
                      mailsState.findAllMailsResponse.data.find(item =>
                        item.subject === mailsState.findEmailByIdResponse.subject)!.templateName : 'Template non trovato'}
                    outline={false} emphasis={false} color={'blue'} />
                  <span className=' text-text-xs text-neutral-700'>
                    {email.last_event_time.slice(0, 10) + " " + email.last_event_time.slice(11, 19)}
                  </span>
                  <span className=' text-text-xs text-neutral-700'>
                    {email.status === 'delivered' && email.opens_count === 0 ? '✔' : '✔✔'}
                  </span>
                </div>}
                <span className="text-text-sm font-medium text-neutral-700">
                  {email.subject}
                </span>
              </div>
            </div>
          </div>
        )
      }
      {
        (mailsState.findEmailsHistoryResponse && mailsState.findEmailsHistoryResponse.messages.length === 0) &&
        <div className=' w-full p-2'>
          <NotFound />
        </div>
      }
    </div>
  )
}
