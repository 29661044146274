import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/types";
import {
  FindAllParticularitiesResponse,
  ParticularitiesFilters,
  UpsertParticularityDTO,
} from "./dto";
import { NewParticularitiesService } from "./service";

interface ParticularitiesState {
  findAllStatus: PromiseStatuses;
  findAllResponse?: FindAllParticularitiesResponse;
  filters: ParticularitiesFilters;
  showNewParticularityModal: boolean;
  editParticularityStatus: PromiseStatuses;
  createParticularityStatus: PromiseStatuses;
  createParticularityResponse?: string;
  deleteParticularityStatus: PromiseStatuses;
  selectedParticularity?: { name: string; id: string };
}

const initialState: ParticularitiesState = {
  findAllStatus: "idle",
  filters: {
    itemsPerPage: 50,
    order: true,
    name: '',
    page: 0,
    sort: "name",
  },
  showNewParticularityModal: false,
  createParticularityStatus: "idle",
  editParticularityStatus: "idle",
  deleteParticularityStatus: "idle",
};

export const findAllParticularities = createAsyncThunk(
  "particularities/findAllParticularities",
  async (
    filters: ParticularitiesFilters,
    thunkApi
  ): Promise<FindAllParticularitiesResponse> => {
    const ParticularitiesService = NewParticularitiesService();

    return ParticularitiesService.findAllParticularities(filters);
  }
);

export const createParticularity = createAsyncThunk(
  "particularities/createParticularity",
  async (request: UpsertParticularityDTO, thunkApi): Promise<string> => {
    const ParticularitiesService = NewParticularitiesService();

    return ParticularitiesService.createParticularity(request);
  }
);

export const deleteParticularity = createAsyncThunk(
  "particularities/deleteParticularity",
  async (request: string, thunkApi): Promise<void> => {
    const ParticularitiesService = NewParticularitiesService();

    return ParticularitiesService.deleteParticularity(request);
  }
);

export const editParticularity = createAsyncThunk(
  "particularities/editParticularity",
  async (
    request: { data: UpsertParticularityDTO; id: string },
    thunkApi
  ): Promise<void> => {
    const ParticularitiesService = NewParticularitiesService();

    return ParticularitiesService.editParticularity(request.data, request.id);
  }
);

const particularitiesSlice = createSlice({
  name: "particularities/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(findAllParticularities.pending, (state) => {
        state.findAllStatus = "loading";
      })
      .addCase(findAllParticularities.fulfilled, (state, action) => {
        state.findAllStatus = "successfully";
        state.findAllResponse = action.payload;
      })
      .addCase(findAllParticularities.rejected, (state) => {
        state.findAllStatus = "failed";
      })
      .addCase(createParticularity.pending, (state) => {
        state.createParticularityStatus = "loading";
      })
      .addCase(createParticularity.fulfilled, (state, action) => {
        state.createParticularityStatus = "successfully";
        state.createParticularityResponse = action.payload;
      })
      .addCase(createParticularity.rejected, (state) => {
        state.createParticularityStatus = "failed";
      })
      .addCase(deleteParticularity.pending, (state) => {
        state.deleteParticularityStatus = "loading";
      })
      .addCase(deleteParticularity.fulfilled, (state, action) => {
        state.deleteParticularityStatus = "successfully";
      })
      .addCase(deleteParticularity.rejected, (state) => {
        state.deleteParticularityStatus = "failed";
      });
  },
  reducers: {
    setFindAllParticularitiesPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setFindAllParticularitiesOrder: (state, action) => {
      state.filters.order = action.payload;
    },
    setFindAllParticularitiesItemsPerPage: (state, action) => {
      state.filters.itemsPerPage = action.payload;
    },
    setFindAllParticularitiesSort: (state, action) => {
      state.filters.sort = action.payload;
    },
    setFindAllParticularitiesStatus: (state, action) => {
      state.filters.status = action.payload;
    },
    setShowNewParticularityModal: (state, action) => {
      state.showNewParticularityModal = action.payload;
    },
    setCreateParticularityStatus: (state, action) => {
      state.createParticularityStatus = action.payload;
    },
    setEditParticularityStatus: (state, action) => {
      state.editParticularityStatus = action.payload;
    },
    setDeleteParticularityStatus: (state, action) => {
      state.deleteParticularityStatus = action.payload;
    },
    setSelectedParticularity: (state, action) => {
      state.selectedParticularity = action.payload;
    },
    setCreateParticularityResponse: (state, action) => {
      state.createParticularityResponse = action.payload;
    },
  },
});

export const {
  setFindAllParticularitiesOrder,
  setFindAllParticularitiesPage,
  setFindAllParticularitiesSort,
  setFindAllParticularitiesStatus,
  setShowNewParticularityModal,
  setCreateParticularityStatus,
  setEditParticularityStatus,
  setSelectedParticularity,
  setDeleteParticularityStatus,
  setCreateParticularityResponse,
  setFindAllParticularitiesItemsPerPage
} = particularitiesSlice.actions;

export default particularitiesSlice.reducer;
