import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { PhoneCallIcon } from "../../../../ui/icons/phoneCall";
import Input from "../../../../ui/molecules/input";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { setFindAllPeopleFiltersSmartphone } from "../slice";

export function PhoneFilter() {
    const peopleState = useAppSelector(state => state.people)
    const dispatch = useAppDispatch()

    return (
        <AccordionFilter
            key={'person-filter-phone'}
            label={"Cellulare"}
            icon={<PhoneCallIcon color={""} size={0} />}
            showClear={peopleState.filters.smartphone !== undefined}
            options={peopleState.filters.smartphone !== undefined ? [peopleState.filters.smartphone] : undefined}
            clearAction={() => dispatch(setFindAllPeopleFiltersSmartphone(undefined))}
            deleteFilter={() => dispatch(setFindAllPeopleFiltersSmartphone(undefined))}
        >
            <Input
                key={'find-person-filter-phone'}
                placeholder="Cerca cellulare"
                onChangeText={(value) => {
                    if (value === undefined || value === "")
                        dispatch(setFindAllPeopleFiltersSmartphone(undefined))
                    else
                        dispatch(setFindAllPeopleFiltersSmartphone({ label: value, value: value }))
                }}
                value={peopleState.filters.smartphone?.value || ""}
            />
        </AccordionFilter>
    )
}