import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { EditIcon } from "../../ui/icons/edit";
import { SortIcon } from "../../ui/icons/sort";
import { TrashIcon } from "../../ui/icons/trash";
import Avatar from "../../ui/molecules/avatar";
import Pills from "../../ui/molecules/pills";
import { Spinner } from "../../ui/molecules/spinner";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../ui/organisms/dropdown";
import { Pagination } from "../../ui/organisms/pagination";
import { UserRoleMap } from "./dto";
import {
  deleteUser,
  setFindAllUsersFiltersItemsPerPage,
  setFindAllUsersFiltersOrder,
  setFindAllUsersFiltersPage,
  setFindAllUsersFiltersSort,
  setSelectedUser,
  setUpdateUserStatus,
} from "./slice";
import { CantDeleteModal } from "./cantDeleteModal";

export function UsersList() {
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    if (usersState.deleteUserStatus === "successfully" || usersState.deleteUserStatus === 'failed' || usersState.showCantDeleteModal) {
      setIdToDelete(undefined);
    }
  }, [usersState.deleteUserStatus, usersState.showCantDeleteModal]);

  if (
    usersState.findAllStatus === "loading" ||
    usersState.findUsersAvatarsListStatus === "loading"
  ) {
    return (
      <div className="w-full flex items-center justify-center h-[45%]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-[100%] flex flex-col" style={{ height: 'calc(100% - 128px)' }}>
      <div className="overflow-auto" style={{ height: 'calc(100% - 48px)' }}>
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs sticky top-0">
            <tr className="bg-neutral-50">
              <th className="text-left" />
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Nome
                  <div
                    onClick={() => {
                      dispatch(setFindAllUsersFiltersSort("name"));
                      dispatch(
                        setFindAllUsersFiltersOrder(!usersState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Cognome
                  <div
                    onClick={() => {
                      dispatch(setFindAllUsersFiltersSort("surname"));
                      dispatch(
                        setFindAllUsersFiltersOrder(!usersState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Ruolo
                  <div
                    onClick={() => {
                      dispatch(setFindAllUsersFiltersSort("role"));
                      dispatch(
                        setFindAllUsersFiltersOrder(!usersState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Email
                  <div
                    onClick={() => {
                      dispatch(setFindAllUsersFiltersSort("email"));
                      dispatch(
                        setFindAllUsersFiltersOrder(!usersState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  Telefono
                  <div
                    onClick={() => {
                      dispatch(setFindAllUsersFiltersSort("phone"));
                      dispatch(
                        setFindAllUsersFiltersOrder(!usersState.filters.order)
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {usersState.findAllResponse?.data.map((user) => (
              <tr
                onClick={() => {
                  dispatch(setUpdateUserStatus("idle"));
                  dispatch(setSelectedUser(user.id));
                }}
                className="bg-white hover:bg-brandPrimary-200 cursor-pointer h-[56px] border-b-[1px] border-b-neutral-100"
                key={user.id}
              >
                <td>
                  <Avatar
                    type="single"
                    size="sm"
                    shape="circle"
                    fullName={user.name + " " + user.surname}
                    altTextInitials={user.name[0] + user.surname[0]}
                    imageUrl={
                      usersState.findUsersAvatarsListResponse.find(
                        (object) => object.userId === user.id
                      )?.objectId!
                    }
                  />
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {user.name}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {user.surname}
                </td>
                <td className="max-w-[150px]">
                  <Pills
                    size={"sm"}
                    label={UserRoleMap.get(user.role)!}
                    outline={true}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="max-w-[200px] crop-text text-text-sm font-semibold text-neutral-600">
                  {user.email}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {user.phone}
                </td>
                <td
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  className="w-[20px] cursor-pointer">
                  <Dropdown
                    items={[
                      {
                        label: "Modifica",
                        icon: <EditIcon color={""} size={0} />,
                        onClick: () => {
                          dispatch(setUpdateUserStatus("idle"));
                          dispatch(setSelectedUser(user.id));
                        },
                      },
                      {
                        label: "Cancella",
                        icon: <TrashIcon color={""} size={0} />,
                        onClick: () => setIdToDelete(user.id),
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsPerPage={usersState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setFindAllUsersFiltersItemsPerPage(value))}
        found={usersState.findAllResponse?.total || 0}
        pages={usersState.findAllResponse?.totalPage || 1}
        currentPage={usersState.filters.page}
        setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))}
      />
      <CantDeleteModal />
      <ConfirmModal
        title={"Elimina utente"}
        label={"Sei sicuro di voler eliminare questo utente?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteUser(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
