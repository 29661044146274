import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { CopyIcon } from "../../ui/icons/copy";
import { EditIcon } from "../../ui/icons/edit";
import { SortIcon } from "../../ui/icons/sort";
import { TrashIcon } from "../../ui/icons/trash";
import { Spinner } from "../../ui/molecules/spinner";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../ui/organisms/dropdown";
import { Pagination } from "../../ui/organisms/pagination";
import { Cell } from "./cells";
import { columnOrderMapKey } from "./dto";
import {
  deleteTargetList,
  setEditTargetListTabValue,
  setSelectedTargetBins,
  setSelectedTargetList,
  setTargetListsFilterItemsPerPage,
  setTargetListsFilterOrder,
  setTargetListsFilterPage,
  setTargetListsFilterSort,
  setTargetListToCopy,
} from "./slice";
import { CopyTargetListToProjectModal } from "./copyToProjectModal";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { UsersIcon } from "../../ui/icons/users";
import { XCircleIcon } from "../../ui/icons/xCircle";
import { keycloak } from "../../lib/keycloak";

export function TargetListsList() {
  const targetListsState = useAppSelector((state) => state.targetList);
  const preferencesState = useAppSelector((state) => state.preferences);
  const usersState = useAppSelector((state) => state.users);

  const dispatch = useAppDispatch();
  const navigation = useNavigate()

  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    if (targetListsState.deleteTargetListStatus === "successfully") {
      setIdToDelete(undefined);
    }
  }, [targetListsState.deleteTargetListStatus]);

  useEffect(() => {
    if (targetListsState.selectedTargetBins !== undefined) {
      navigation("/target-list-bins/" + targetListsState.selectedTargetBins);
      dispatch(setSelectedTargetBins(undefined));
    }
  }, [targetListsState.selectedTargetBins]);

  if (targetListsState.findAllTargetListsStatus === "loading") {
    return (
      <div className="w-full flex items-center justify-center h-[45%]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-[100%] rounded-b-xl shadow-sm bg-white flex flex-col" style={{ height: 'calc(100% - 64px)' }}>
      <div className="overflow-auto" style={{ height: 'calc(100% - 48px)' }}>
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase sticky top-0 z-10 text-text-xs">
            <tr className="bg-neutral-50">
              {preferencesState.findAllResponse?.data[0].targetListPreferences
                .filter((column) => column.visible)
                .map((column, index) => (
                  <th className="text-left">
                    <div className="flex gap-[4px]">
                      {column.column}
                      <div
                        onClick={() => {
                          dispatch(
                            setTargetListsFilterSort(
                              columnOrderMapKey.get(column.column)
                            )
                          );
                          dispatch(
                            setTargetListsFilterOrder(
                              !targetListsState.filters.order
                            )
                          );
                        }}
                        className="cursor-pointer hover:opacity-70"
                      >
                        <SortIcon />
                      </div>
                    </div>
                  </th>
                ))}
              <th className={clsx("text-left", {
                hidden: keycloak.hasRealmRole('customer') && !usersState.findMeResponse?.canAccessDatabase,
              })}>
                <div className="flex gap-[4px] w-[20px]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {targetListsState.findAllTargetListsResponse?.data.map((target) => (
              <tr
                className={clsx(" h-[56px] border-b-[1px] border-b-neutral-100", {
                  "bg-white": !targetListsState.copiedTargetList,
                  "highlight": targetListsState.copiedTargetList === target.id
                })}
                key={target.id}
              >
                {preferencesState.findAllResponse?.data[0].targetListPreferences
                  .filter((column) => column.visible)
                  .map((column, index) => (
                    <Cell
                      key={target.id + "-" + column.column}
                      column={column.column}
                      target={target}
                    />
                  ))}
                <td className={clsx("w-[20px] cursor-pointer", { hidden: keycloak.hasRealmRole('customer') && !usersState.findMeResponse?.canAccessDatabase })}>
                  <Dropdown
                    items={[
                      {
                        label: "Modifica",
                        icon: <EditIcon color={""} size={0} />,
                        onClick: () => {
                          dispatch(setSelectedTargetList(target.id))
                          dispatch(setEditTargetListTabValue(0))
                        }
                      },
                      {
                        label: "Mostra contatti",
                        icon: <UsersIcon color={""} size={0} />,
                        onClick: () => {
                          dispatch(setSelectedTargetList(target.id))
                          dispatch(setEditTargetListTabValue(1))
                        }
                      },
                      {
                        label: "Visualizza cestini",
                        icon: <TrashIcon color={""} size={0} />,
                        onClick: () => {
                          dispatch(setSelectedTargetBins(target.id))
                        }
                      },
                      (keycloak.hasRealmRole('customer') ? null : {
                        label: "Copia in un altro progetto",
                        icon: <CopyIcon color={""} size={0} />,
                        onClick: () => {
                          dispatch(setTargetListToCopy(target.id))
                        }
                      }),
                      (keycloak.hasRealmRole('customer') ? null : {
                        label: "Cancella",
                        icon: <XCircleIcon color={""} size={0} />,
                        onClick: () => setIdToDelete(target.id),
                      }),
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsPerPage={targetListsState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setTargetListsFilterItemsPerPage(value))}
        found={targetListsState.findAllTargetListsResponse?.total || 0}
        pages={targetListsState.findAllTargetListsResponse?.totalPage || 1}
        currentPage={targetListsState.filters.page}
        setPage={(page) => dispatch(setTargetListsFilterPage(page))}
      />
      <CopyTargetListToProjectModal />
      <ConfirmModal
        title={"Elimina lista target"}
        label={"Sei sicuro di voler eliminare questa lista target?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteTargetList(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
