import { useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { DownloadIcon } from "../../../ui/icons/download";
import { PlusIcon } from "../../../ui/icons/plus";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import {
  bulkCSVCompanies,
  findAllCompanies,
  setFindAllCompaniesFiltersPage,
  setFindAllCompaniesFiltersTargetList
} from "../../database/companies/slice";
import {
  bulkCSVPeople,
  findAllPeople,
  setFindAllPeopleFiltersPage,
  setFindAllPeopleFiltersTargetList
} from "../../database/people/slice";
import { findProjectById } from "../../projects/slice";
import { TargetListPageEnum } from "../dto";
import {
  editTargetList,
  findTargetListById,
  getCounters,
  setEditParticularitiesDefaultValue,
  setEditTargetListAtecosOptions,
  setEditTargetListRequestParticularity,
  setEditTargetListTabValue,
  setFindTargetListStatus,
  setTargetListPage,
  setValidateEditTargetListCategories,
  setValidateEditTargetListCommercials,
  setValidateEditTargetListDepartments,
  setValidateEditTargetListEmployeesFirst,
  setValidateEditTargetListEmployeesSecond,
  setValidateEditTargetListEmployeesSign,
  setValidateEditTargetListName,
  setValidateEditTargetListRevenueFirst,
  setValidateEditTargetListRevenueSecond,
  setValidateEditTargetListRevenueSign,
  setValidateEditTargetListRoles,
  setValidateEditTargetListSectors,
  setValidateEditTargetListStatus,
  validateEditTargetList
} from "../slice";
import { BannerTargetListDetails } from "./banner";
import { TargetListCompanies } from "./companies";
import { Form } from "./form";
import { TargetListPeople } from "./people";

export function EditTargetList() {
  const dispatch = useAppDispatch();

  const targetListState = useAppSelector((state) => state.targetList);
  const companiesState = useAppSelector((state) => state.companies);
  const peopleState = useAppSelector((state) => state.people);
  const usersState = useAppSelector((state) => state.users);

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);
  const [isFirstLoadPeople, setIsFirstLoadPeople] = useState(true);

  useEffect(() => {
    dispatch(findTargetListById(targetListState.selectedTargetList!));
    dispatch(setEditTargetListAtecosOptions([]))
    dispatch(setEditTargetListRequestParticularity(""))
    dispatch(setEditParticularitiesDefaultValue(undefined))
  }, []);

  useEffect(() => {
    if (targetListState.validateEditTargetList.status === "successfully") {
      dispatch(editTargetList({ id: targetListState.findTargetListResponse?.id!, data: targetListState.editTargetListRequest }));
      dispatch(setValidateEditTargetListStatus("idle"));
      dispatch(setEditTargetListAtecosOptions([]))
    }
  }, [targetListState.validateEditTargetList.status]);

  useEffect(() => {
    dispatch(setValidateEditTargetListStatus('idle'))
    dispatch(setValidateEditTargetListCategories(false))
    dispatch(setValidateEditTargetListCommercials(false))
    dispatch(setValidateEditTargetListDepartments(false))
    dispatch(setValidateEditTargetListEmployeesFirst(false))
    dispatch(setValidateEditTargetListEmployeesSecond(false))
    dispatch(setValidateEditTargetListEmployeesSign(false))
    dispatch(setValidateEditTargetListName(false))
    dispatch(setValidateEditTargetListRevenueFirst(false))
    dispatch(setValidateEditTargetListRevenueSecond(false))
    dispatch(setValidateEditTargetListRevenueSign(false))
    dispatch(setValidateEditTargetListRoles(false))
    dispatch(setValidateEditTargetListSectors(false))
  }, [])

  useEffect(() => {
    if (!isFirstLoadCompanies) {
      dispatch(setFindAllCompaniesFiltersPage(0));
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          page: 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  value: targetListState.findTargetListResponse?.id!,
                  label: targetListState.findTargetListResponse?.name!,
                },
              ]
              : [],
        })
      );
    }
  }, [
    companiesState.filters.order,
    companiesState.filters.itemsPerPage,
    companiesState.filters.sort,
    companiesState.filters.name,
    companiesState.filters.atecos,
    companiesState.filters.category,
    companiesState.filters.city,
    companiesState.filters.countryRegion,
    companiesState.filters.department,
    companiesState.filters.employees,
    companiesState.filters.filter,
    companiesState.filters.active,
    companiesState.filters.privacy,
    companiesState.filters.revenue,
    companiesState.filters.role,
    companiesState.filters.sector,
    companiesState.filters.hasSmartphone,
    companiesState.filters.state,
    companiesState.filters.zipCode,
    companiesState.filters.targetList,
    companiesState.filters.outcome,
    companiesState.filters.project,
    companiesState.filters.smartphone,
    companiesState.filters.creationContactDate,
    companiesState.filters.lastContactDate,
    companiesState.filters.outcomeInfo
  ]);

  useEffect(() => {
    if (!isFirstLoadPeople) {
      dispatch(setFindAllPeopleFiltersPage(0));
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          page: 0,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 500,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  value: targetListState.findTargetListResponse?.id!,
                  label: targetListState.findTargetListResponse?.name!,
                },
              ]
              : [],
        })
      );
    }
  }, [
    peopleState.filters.order,
    peopleState.filters.itemsPerPage,
    peopleState.filters.sort,
    peopleState.filters.name,
    peopleState.filters.atecos,
    peopleState.filters.category,
    peopleState.filters.city,
    peopleState.filters.countryRegion,
    peopleState.filters.department,
    peopleState.filters.employees,
    peopleState.filters.privacy,
    peopleState.filters.revenue,
    peopleState.filters.role,
    peopleState.filters.sector,
    peopleState.filters.hasSmartphone,
    peopleState.filters.state,
    peopleState.filters.zipCode,
    peopleState.filters.targetList,
    peopleState.filters.personName,
    peopleState.filters.personEmail,
    peopleState.filters.hasCompany,
    peopleState.filters.active,
    peopleState.filters.project,
    peopleState.filters.outcome,
    peopleState.filters.particularity,
    peopleState.filters.smartphone,
    peopleState.filters.creationContactDate,
    peopleState.filters.lastContactDate,
    peopleState.filters.outcomeInfo
  ]);

  useEffect(() => {
    if (!isFirstLoadCompanies)
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? companiesState.filters.itemsPerPage
              : 500,
        })
      );
  }, [companiesState.filters.page]);

  useEffect(() => {
    if (!isFirstLoadPeople)
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 500,
        })
      );
  }, [peopleState.filters.page]);

  useEffect(() => {
    if (targetListState.findTargetListStatus === "successfully") {
      dispatch(setFindTargetListStatus("idle"));
      const contacts = targetListState.editTargetListTabValue === 1 ? targetListState.getTargetListCounterResponse?.persons : targetListState.findTargetListResponse?.companyContacts
      dispatch(
        setTargetListPage(
          Object.keys(
            contacts ?? {}
          ).length === 0
            ? TargetListPageEnum.ADD
            : TargetListPageEnum.LIST
        )
      );
      dispatch(getCounters(targetListState.findTargetListResponse?.id!))
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? companiesState.filters.itemsPerPage
              : 500,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  label: targetListState.findTargetListResponse?.name!,
                  value: targetListState.findTargetListResponse?.id!,
                },
              ]
              : [],
        })
      );
      dispatch(
        setFindAllPeopleFiltersTargetList(
          targetListState.targetListPage !== TargetListPageEnum.DB
            ? [
              {
                label: targetListState.findTargetListResponse?.name!,
                value: targetListState.findTargetListResponse?.id!,
              },
            ]
            : []
        )
      );
      dispatch(
        setFindAllCompaniesFiltersTargetList(
          targetListState.targetListPage !== TargetListPageEnum.DB
            ? [
              {
                label: targetListState.findTargetListResponse?.name!,
                value: targetListState.findTargetListResponse?.id!,
              },
            ]
            : []
        )
      );
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 500,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  label: targetListState.findTargetListResponse?.name!,
                  value: targetListState.findTargetListResponse?.id!,
                },
              ]
              : [],
        })
      );
      dispatch(findProjectById(targetListState.findTargetListResponse?.projectId!))
      setIsFirstLoadCompanies(false);
      setIsFirstLoadPeople(false);
    }
  }, [targetListState.findTargetListStatus]);

  return (
    <div className="h-full max-h-full p-6">
      <BannerTargetListDetails />
      {targetListState.findTargetListStatus === "loading" || targetListState.getTargetListCounterStatus === 'loading' ? (
        <Spinner />
      ) : (
        <div className="flex flex-col bg-white rounded-2xl shadow-sm h-full max-h-full">
          <div className="p-4 pb-0 flex justify-between items-center sticky">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-6">
                <span className="text-heading-xs font-semibold text-neutral-800">
                  {targetListState.findTargetListResponse?.name}
                </span>
                <TabMenu
                  direction={direction}
                  setDirection={(value) => setDirection(value)}
                  setValue={(e) => {
                    dispatch(setEditTargetListTabValue(e));
                    if (e === 1 || e === 2) {
                      dispatch(setTargetListPage(TargetListPageEnum.LIST));
                    }
                  }}
                  value={targetListState.editTargetListTabValue}
                  items={[
                    {
                      icon: <></>,
                      label: "Specifiche lista",
                    },
                    {
                      icon: <></>,
                      label:
                        "Persone (" +
                        // (targetListState.getTargetListCounterStatus === 'loading' ?
                        //   <Spinner size={10} />
                        //   :
                        targetListState.getTargetListCounterResponse?.persons.inList.total
                        // ) 
                        +
                        ")",
                    },
                    {
                      icon: <></>,
                      label:
                        "Aziende (" +
                        // (targetListState.getTargetListCounterStatus === 'loading' ?
                        //   <Spinner size={10} />
                        //   :
                        targetListState.getTargetListCounterResponse?.companies.inList.total
                        // )
                        +
                        ")",
                    },
                  ]}
                />
              </div>
              <div className="flex justify-end items-center gap-2">
                {
                  ((companiesState.findAllResponse?.total !== 0 && targetListState.editTargetListTabValue === 2) || (targetListState.editTargetListTabValue === 1 && peopleState.findAllResponse?.total !== 0)) &&
                  !keycloak.hasRealmRole('customer') &&
                  <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    color={"blue"}
                    onClick={() => {
                      if (targetListState.editTargetListTabValue === 2) {
                        dispatch(bulkCSVCompanies({
                          name: companiesState.filters.name,
                          atecos: companiesState.filters.atecos,
                          sector: companiesState.filters.sector,
                          category: companiesState.filters.category,
                          department: companiesState.filters.department,
                          role: companiesState.filters.role,
                          hasSmartphone: companiesState.filters.hasSmartphone,
                          zipCode: companiesState.filters.zipCode,
                          countryRegion: companiesState.filters.countryRegion,
                          city: companiesState.filters.city,
                          targetList: companiesState.filters.targetList,
                          project: companiesState.filters.project,
                          outcome: companiesState.filters.outcome,
                          particularity: companiesState.filters.particularity,
                          smartphone: companiesState.filters.smartphone,
                          creationContactDate: companiesState.filters.creationContactDate,
                          lastContactDate: companiesState.filters.lastContactDate,
                          outcomeInfo: companiesState.filters.outcomeInfo
                        }))
                      } else {
                        dispatch(bulkCSVPeople({
                          name: peopleState.filters.name,
                          atecos: peopleState.filters.atecos,
                          sector: peopleState.filters.sector,
                          category: peopleState.filters.category,
                          department: peopleState.filters.department,
                          role: peopleState.filters.role,
                          zipCode: peopleState.filters.zipCode,
                          countryRegion: peopleState.filters.countryRegion,
                          hasSmartphone: peopleState.filters.hasSmartphone,
                          city: peopleState.filters.city,
                          targetList: peopleState.filters.targetList,
                          project: peopleState.filters.project,
                          outcome: peopleState.filters.outcome,
                          particularity: peopleState.filters.particularity,
                          smartphone: peopleState.filters.smartphone,
                          creationContactDate: peopleState.filters.creationContactDate,
                          lastContactDate: peopleState.filters.lastContactDate,
                          outcomeInfo: peopleState.filters.outcomeInfo
                        }))
                      }
                    }}
                    label="Esporta tutti"
                    icon={<DownloadIcon color={""} size={0} />}
                  />
                }
                {
                  (targetListState.editTargetListTabValue === 0 &&
                    !keycloak.hasRealmRole('customer') &&
                    !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous)) &&
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    label="Aggiorna"
                    variant={"outline"}
                    color={"blue"}
                    onClick={() => {
                      dispatch(
                        validateEditTargetList(targetListState.editTargetListRequest)
                      );
                    }}
                  />
                }
                {
                  !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous) &&
                  !keycloak.hasRealmRole('customer') &&
                  (targetListState.editTargetListTabValue !== 0 &&
                    targetListState.targetListPage !== TargetListPageEnum.CSV &&
                    targetListState.targetListPage !== TargetListPageEnum.DB &&
                    targetListState.targetListPage !== TargetListPageEnum.TL &&
                    targetListState.targetListPage !== TargetListPageEnum.ADD && (
                      <Button
                        size={"sm"}
                        iconPosition={"left"}
                        variant={"outline"}
                        color={"blue"}
                        icon={<PlusIcon color={""} size={0} />}
                        label={
                          targetListState.editTargetListTabValue === 2 ? "Aggiungi aziende" : "Aggiungi persone"
                        }
                        onClick={() =>
                          dispatch(setTargetListPage(TargetListPageEnum.ADD))
                        }
                      />
                    ))}
              </div>
            </div>
          </div>
          <div style={{ height: 'calc(100% - 79px)' }} className="border-t border-t-neutral-200">
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <div key={0} className="max-h-full h-full overflow-auto">
                  <Form />
                </div>,
                <div key={1} className="h-full">
                  <TargetListPeople />
                </div>,
                <div key={2} className="h-full">
                  <TargetListCompanies />
                </div>,
              ]}
              value={targetListState.editTargetListTabValue}
              setValue={(e) => {
                dispatch(setEditTargetListTabValue(e));
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
