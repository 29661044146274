import React, { useState } from 'react'
import { TrashIcon } from '../../icons/trash'
import { EditIcon } from '../../icons/edit'
import { colors } from '../../colors'
import clsx from 'clsx'
import { AccordionTemplateProps } from './dto'

export function AccordionTemplate(props: AccordionTemplateProps) {
    const [hoverEdit, setHoverEdit] = useState(false)
    const [hoverDelete, setHoverDelete] = useState(false)

    return (
        <div className=' border border-neutral-200 cursor-pointer rounded-xl flex flex-col '>
            <div className='flex items-center justify-between py-4 pr-2 pl-4'>
                <span onClick={() => props.onClick()} className={clsx(' text-neutral-500 hover:text-neutral-800 text-text-sm font-bold', {
                    'text-neutral-800': props.open
                })}>
                    {props.title}
                </span>
                <div className=' flex items-center gap-2'>
                    <div onClick={() => { props.onClick() }} className=' size-6 flex items-center justify-center' onPointerEnter={() => setHoverEdit(true)} onPointerLeave={() => setHoverEdit(false)}>
                        <EditIcon color={hoverEdit ? colors.brandPrimary[500] : colors.neutral[400]} size={18} />
                    </div>
                    <div onClick={() => props.deleteFunc && props.deleteFunc()} className=' size-6 flex items-center justify-center' onPointerEnter={() => setHoverDelete(true)} onPointerLeave={() => setHoverDelete(false)}>
                        <TrashIcon color={hoverDelete ? colors.red[500] : colors.neutral[400]} size={18} />
                    </div>
                </div>
            </div>
            {props.open &&
                <div className=' bg-neutral-50 rounded-x-xl rounded-b-xl w-full'>
                    {props.content}
                </div>
            }
        </div>
    )
}
