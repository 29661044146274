import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { SelectCustom } from "../../../../ui/molecules/select";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { setFindAllCompaniesFiltersOutcomeInfo } from "../slice";
export function OutcomeInfoFilter() {
    const companiesState = useAppSelector(state => state.companies)
    const dispatch = useAppDispatch()

    return (
        <AccordionFilter
            key={"companies-filter-outcome-info"}
            label={"Micro-esiti"}
            icon={<CheckCircleIcon color={""} size={0} />}
            showClear={companiesState.filters.outcomeInfo.length > 0}
            clearAction={() => dispatch(setFindAllCompaniesFiltersOutcomeInfo([]))}
            deleteFilter={(option) => dispatch(setFindAllCompaniesFiltersOutcomeInfo(companiesState.filters.outcomeInfo.filter(name => name.value !== option.value)))}
            options={companiesState.filters.outcomeInfo}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                placeholder={"Seleziona un micro-esito"}
                label="Micro-esiti"
                onChange={(e) => {
                    if (e !== undefined && typeof e !== 'string')
                        dispatch(setFindAllCompaniesFiltersOutcomeInfo(e.map(option => ({ value: option, label: option }))));
                }}
                defaultValue={
                    companiesState.filters.outcomeInfo.map((value) => value.value)
                }
                options={[
                    { label: "In attesa", value: "In attesa" },
                    { label: "Richiamo", value: "Richiamo" },
                    { label: "Decisore assente", value: "Decisore assente" },
                    { label: "Interessante", value: "Interessante" },
                    { label: "Non interessato", value: "Non interessato" },
                    { label: "Inesistente/Centralino/Servizio clienti", value: "Inesistente/Centralino/Servizio clienti" },
                    { label: "Primo filtro", value: "Primo filtro" },
                    { label: "Registro opposizioni", value: "Registro opposizioni" },
                    { label: "Non in target", value: "Non in target" },
                    { label: "Ruolo", value: "Ruolo" },
                    { label: "Altro", value: "Altro" }
                ]}
            />
        </AccordionFilter>
    )
}