import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { PhoneCallIcon } from "../../../../ui/icons/phoneCall";
import Input from "../../../../ui/molecules/input";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { setFindAllCompaniesFiltersSmartphone } from "../slice";
export function PhoneFilter() {
    const companiesState = useAppSelector(state => state.companies)
    const dispatch = useAppDispatch()

    return (
        <AccordionFilter
            key={'companies-filter-phone'}
            label={"Cellulare"}
            icon={<PhoneCallIcon color={""} size={0} />}
            showClear={companiesState.filters.smartphone !== undefined}
            options={companiesState.filters.smartphone !== undefined ? [companiesState.filters.smartphone] : undefined}
            clearAction={() => dispatch(setFindAllCompaniesFiltersSmartphone(undefined))}
            deleteFilter={() => dispatch(setFindAllCompaniesFiltersSmartphone(undefined))}
        >
            <Input
                key={'find-companies-filter-phone'}
                placeholder="Cerca cellulare"
                onChangeText={(value) => {
                    if (value === undefined || value === "")
                        dispatch(setFindAllCompaniesFiltersSmartphone(undefined))
                    else
                        dispatch(setFindAllCompaniesFiltersSmartphone({ label: value, value: value }))
                }}
                value={companiesState.filters.smartphone?.value || ""}
            />
        </AccordionFilter>
    )
}