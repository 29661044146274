import { PillsColor } from "../../ui/molecules/pills/Pills.types";
import { ItemsPerPageValues } from "../../ui/organisms/pagination/dto";
import { ParticularityDTO } from "../particularities/dto";
import { ProjectDTO } from "../projects/dto";
import { UserDTO } from "../users/dto";

export interface TargetListDTO {
  commercials: UserDTO[];
  contacts: number;
  companyContacts: null | { [key: string]: TargetListContactInfo };
  departments: string[];
  employeesNumber: string;
  id: string;
  name: string;
  leads: number;
  drafts: number;
  personContacts: null | { [key: string]: TargetListContactInfo };
  projectId: string;
  project: ProjectDTO;
  revenue: string;
  roles: string;
  state: TargetListStatusEnum;
  particularity: ParticularityDTO | null
  atecos: {
    atecoCode: string | null
    atecoDescription: string | null
    sector: string | null
    category: string | null
  }[]
}

export enum ContactStateEnum {
  DRAFT = "DRAFT",
  LEADTAKEN = "LEADTAKEN",
  TORECALL = "TORECALL",
  KO = "KO",
  INCORRECT = "INCORRECT",
}

export interface TargetListContactInfo {
  contactId: string;
  contactState: ContactStateEnum;
  lastContactDate: null | Date;
  notes: null | NoteDTO[];
  stateInfo: null | string
  callAttempts: null | number;
  createdAt: null | Date;
  deletedAt: null | Date
  id: null | string
}

export interface NoteDTO {
  note: string,
  date: string,
  authorUserId: string
}

export function stateInfoMap(state: ContactStateEnum): string[] {
  const StateInfoMap = new Map<ContactStateEnum, string[]>([
    [ContactStateEnum.DRAFT, []],
    [ContactStateEnum.LEADTAKEN, []],
    [ContactStateEnum.TORECALL, ["Richiamo", "Decisore assente", "Interessante"]],
    [ContactStateEnum.KO, ["Non interessato", "Inesistente/Centralino/Servizio clienti", "Primo filtro", "Registro opposizioni", "Non in target"]],
    [ContactStateEnum.INCORRECT, ["Fatturato", "Dipendenti", "Ruolo", "Settore", "Categoria", "Altro"]],
  ])

  return StateInfoMap.get(state)!
}

export function generateOutcomeOptions(
  count: null | number,
  contactState: ContactStateEnum,
  type: 'person' | 'company'
) {
  const outcomes = Object.values(ContactStateEnum);
  const outcomeLabelMap = new Map<ContactStateEnum, string>([
    [ContactStateEnum.DRAFT, "In attesa"],
    [ContactStateEnum.LEADTAKEN, "Lead preso"],
    [ContactStateEnum.TORECALL, "Da richiamare"],
    [ContactStateEnum.KO, "Ko"],
    [ContactStateEnum.INCORRECT, "Info errate"],
  ]);

  let options: { label: string; options: { label: string; value: string }[] }[] =
    [];

  outcomes.forEach((outcome) => {
    if (contactState !== ContactStateEnum.LEADTAKEN && outcome === ContactStateEnum.LEADTAKEN) {
      return;
    }

    const microOutcomes = stateInfoMap(outcome);

    if (microOutcomes.length === 0) {
      options.push({
        label: outcomeLabelMap.get(outcome)!,
        options: [{ label: outcomeLabelMap.get(outcome)!, value: outcome.toString() }],
      });
    } else {
      options.push({ label: outcomeLabelMap.get(outcome)!, options: [] });
      microOutcomes.forEach((micro) => {
        if (type === 'person' && outcome === ContactStateEnum.INCORRECT && micro !== 'Ruolo' && micro !== 'Altro') {
          return
        }
        let microLabel = micro;
        if (micro === "Richiamo" && count && count > 0) {
          microLabel = micro + "(" + count + ")";
        }
        options[options.length - 1].options.push({ label: microLabel, value: outcome + '::' + micro });
      });
    }
  });
  return options;
}


export function generateDefaultValue(contactState: ContactStateEnum, stateInfo: string | null) {
  if (contactState === ContactStateEnum.DRAFT || contactState === ContactStateEnum.LEADTAKEN) {
    return contactState
  } else {
    return (contactState + '::' + stateInfo)
  }
}

export interface FindAllTargetListsResponse {
  data: TargetListDTO[];
  next: boolean;
  page: number;
  perPage: number;
  prev: false;
  total: number;
  totalPage: number;
}

export interface FindAllTargetListsFilters {
  commercial: string[];
  contacts: string;
  itemsPerPage: ItemsPerPageValues;
  name: string;
  project: string[];
  order: boolean;
  page: number;
  sector: string[];
  category: string[];
  sort: keyof TargetListDTO;
  state: TargetListStatusEnum[];
  teamLeader: string[];
}

export interface EditTargetListRequest {
  name: string,
  commercialIds: string[],
  revenue: string,
  employeesNumber: string,
  particularityId?: string,
  departments: string[],
  roles: string[],
  atecos:
  {
    atecoCode: string | null,
    atecoDescription: string | null,
    sector: string | null,
    category: string | null
  }[]
}

export interface NewTargetListRequest {
  projectId: string;
  name: string;
  commercialIds: string[];
  departments: string[];
  roles: string[];
  revenue: string;
  employeesNumber: string;
  particularityId: string | null;
  atecos:
  {
    atecoCode: string | null,
    atecoDescription: string | null,
    sector: string | null,
    category: string | null
  }[]
}

export enum TargetListPageEnum {
  LIST,
  ADD,
  CSV,
  DB,
  TL,
}

export enum TargetListStatusEnum {
  TOSTART = "TOSTART",
  INPROGRESS = "INPROGRESS",
  SUSPENDED = "SUSPENDED",
  CONCLUDED = "CONCLUDED",
}

export interface ImportPersonDTO {
  name: string[];
  surname: string[];
  role: string[];
  department: string[];
  phone: string[];
  linkedinProfile: string[];
  privacy: string[];
  companyName: string[];
  notes: string[];
  lastContactDate: string[];
  email: string[];
  companyVat: string[];
  companyPhone: string[];
  contactState: string[];
}

export interface ImportCompanyDTO {
  name: string[];
  vat: string[];
  address: string[];
  zipCode: string[];
  city: string[];
  province: string[];
  countryRegion: string[];
  state: string[];
  email: string[];
  website: string[];
  revenue: string[];
  employeesNumber: string[];
  atecoCode: string[];
  atecoDescription: string[];
  sector: string[];
  category: string[];
  phone: string[];
  contactState: string[];
  lastContactDate: string[];
  notes: string[];
}

export interface TargetListCounterDTO {
  persons: {
    inList: StateCounterDTO,
    targetListBin: StateCounterDTO
  },
  companies: {
    inList: StateCounterDTO,
    targetListBin: StateCounterDTO
  }
}

export interface StateCounterDTO {
  total: number,
  leadTaken: number,
  toRecall: number,
  ko: number,
  incorrect: number,
  draft: number
}

export const targertListsStatePillMap = new Map<
  TargetListStatusEnum,
  { label: string; color: PillsColor }
>([
  [TargetListStatusEnum.TOSTART, { label: "Da iniziare", color: "gray" }],
  [TargetListStatusEnum.CONCLUDED, { label: "Concluso", color: "green" }],
  [TargetListStatusEnum.INPROGRESS, { label: "In corso", color: "blue" }],
  [TargetListStatusEnum.SUSPENDED, { label: "Sospeso", color: "orange" }],
]);

export const contactsStatePillMap = new Map<
  ContactStateEnum,
  { label: string; color: PillsColor }
>([
  [ContactStateEnum.DRAFT, { label: "In attesa", color: "blue" }],
  [ContactStateEnum.INCORRECT, { label: "Info errate", color: "gray" }],
  [ContactStateEnum.KO, { label: "Ko", color: "red" }],
  [ContactStateEnum.LEADTAKEN, { label: "Lead preso", color: "green" }],
  [ContactStateEnum.TORECALL, { label: "Da richiamare", color: "orange" }],
]);

export const columnOrderMapKey = new Map<string, string>([
  ["Settore", "sector"],
  ["Nome", "name"],
  ["Team Leader", "project.teamLeader.name"],
  ["Commerciali", "commercials.name"],
  ["Contatti", "contacts"],
  ["Lead", "contacts"],
  ["Da lavorare", "contacts"],
  ["Stato", "state"],
]);

export const columnOrderTargetPeopleMapKey = new Map<string, string>([
  ['Nome referente', 'name'],
  ['Cognome referente', 'surname'],
  ['Linkedin', ''],
  ['Ruolo', 'role'],
  ['Data di contatto', 'lastContactDate'],
  ['Esito', ''],
  ['Note', ''],
  ['Smartphone', 'phone'],
  ['Azienda', 'companyName'],
  ['Reparto', 'department'],
  ['Privacy', ''],
  ['Email', 'email'],
])


export const columnOrderTargetCompaniesMapKey = new Map<string, string>([
  ['Nome azienda', 'name'],
  ['Data di contatto', 'lastContactDate'],
  ['Fatturato', 'revenue'],
  ['Telefono', 'phone'],
  ['Esito', ''],
  ['Note', ''],
  ['Email', 'email'],
  ['Dipendenti', 'employeesId.lenght'],
  ['P. IVA', 'vat'],
  ['Sede azienda', 'address'],
  ['Sito web', ''],
  ['Cod. ATECO', 'atecoCode'],
  ['Settore', 'sector'],
  ['Categoria', 'category'],
  ['CAP', 'zipCode'],
])

