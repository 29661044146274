import { ItemsPerPageValues } from "../../ui/organisms/pagination/dto"
import { TargetListDTO } from "../targetList/dto"
import { UserDTO } from "../users/dto"

export interface ProjectDTO {
    id: string
    name: string,
    state: ProjectStateEnum,
    customer: UserDTO,
    customerId: string,
    sector: string
    description: string,
    teamLeader: UserDTO,
    commercials: UserDTO[],
    targetListSize: null | number
    targetListsIds: null | string[],
    targetLists: null | TargetListDTO[]
    startDate: Date,
    endDate: Date
    contactsNumber: null | number
    emailFrom: string | null
    nameFrom: string | null
}

export enum ProjectStateEnum {
    TOSTART = 'TOSTART',
    INPROGRESS = 'INPROGRESS',
    SUSPENDED = 'SUSPENDED',
    CONCLUDED = 'CONCLUDED',
}

export interface FindAllProjectsFilters {
    commercial: string[]
    name: string
    sector: string[]
    category: string[]
    startDate: string[]
    state: ProjectStateEnum[]
    teamLeaderId?: string
    order: boolean
    sort?: keyof ProjectDTO
    itemsPerPage: ItemsPerPageValues
    page: number
}

export interface FindAllProjectsResponse {
    data: ProjectDTO[]
    next: boolean
    page: number
    perPage: number
    prev: false
    total: number
    totalPage: number
}

export interface EditProjectRequest {
    description: string | null
    targetListsIds?: string[] | null
    commercialIds: string[] | null
    emailFrom: string | null
    nameFrom: string | null
}

export interface ProjectCustomerBindDTO {
    customerId: string
}

export interface NewProjectRequest {
    name: string,
    customerId?: string,
    description?: string,
    teamLeaderId?: string,
    commercialIds: string[]
    emailFrom: string | null
    nameFrom: string | null
}

export enum EditProjectPage {
    LIST,
    ADDLIST,
    EDITLIST
}