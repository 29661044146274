import { keycloak } from "../../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { EditIcon } from "../../../../ui/icons/edit";
import { LinkedinIcon } from "../../../../ui/icons/linkedin";
import { MailIcon } from "../../../../ui/icons/mail";
import { PhoneCallIcon } from "../../../../ui/icons/phoneCall";
import { Badge } from "../../../../ui/molecules/badge";
import Button from "../../../../ui/molecules/button";
import { GroupSelectCustom } from "../../../../ui/molecules/groupSelect";
import { Spinner } from "../../../../ui/molecules/spinner";
import { setMailDetailsTo, setSendEmailModalOpen } from "../../../projects/mails/slice";
import { ContactStateEnum, generateDefaultValue, generateOutcomeOptions } from "../../../targetList/dto";
import { changeContactState } from "../../../targetList/slice";
import { setTabValueDetailsPerson } from "../slice";

interface Props {
    visible: boolean
}

export function PersonTargetListInfo(props: Props) {
    const { visible } = props
    const peopleState = useAppSelector(state => state.people)
    const mailsState = useAppSelector(state => state.mails)
    const targetListsState = useAppSelector(state => state.targetList)
    const dispatch = useAppDispatch()

    const stateInfo = targetListsState.findTargetListResponse?.personContacts &&
        targetListsState.findTargetListResponse?.personContacts[
        peopleState.findPersonResponse?.id!
        ] ?
        targetListsState.findTargetListResponse?.personContacts[
            peopleState.findPersonResponse?.id!
        ].stateInfo : null

    const contactState = targetListsState.findTargetListResponse?.personContacts &&
        targetListsState.findTargetListResponse?.personContacts[
        peopleState.findPersonResponse?.id!
        ] ?
        targetListsState.findTargetListResponse?.personContacts[
            peopleState.findPersonResponse?.id!
        ].contactState : ContactStateEnum.DRAFT

    if (!visible) {
        return <></>
    }

    return (
        <>
            <div className="w-full px-6 py-3 flex items-center border-y border-y-brandPrimary-100 justify-between mt-1 bg-brandPrimary-50">
                <div className="gap-2 flex">
                    <div className=" relative">
                        <Button
                            size={"sm"}
                            iconPosition={"only"}
                            variant={"outline"}
                            onClick={() => {
                                dispatch(setMailDetailsTo(peopleState.findPersonResponse?.email))
                                dispatch(setSendEmailModalOpen(true))
                            }}
                            color={"blue"}
                            icon={<MailIcon color={""} size={0} />}
                        />
                        <div className=" absolute top-[-20%] right-[-8%]">
                            {mailsState.findEmailsHistoryStatus === 'loading' && <Spinner size={10} />}
                            {(mailsState.findEmailsHistoryStatus !== 'loading' && mailsState.findEmailsHistoryStatus !== 'failed' && mailsState.findEmailsHistoryResponse && mailsState.findEmailsHistoryResponse.messages.length > 0) &&
                                <Badge color={mailsState.findEmailsHistoryResponse &&
                                    (mailsState.findEmailsHistoryResponse.messages[0].opens_count > 0) ? 'green' : "red"}
                                    label={mailsState.findEmailsHistoryResponse.messages.length.toString()} />
                            }
                        </div>
                    </div>
                    <Button
                        disabled={keycloak.hasRealmRole('customer')}
                        size={"sm"}
                        iconPosition={"only"}
                        variant={"outline"}
                        color={"blue"}
                        onClick={() => {
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.rel = "noreferrer"
                            a.target = "_blank"
                            a.href = 'tel:' + peopleState.findPersonResponse?.phone;
                            a.click();
                        }}
                        icon={<PhoneCallIcon color={""} size={0} />}
                    />
                    <Button
                        disabled={keycloak.hasRealmRole('customer')}
                        size={"sm"}
                        iconPosition={"only"}
                        variant={"outline"}
                        color={"blue"}
                        onClick={() => {
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.rel = "noreferrer"
                            a.target = "_blank"
                            a.href = peopleState.findPersonResponse?.linkedinProfile!;
                            a.click();
                        }}
                        icon={<LinkedinIcon color={""} size={0} />}
                    />
                    <Button
                        disabled={keycloak.hasRealmRole('customer')}
                        size={"sm"}
                        iconPosition={"only"}
                        variant={"outline"}
                        onClick={() => dispatch(setTabValueDetailsPerson(2))}
                        color={"blue"}
                        icon={<EditIcon color={""} size={0} />}
                    />
                </div>
                <Button
                    size={"sm"}
                    disabled={keycloak.hasRealmRole('customer')}
                    iconPosition={contactState === ContactStateEnum.LEADTAKEN ? "left" : 'off'}
                    variant={"outline"}
                    label="Lead preso"
                    color={contactState === ContactStateEnum.LEADTAKEN ? "green" : 'gray'}
                    icon={<CheckCircleIcon color={""} size={0} />}
                    onClick={() => {
                        if (contactState !== ContactStateEnum.LEADTAKEN) {
                            dispatch(
                                changeContactState({
                                    targetListId:
                                        targetListsState.findTargetListResponse?.id!,
                                    type: "persons",
                                    contactState: ContactStateEnum.LEADTAKEN,
                                    contactId: peopleState.findPersonResponse?.id!,
                                    stateInfo: null
                                })
                            )
                        }
                    }}
                />
            </div>
            <div className="w-full px-6 py-3 flex items-center border-b border-b-brandPrimary-100 justify-between mt-1">
                <GroupSelectCustom
                    disabled={peopleState.findPersonResponse && peopleState.findPersonResponse?.contactCount >= 3 || keycloak.hasRealmRole('customer')}
                    menuPortalTargetIsNotBody
                    placeholder={"Selezion un esito"}
                    label="Esito"
                    onChange={(e) => {
                        if (e === ContactStateEnum.DRAFT || e === ContactStateEnum.LEADTAKEN) {
                            dispatch(
                                changeContactState({
                                    targetListId:
                                        targetListsState.findTargetListResponse?.id!,
                                    type: "persons",
                                    contactState: e,
                                    contactId: peopleState.findPersonResponse?.id!,
                                    stateInfo: null
                                })
                            );
                        } else {
                            dispatch(
                                changeContactState({
                                    targetListId:
                                        targetListsState.findTargetListResponse?.id!,
                                    type: "persons",
                                    contactState: e && e !== undefined && typeof e === 'string' ? e.split('::')[0] as ContactStateEnum : ContactStateEnum.DRAFT,
                                    contactId: peopleState.findPersonResponse?.id!,
                                    stateInfo: e && e !== undefined && typeof e === 'string' ? e.split('::')[1] : null
                                })
                            );
                        }
                    }}
                    defaultValue={generateDefaultValue(contactState, stateInfo)}
                    options={generateOutcomeOptions(peopleState.findPersonResponse?.contactCount!, contactState, 'person')}
                />
            </div>
        </>
    )
}