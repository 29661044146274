import clsx from 'clsx'
import { ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks'
import { XCircleIcon } from '../../../ui/icons/xCircle'
import Button from '../../../ui/molecules/button'
import Input from '../../../ui/molecules/input'
import { Spinner } from '../../../ui/molecules/spinner'
import TextArea from '../../../ui/molecules/textArea'
import { AccordionTemplate } from '../../../ui/organisms/accordionTemplate'
import { ConfirmModal } from '../../../ui/organisms/confirmModal.tsx'
import { NotFound } from '../../../ui/organisms/notFound'
import { editProject, setEditProjectEmailFrom, setEditProjectNameFrom } from '../slice'
import { findAllCustomMails, removeMail, resetCreateCustomMailRequest, saveMail, setCustomMailCreateRequest, setCustomMailUpdateRequest, setEmailSettingsModalOpen, setEmailsSettingsTab, setOpenTemplateId, setRemoveMailStatus, setTemplateToRemoveId, setUpdateMailStatus, updateMail } from './slice'
import { addToTextArea, HTMLfy, translateVariables, variablesMap } from './utils'

export function EmailSettingModal() {
    const mailsState = useAppSelector(state => state.mails)
    const projectState = useAppSelector(state => state.projects)
    const dispatch = useAppDispatch()

    const menuRef = useRef<HTMLTableCellElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const [openVariables, setOpenVariables] = useState(false)
    const [temporaryText, setTemporaryText] = useState('')

    function updateTemplate(text: string) {
        const customText = text.replace(/\[([^\]]+)\]/g, (match, word) => `${variablesMap.get(word)}`)
        const HTMLtext = HTMLfy(customText)
        return [customText, HTMLtext]
    }

    let variableOptions: ReactNode[] = []
    variablesMap.forEach((key, item) => variableOptions.push(<Button onClick={() => { addToTextArea('[' + item + ']'); setTemporaryText(document.querySelector('textarea')?.value!) }} label={item} size={'sm'} iconPosition={'left'} variant={'link'} color={'gray'} />))

    useEffect(() => {
        if (projectState.findProjectResponse) {
            dispatch(setCustomMailCreateRequest({
                ...mailsState.customMailCreateRequest,
                projectId: projectState.findProjectResponse.id,
                fromEmail: projectState.findProjectResponse.emailFrom,
                fromEmailName: projectState.findProjectResponse.nameFrom
            }))
            dispatch(setCustomMailUpdateRequest({
                ...mailsState.customMailCreateRequest,
                projectId: projectState.findProjectResponse.id,
                fromEmail: projectState.findProjectResponse.emailFrom,
                fromEmailName: projectState.findProjectResponse.nameFrom
            }))
            projectState.findProjectResponse?.id &&
                dispatch(findAllCustomMails({ ...mailsState.mailFilters, projectId: projectState.findProjectResponse?.id! }))
        }
    }, [projectState.findProjectResponse])

    useEffect(() => {
        if (mailsState.removeMailStatus === 'successfully' || mailsState.saveMailStatus === 'successfully' || mailsState.updateMailStatus === 'successfully') {
            dispatch(findAllCustomMails({ ...mailsState.mailFilters, projectId: projectState.findProjectResponse?.id! }))
            dispatch(setRemoveMailStatus('idle'))
            dispatch(setUpdateMailStatus('idle'))
            //dispatch(setSaveMailStatus('idle'))
        }
    }, [mailsState.removeMailStatus, mailsState.saveMailStatus, mailsState.updateMailStatus])

    useEffect(() => {
        if (mailsState.saveMailStatus === 'successfully' || mailsState.saveMailStatus === 'failed') {
            dispatch(resetCreateCustomMailRequest())
        }
    }, [mailsState.saveMailStatus])

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setOpenVariables(false);
        }
    };

    useEffect(() => {
        if (openVariables) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openVariables]);

    if (mailsState.openEmailSettingsModal)
        return (
            <div className='transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]'>
                <div className=' transition-all flex flex-col p-4 bg-white rounded-xl opacity-100 shadow-sm min-w-[30%] max-w-[50%] max-h-full overflow-auto'>
                    <div className=' flex items-center gap-6 justify-between w-full '>
                        <span className=' text-heading-xs font-bold text-neutral-800 px-6 py-4'>Impostazioni invio e-mail</span>
                        <Button onClick={() => {
                            dispatch(resetCreateCustomMailRequest())
                            setOpenVariables(false)
                            dispatch(setOpenTemplateId(undefined))
                            dispatch(setEmailSettingsModalOpen(false))
                        }
                        } size={'lg'} iconPosition={'only'}
                            variant={'link'} color={'blue'}
                            icon={<XCircleIcon color={'blue'} size={32} />} />
                    </div>
                    <div className=' flex gap-4 text-text-sm font-medium text-neutral-500 px-6'>
                        {['Nuovo template', 'Archivio template', 'Impostazioni invio'].map((item, index) =>
                            <div onClick={() => dispatch(setEmailsSettingsTab(index))} className=
                                {clsx('p-3 cursor-pointer', { 'border-b-brandPrimary-500 border-b-2': mailsState.emailSettingsTab === index })}>
                                {item}
                            </div>
                        )}
                    </div>
                    {mailsState.emailSettingsTab === 0 && <div className=' flex flex-col w-full py-8 px-4 gap-4 border-t border-neutral-200'>
                        <div className='flex flex-col gap-3 '>
                            <Input label='Nome template' value={mailsState.customMailCreateRequest.templateName} onChange={(e) => dispatch(setCustomMailCreateRequest({ ...mailsState.customMailCreateRequest, templateName: e.target.value }))} placeholder="Specificare il nome del template" />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <Input label='Oggetto' value={mailsState.customMailCreateRequest.subject} onChange={(e) => { dispatch(setCustomMailCreateRequest({ ...mailsState.customMailCreateRequest, subject: e.target.value })) }} placeholder="Specificare l'oggetto della mail" />
                        </div>
                        <div className='flex flex-col gap-0.5'>
                            <TextArea label='Contenuto della mail' value={temporaryText} onChangeText={(e) => { setTemporaryText(e) }} placeholder="Descrivere il contenuto dell'e-mail" />
                            <div ref={buttonRef} className='relative flex flex-col items-end justify-center'>
                                <Button onClick={() => setOpenVariables(prev => !prev)} label="Inserisci una variabile" size={'sm'} iconPosition={'left'} variant={'link'} color={'blue'} />
                                {ReactDOM.createPortal((
                                    openVariables ?
                                        <div ref={menuRef} className="bg-white border-[1px] border-neutral-100 rounded-md absolute shadow-sm z-[100]"
                                            style={{
                                                top: buttonRef.current ? (buttonRef.current.getBoundingClientRect().top + 40) : 0,
                                                right: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : 0,
                                            }}
                                        >
                                            {variableOptions}
                                        </div> : null
                                ), document.body)}
                            </div>
                        </div>
                    </div>
                    }
                    {mailsState.emailSettingsTab === 1 &&
                        <div className=' h-[287px] overflow-scroll py-4 px-6 border-t border-neutral-200 flex flex-col gap-2' key={1}>
                            {mailsState.findAllMailsStatus === 'loading' &&
                                <Spinner />}
                            {mailsState.findAllMailsStatus !== 'loading' && mailsState.findAllMailsStatus === 'successfully' && mailsState.findAllMailsResponse.data ?
                                mailsState.findAllMailsResponse.data.length > 0 ?
                                    mailsState.findAllMailsResponse.data.map(
                                        item => <AccordionTemplate open={mailsState.openTemplateId === item.ID} onOpen={() => {
                                            setOpenVariables(false)
                                        }}
                                            onClick={() => {
                                                dispatch(setOpenTemplateId(item.ID === mailsState.openTemplateId ? undefined : item.ID))
                                                dispatch(setCustomMailUpdateRequest({ ...mailsState.customMailUpdateRequest, templateName: item.templateName, subject: item.subject }))
                                            }
                                            }
                                            deleteFunc={() => dispatch(setTemplateToRemoveId(item.ID))} title={item.templateName} content={
                                                <div className='flex flex-col gap-6 p-4'>
                                                    <div className='flex flex-col gap-3 '>
                                                        <Input label='Nome template' defaultValue={item.templateName} onChange={(e) => dispatch(setCustomMailUpdateRequest({ ...mailsState.customMailUpdateRequest, templateName: e.target.value }))} placeholder="Specificare il nome del template" />
                                                    </div>
                                                    <div className='flex flex-col gap-3'>
                                                        <Input label='Oggetto' defaultValue={item.subject} onChange={(e) => { dispatch(setCustomMailUpdateRequest({ ...mailsState.customMailUpdateRequest, subject: e.target.value })) }} placeholder="Specificare l'oggetto della mail" />
                                                    </div>
                                                    <div className='flex flex-col gap-0.5'>
                                                        <TextArea label='Contenuto della mail' defaultValue={translateVariables(item.templateText)} placeholder="Descrivere il contenuto dell'e-mail" />
                                                        <div className=' flex flex-col items-end justify-center '>
                                                            <Button onClick={() => setOpenVariables(prev => !prev)} label="Inserisci una variabile" size={'sm'} iconPosition={'left'} variant={'link'} color={'blue'} />
                                                            <div className=" bg-white border-[1px] border-neutral-100 rounded-md bottom-[20%] absolute z-30 ">
                                                                {openVariables && variableOptions}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Button onClick={() => {
                                                        dispatch(updateMail(
                                                            {
                                                                id: mailsState.openTemplateId!, request: {
                                                                    ...mailsState.customMailUpdateRequest,
                                                                    templateText: updateTemplate(document.querySelector('textarea')!.value)[0],
                                                                    templateHTML: updateTemplate(document.querySelector('textarea')!.value)[1]
                                                                }
                                                            }
                                                        )
                                                        )
                                                        dispatch(setOpenTemplateId(undefined))
                                                    }}
                                                        label="Salva modifiche" size={'sm'} iconPosition='left' variant='solid' color='blue' />
                                                </div>
                                            } />
                                    ) : <NotFound /> : <NotFound />
                            }
                        </div>
                    }
                    {mailsState.emailSettingsTab === 2 &&
                        <div key={2} className=' flex flex-col w-full py-8 px-4 gap-4 border-t border-neutral-200'>
                            <div className='flex flex-col gap-3 '>
                                <Input label='E-mail del mittente' defaultValue={projectState.findProjectResponse!.emailFrom ? projectState.findProjectResponse!.emailFrom : ''} onChange={(e) => dispatch(setEditProjectEmailFrom(e.target.value))} placeholder="Specificare l'indirizzo email di chi invia le mail" />
                            </div>
                            <div className='flex flex-col gap-3'>
                                <Input label='Nome del mittente' defaultValue={projectState.findProjectResponse!.nameFrom ? projectState.findProjectResponse!.nameFrom : ''} onChange={(e) => dispatch(setEditProjectNameFrom(e.target.value))} placeholder="Specificare il nome di chi invia le mail" />
                            </div>
                        </div>
                    }
                    <div className=' flex justify-end items-center w-full border-t gap-2 pt-4 px-2 border-neutral-200'>
                        {mailsState.emailSettingsTab !== 1 && <Button onClick={() => {
                            if (mailsState.emailSettingsTab !== 0) {
                                if (mailsState.emailSettingsTab === 2)
                                    dispatch(editProject({
                                        id: projectState.findProjectResponse?.id!,
                                        data: projectState.editProjectRequest
                                    }))
                            }
                            else {
                                setTemporaryText('')
                                dispatch(saveMail(
                                    {
                                        ...mailsState.customMailCreateRequest,
                                        templateHTML: updateTemplate(document.querySelector('textarea')!.value)[1],
                                        templateText: updateTemplate(document.querySelector('textarea')!.value)[0]
                                    }))
                            }
                        }} disabled={mailsState.emailSettingsTab === 0 && (!projectState.findProjectResponse?.emailFrom || !projectState.findProjectResponse?.nameFrom)} label={mailsState.emailSettingsTab !== 0 ? 'Salva impostazioni' : 'Crea template'} size={'sm'} iconPosition={'left'} variant={'solid'} color={'blue'} />
                        }
                    </div>
                    <ConfirmModal title={'Eliminazione template'} label={'Sei sicuro di voler eliminare questo template?'} confirmAction={(() => {
                        dispatch(removeMail(mailsState.templateToRemoveId!))
                        dispatch(setTemplateToRemoveId(undefined))
                    })
                    }
                        onClose={() => dispatch(setTemplateToRemoveId(undefined))} visible={mailsState.templateToRemoveId !== undefined} />
                </div>
            </div>
        )
    else return <></>
}
