import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { TargetListPageEnum } from "../../../targetList/dto";
import { } from "../../slice";
import {
  findAllCompanies,
  setFindAllCompaniesFiltersAtecos,
  setFindAllCompaniesFiltersCategory,
  setFindAllCompaniesFiltersCity,
  setFindAllCompaniesFiltersCountryRegion,
  setFindAllCompaniesFiltersCreationContactDate,
  setFindAllCompaniesFiltersDepartment,
  setFindAllCompaniesFiltersEmployees,
  setFindAllCompaniesFiltersHasSmartphone,
  setFindAllCompaniesFiltersLastContactDate,
  setFindAllCompaniesFiltersName,
  setFindAllCompaniesFiltersOutcome,
  setFindAllCompaniesFiltersOutcomeInfo,
  setFindAllCompaniesFiltersParticularity,
  setFindAllCompaniesFiltersPrivacy,
  setFindAllCompaniesFiltersProject,
  setFindAllCompaniesFiltersRevenue,
  setFindAllCompaniesFiltersRole,
  setFindAllCompaniesFiltersSector,
  setFindAllCompaniesFiltersSmartphone,
  setFindAllCompaniesFiltersState,
  setFindAllCompaniesFiltersTargetList,
  setFindAllCompaniesFiltersZipCode,
} from "../slice";
import { AtecoFilter } from "./ateco";
import { CategoryFilter } from "./category";
import { CompanyFilter } from "./company";
import { ContactCreationFilter } from "./contactCreationFilter";
import { DateOfContactFilter } from "./dateOfContactFilter";
import { DepartmentFilter } from "./department";
import { EmployeesNumberFilter } from "./employeesNumber";
import { OutcomeFilter } from "./outcome";
import { ParticularitiesFilters } from "./particularitiesFilters";
import { PhoneFilter } from "./phoneFilter";
import { PrivacyFilter } from "./privacy";
import { ProjectFilter } from "./project";
import { RevenueFilter } from "./revenue";
import { RoleFilter } from "./role";
import { SectorFilter } from "./sector";
import { SmartphoneFilter } from "./smartphone";
import { StateFilter } from "./state";
import { TargetFilter } from "./target";
import { OutcomeInfoFilter } from "./outcomeInfoFilter";

interface Props {
  isInTargetList: boolean
}

export function Filters(props: Props) {
  const { isInTargetList } = props
  const companiesState = useAppSelector((state) => state.companies);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(findAllCompaniesForFilters());
    // dispatch(findAllTargetListForFilters());
    // dispatch(findAllProjectsForFilters());
  }, []);

  const filters = Object.entries(companiesState.filters).filter(
    (entr) =>
      entr[0] !== "page" &&
      entr[0] !== "itemsPerPage" &&
      entr[0] !== "order" &&
      entr[0] !== "filter" &&
      entr[0] !== "active" &&
      entr[0] !== "sort" &&
      entr[1] !== undefined &&
      entr[1].length !== 0 &&
      (window.location.pathname.includes("target") &&
        targetListsState.targetListPage !== TargetListPageEnum.DB
        ? entr[0] !== "project" && entr[0] !== "targetList"
        : true)
  ).length;

  return (
    <div className="bg-white border-r h-full rounded-b-xl border-neutral-200 w-full relative">
      <div className="px-[24px] h-16 flex items-center justify-between sticky">
        <span className="text-text-md text-neutral-800 font-bold">
          {"Filtri " + (filters === 0 ? "" : "(" + filters + ")")}
        </span>
        <div className="flex items-center">
          <Button
            size={"sm"}
            iconPosition={"off"}
            variant={"link"}
            color={"blue"}
            label="Pulisci tutti"
            onClick={() => {
              dispatch(setFindAllCompaniesFiltersName([]));
              dispatch(setFindAllCompaniesFiltersRevenue(undefined));
              dispatch(setFindAllCompaniesFiltersEmployees(undefined));
              dispatch(setFindAllCompaniesFiltersAtecos([]));
              dispatch(setFindAllCompaniesFiltersSector([]));
              dispatch(setFindAllCompaniesFiltersCategory([]));
              dispatch(setFindAllCompaniesFiltersDepartment([]));
              dispatch(setFindAllCompaniesFiltersRole([]));
              dispatch(setFindAllCompaniesFiltersHasSmartphone(undefined));
              dispatch(setFindAllCompaniesFiltersPrivacy(undefined));
              dispatch(setFindAllCompaniesFiltersState(undefined));
              dispatch(setFindAllCompaniesFiltersCountryRegion([]));
              dispatch(setFindAllCompaniesFiltersCity([]));
              dispatch(setFindAllCompaniesFiltersZipCode([]));
              dispatch(setFindAllCompaniesFiltersParticularity([]))
              dispatch(setFindAllCompaniesFiltersSmartphone(undefined))
              dispatch(setFindAllCompaniesFiltersCreationContactDate(undefined))
              dispatch(setFindAllCompaniesFiltersLastContactDate(undefined))
              dispatch(setFindAllCompaniesFiltersOutcomeInfo([]))
              dispatch(
                setFindAllCompaniesFiltersProject([])
              );
              dispatch(setFindAllCompaniesFiltersOutcome([]));
              dispatch(
                setFindAllCompaniesFiltersTargetList(
                  window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                      {
                        label: targetListsState.findTargetListResponse?.name!,
                        value: targetListsState.findTargetListResponse?.id!,
                      },
                    ]
                    : []
                )
              );
              dispatch(
                findAllCompanies({
                  atecos: [],
                  category: [],
                  department: [],
                  employees: undefined,
                  itemsPerPage: companiesState.filters.itemsPerPage,
                  name: [],
                  order: true,
                  page: 0,
                  privacy: undefined,
                  revenue: undefined,
                  role: [],
                  sector: [],
                  hasSmartphone: undefined,
                  sort: undefined,
                  state: undefined,
                  city: [],
                  countryRegion: [],
                  project: [],
                  outcome: [],
                  zipCode: [],
                  targetList: window.location.pathname.includes("target") &&
                    targetListsState.targetListPage !== TargetListPageEnum.DB
                    ? [
                      {
                        label: targetListsState.findTargetListResponse?.name!,
                        value: targetListsState.findTargetListResponse?.id!,
                      },
                    ]
                    : [],
                  particularity: [],
                  smartphone: undefined,
                  creationContactDate: undefined,
                  lastContactDate: undefined,
                  outcomeInfo: []
                })
              );
            }}
          />
        </div>
      </div>
      <div className="overflow-auto rounded-b-xl" style={{ height: 'calc(100% - 64px)' }}>
        <CompanyFilter isInTargetList={isInTargetList} />
        <RevenueFilter />
        <OutcomeFilter />
        <EmployeesNumberFilter />
        {(!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListsState.targetListPage === TargetListPageEnum.DB)) && (
            <>
              <ProjectFilter />
              <TargetFilter />
            </>
          )}
        <AtecoFilter />
        <SectorFilter />
        <CategoryFilter />
        <DepartmentFilter />
        <RoleFilter />
        <SmartphoneFilter />
        <PrivacyFilter />
        <StateFilter />
        <ParticularitiesFilters />
        <PhoneFilter />
        <ContactCreationFilter />
        <DateOfContactFilter />
        <OutcomeInfoFilter />
      </div>
    </div>
  );
}
