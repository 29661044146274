import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import { groupBy } from '../../../lib/utils';
import { colors } from '../../colors';
import { LogoIcon } from '../../icons/logo';
import { PlusIcon } from '../../icons/plus';
import { SidebarIcon } from '../../icons/sidebar';
import { MenuStatus } from '../../layout/dto';
import { setMenuStatus } from '../../layout/slice';
import { MenuItems, MenuItemsHrefMap, MenuItemsIconsMap, MenuItemsLabelMap } from './dto';
import NavLinks from './nav-links';

interface Props {
  menuItem: MenuItems
}
export default function SideNav(props: Props) {
  const layoutState = useAppSelector(state => state.layout)
  const dispatch = useAppDispatch()

  const groupedItems = groupBy(layoutState.menuItems, 'section')

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'white', overflow: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}
        className={clsx(
          'h-[64px] lg:h-[80px]',
          {
            'lg:p-[24px] py-[24px] px-[8px] justify-between': layoutState.menuStatus !== MenuStatus.CLOSED,
            'lg:py-[24px] lg:px-[0px] justify-center': layoutState.menuStatus === MenuStatus.CLOSED,
          }
        )}>
        <LogoIcon extended={layoutState.menuStatus !== MenuStatus.CLOSED} />
        <div className='rotate-45 block lg:hidden cursor-pointer' onClick={() => dispatch(setMenuStatus(MenuStatus.CLOSED))}>
          <PlusIcon color={colors.neutral[500]} size={20} />
        </div>
        <div
          onClick={() => {
            if (layoutState.menuStatus === MenuStatus.OPEN) {
              dispatch(setMenuStatus(MenuStatus.CLOSED))
            } else if (layoutState.menuStatus === MenuStatus.HOVER) {
              dispatch(setMenuStatus(MenuStatus.OPEN))
            }
          }}
          className={
            clsx(
              'transition-all hidden',
              {
                'rotate-0 lg:block': layoutState.menuStatus === MenuStatus.OPEN,
                'rotate-180 lg:block': layoutState.menuStatus === MenuStatus.HOVER,
                'hidden': layoutState.menuStatus === MenuStatus.CLOSED
              }
            )
          }>
          <SidebarIcon />
        </div>
      </div>
      {
        groupedItems.map(group => (
          <div key={group.key + '-separator-group'}>
            <div key={group.key + '-separator'} className='h-[1px] w-[100%] bg-neutral-200' />
            <div key={group.key + '-group'} style={{ padding: '24px 16px 24px 16px', gap: '4px', display: 'flex', flexDirection: 'column' }}>
              {
                group.values.map(item => <NavLinks menuItem={props.menuItem} key={item.item + '-item'} link={{ name: MenuItemsLabelMap.get(item.item)!, href: MenuItemsHrefMap.get(item.item)!, icon: MenuItemsIconsMap(item.item, '', 20), menuItem: item.item }} />)
              }
            </div>
          </div>

        ))
      }
      <div
        style={{ padding: '24px 16px 24px 16px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
        className='text-label-sm font-medium text-neutral-500 transition-all'
      >
        <span
          className={
            clsx(
              'transition-all',
              {
                "block": layoutState.menuStatus === MenuStatus.CLOSED,
                "hidden content-['© 2024 Commercali Digitali']": layoutState.menuStatus !== MenuStatus.CLOSED
              }
            )
          }>
          ©
        </span>
        <span
          className={
            clsx(
              'transition-all',
              {
                "hidden": layoutState.menuStatus === MenuStatus.CLOSED,
                "block": layoutState.menuStatus !== MenuStatus.CLOSED
              }
            )
          }>
          {"© " + new Date().getFullYear() + " Aisales"}
        </span>
      </div>
    </div>
  );
}
