import clsx from "clsx";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/lib/redux/hooks";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { UserPlusIcon } from "../../../ui/icons/userPlus";
import Checkbox from "../../../ui/molecules/checkbox";
import { Spinner } from "../../../ui/molecules/spinner";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { NotFound } from "../../../ui/organisms/notFound";
import { Pagination } from "../../../ui/organisms/pagination/index";
import { Cell } from "./cell";
import { Head } from "./head";
import {
  setFindAllPeopleFiltersActive,
  setFindAllPeopleFiltersItemsPerPage,
  setFindAllPeopleFiltersPage,
  setPersonToAddCompany,
  setSelectedIdsPeople,
  setSelectedPerson,
  setShowPopupPeople,
  setTabValueDetailsPerson
} from "./slice";

export function PeopleList() {
  const peopleState = useAppSelector((state) => state.people);
  const preferencesState = useAppSelector((state) => state.preferences);

  const dispatch = useAppDispatch();

  return (
    <div className="flex-grow w-full" style={{ height: 'calc(100% - 63.8px)' }}>
      <div className="flex items-center gap-10 px-4 h-16">
        <span className="text-heading-xs font-semibold text-neutral-800">
          Persone
        </span>
        {
          !window.location.pathname.includes('black-list') &&
          <div className="flex justify-end w-full items-center gap-2 h-16">
            <div
              onClick={() => {
                if (peopleState.filters.active === "false") {
                  dispatch(setFindAllPeopleFiltersActive(undefined))
                } else {
                  dispatch(setFindAllPeopleFiltersActive("false"))
                }
              }}
              className={clsx("cursor-pointer flex items-center px-2 py-1 gap-2 rounded-[4px] bg-brandPrimary-400", {
                'border-[2px] border-brandPrimary-600': peopleState.filters.active === "false"
              })}
            >
              <span className="text-white text-label-sm font-medium">
                Inattive
              </span>
              <div className="bg-brandPrimary-100 flex items-center justify-center text-brandPrimary-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                {
                  peopleState.personCountResponse.toActivate
                }
              </div>
            </div>
          </div>
        }
      </div>
      {peopleState.findAllResponse?.data.length === 0 ||
        peopleState.findAllResponse?.data === null ? (
        <div style={{ height: 'calc(100% - 64px - 60px)' }}>
          <NotFound />
        </div>
      ) : (
        <div className="w-full overflow-auto" style={{ height: 'calc(100% - 48px - 64px)' }}>
          <table className="max-w-full w-full">
            <thead className="h-[48px] font-semibold text-neutral-500 uppercase text-text-xs sticky z-30 top-0">
              <tr className="bg-neutral-50">
                <th className="">
                  <Checkbox
                    shape={"square"}
                    checked={
                      peopleState.findAllResponse?.data.every((person) => peopleState.selectedIds.includes(person.id))!
                    }
                    onCheck={function (value: boolean): void {
                      if (
                        peopleState.findAllResponse?.data
                          .every((person) => peopleState.selectedIds.includes(person.id))
                      ) {
                        let newSelected = [...peopleState.selectedIds];
                        peopleState.findAllResponse?.data.forEach((person) => {
                          newSelected = newSelected.filter(
                            (id) => person.id !== id
                          );
                        });
                        dispatch(setSelectedIdsPeople([...newSelected]));
                      } else {
                        dispatch(setSelectedIdsPeople([
                          ...peopleState.selectedIds,
                          ...peopleState.findAllResponse?.data
                            .filter(
                              (person) =>
                                !peopleState.selectedIds.includes(person.id)
                            )
                            .map((person) => person.id)!,
                        ]));
                      }
                    }}
                  />
                </th>
                {preferencesState.findAllResponse?.data[0].personPreferences
                  .filter((column) => column.visible)
                  .map((column, index) => (
                    <Head
                      key={column.column}
                      column={column.column}
                      index={index}
                    />
                  ))}
                <th className="">
                </th>
              </tr>
            </thead>
            <tbody>
              {peopleState.findAllResponse?.data.map((person, index) => (
                <tr
                  className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                  key={person.email + index}
                >
                  <td className="text-text-sm font-semibold text-neutral-600">
                    <Checkbox
                      shape={"square"}
                      checked={peopleState.selectedIds.includes(person.id)}
                      onCheck={function (value: boolean): void {
                        if (peopleState.selectedIds.includes(person.id)) {
                          dispatch(setSelectedIdsPeople([
                            ...peopleState.selectedIds.filter((id) => id !== person.id),
                          ]));
                        } else {
                          dispatch(setSelectedIdsPeople([...peopleState.selectedIds, person.id]));
                        }
                      }}
                    />
                  </td>
                  {preferencesState.findAllResponse?.data[0].personPreferences
                    .filter((column) => column.visible)
                    .map((column, index) => (
                      <Cell
                        key={person.id + "-" + column.column}
                        column={column.column}
                        index={index}
                        person={person}
                      />
                    ))}
                  <td className="w-[20px] cursor-pointer z-20">
                    {peopleState.findPersonStatus === "loading" ||
                      peopleState.deletePersonStatus === "loading" ? (
                      <Spinner size={20} />
                    ) : (
                      <Dropdown
                        items={[
                          {
                            label: "Modifica",
                            icon: <EditIcon color={""} size={0} />,
                            onClick: () => {
                              dispatch(setSelectedPerson(person.id));
                              dispatch(setTabValueDetailsPerson(0))
                            },
                          },
                          person.companyId === null
                            ? {
                              label: "Associa azienda",
                              icon: <UserPlusIcon color={""} size={0} />,
                              onClick: () =>
                                dispatch(setPersonToAddCompany(person)),
                            }
                            : null,
                          window.location.pathname.includes('black-list') ?
                            null :
                            {
                              label: "Cancella",
                              icon: <TrashIcon color={""} size={0} />,
                              onClick: () => {
                                dispatch(setSelectedIdsPeople([person.id]))
                                dispatch(setShowPopupPeople(true))
                              },
                            },
                        ]}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        itemsPerPage={peopleState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setFindAllPeopleFiltersItemsPerPage(value))}
        found={peopleState.findAllResponse?.total || 0}
        key={"pagination-people-bottom"}
        pages={peopleState.findAllResponse?.totalPage || 0}
        currentPage={peopleState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllPeopleFiltersPage(page));
        }}
      />
    </div>
  );
}
