import qs from "qs";
import { cdAxios } from "../../lib/axios";
import { keycloak } from "../../lib/keycloak";
import { FindAllCompaniesFilters } from "../database/companies/dto";
import { ImportCSVResponseDTO } from "../database/dto";
import { FindAllPeopleFilters } from "../database/people/dto";
import {
  ContactStateEnum,
  EditTargetListRequest,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  NoteDTO,
  TargetListCounterDTO,
  TargetListDTO,
  TargetListStatusEnum,
} from "./dto";
import { TargetListsService } from "./service";

export class TargetListsServiceImpl implements TargetListsService {
  public findAllTargetLists(
    filters: FindAllTargetListsFilters
  ): Promise<FindAllTargetListsResponse> {
    return cdAxios
      .get("/api/target-lists", {
        params: {
          itemsPerPage: filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          order: filters.order,
          name: filters.name,
          sector: filters.sector,
          category: filters.category,
          commercial: filters.commercial,
          project: filters.project,
          teamLeader: filters.teamLeader,
          state: filters.state,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public importContactCSV(
    data: FormData,
    type: "persons" | "companies",
    targetListId: string
  ): Promise<ImportCSVResponseDTO> {
    return cdAxios
      .put("/api/target-lists/" + targetListId + "/" + type + "/csv", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public removeContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId + "/" + type + "/remove",
        contactIds,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public addPeopleBulk(
    targetListId: string,
    filters: FindAllPeopleFilters
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId + "/persons/bulk-add",
        {
          params: {
            itemsPerPage: 0,
            page: 0,
            sort: filters.sort,
            order: filters.order,
            privacy: filters.privacy?.value,
            revenue: filters.revenue?.value,
            employeesNumber: filters.employees?.value,
            personName: filters.personName,
            personEmail: filters.personEmail,
            hasCompany: filters.hasCompany,
            active: filters.active,
            hasSmartphone: filters.hasSmartphone?.value,
            outcome: filters.outcome.map((outcome) => outcome.value + (window.location.pathname.includes('target') ? ':' + filters.targetList[0].value : '')),
            atecoCode: filters.atecos.map((ateco) => "~:" + ateco.value),
            category: filters.category.map((category) => category.value),
            department: filters.department.map((department) => department.value),
            name: filters.name.map((name) => name.label),
            role: filters.role.map((role) => role.value),
            sector: filters.sector.map((sector) => sector.value),
            state: filters.state?.value,
            city: filters.city.map((city) => city.value),
            countryRegion: filters.countryRegion.map(
              (countryRegion) => countryRegion.value
            ),
            zipCode: filters.zipCode.map((zipCode) => zipCode.value),
            targetList: filters.targetList.map((target) => "=:" + target.value),
            project: filters.project.map((project) => "=:" + project.value),
          },
          paramsSerializer: (params: FindAllPeopleFilters) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: false,
            });
          },
        }, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
      )
      .then((response) => response.data);
  }

  public addCompaniesBulk(
    targetListId: string,
    filters: FindAllCompaniesFilters
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId + "/companies/bulk-add",
        {
          params: {
            itemsPerPage: 0,
            page: 0,
            sort: filters.sort,
            order: filters.order,
            filter: filters.filter,
            active: filters.active,
            privacy: filters.privacy?.value,
            revenue: filters.revenue?.value,
            employeesNumber: filters.employees?.value,
            hasSmartphone: filters.hasSmartphone?.value,
            atecoCode: filters.atecos.map((ateco) => "~:" + ateco.value),
            category: filters.category.map((category) => category.value),
            department: filters.department.map((department) => department.value),
            targetList: filters.targetList.map((target) => "=:" + target.value),
            outcome: filters.outcome.map((outcome) => outcome.value + (window.location.pathname.includes('target') ? ':' + filters.targetList[0].value : '')),
            project: filters.project.map((project) => "=:" + project.value),
            name: filters.name.map((name) => name.label),
            role: filters.role.map((role) => role.value),
            sector: filters.sector.map((sector) => sector.value),
            state: filters.state?.value,
            city: filters.city.map((city) => "~:" + city.value),
            countryRegion: filters.countryRegion.map(
              (countryRegion) => countryRegion.value
            ),
            zipCode: filters.zipCode.map((zipCode) => zipCode.value),
          },
          paramsSerializer: (params: FindAllPeopleFilters) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: false,
            });
          },
        }, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
      )
      .then((response) => response.data);
  }

  public getCounters(
    targetListId: string
  ): Promise<TargetListCounterDTO> {
    return cdAxios
      .get(
        "/api/target-lists/" + targetListId + "/contacts-counter",
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public addContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId + "/" + type + "/add",
        contactIds,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public editTargetList(
    targetListId: string,
    data: EditTargetListRequest
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId,
        data,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }


  public changeContactStatus(
    targetListId: string,
    type: "persons" | "companies",
    contactId: string,
    contactState?: ContactStateEnum,
    stateInfo?: string | null
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" +
        targetListId +
        "/" +
        type +
        "/" +
        contactId +
        "/state",
        undefined,
        {
          params: {
            state: contactState,
            stateInfo: stateInfo
          },
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public updateNotes(
    targetListId: string,
    type: "persons" | "companies",
    contactId: string,
    data: NoteDTO[]
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" +
        targetListId +
        "/" +
        type +
        "/" +
        contactId +
        "/notes",
        { notes: data },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public restoreContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[],
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" +
        targetListId +
        "/" +
        type +
        "/restore",
        contactIds,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public findTargetListById(id: string): Promise<TargetListDTO> {
    let idToUse: string = "";
    if (window.location.pathname.includes("edit-project")) {
      if (window.location.pathname.split("/")[4] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[4];
      }
    } else if (window.location.pathname.includes("edit-target-list")) {
      if (window.location.pathname.split("/")[2] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[2];
      }
    } else if (window.location.pathname.includes("target-list-bins")) {
      if (window.location.pathname.split("/")[2] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[2];
      }
    } else {
      idToUse = id;
    }
    return cdAxios
      .get("/api/target-lists/" + idToUse, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createTargetList(request: NewTargetListRequest): Promise<string> {
    return cdAxios
      .post("/api/target-lists", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public changeStatus(
    request: {
      state:
      | TargetListStatusEnum.CONCLUDED
      | TargetListStatusEnum.INPROGRESS
      | undefined;
      id: string;
    }[]
  ): Promise<void> {
    return cdAxios
      .put("/api/target-lists/state", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public deleteTargetList(id: string): Promise<void> {
    return cdAxios
      .delete("/api/target-lists/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }
}
